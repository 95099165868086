/**
 * Created by osirvent on 14/07/2016.
 */
angular.module('annexaApp')
    .directive('expirationBox', ['$compile', 'TramFactory', function ($compile, TramFactory) {
        var getTemplate = function (definition) {
            var template = '';

            var col1 = 'col-sm-6';
            var col2 = 'col-sm-6';

            if (definition.type == 'transaction') {
                col1 = 'col-sm-4';
                col2 = 'col-sm-8';
            }

            var classStart = 'expiration-box-' + definition.type + '-';

            if (definition.type == 'dossier') {
                if (definition.days < 0) {
                    template += '   <div class="expiration-box-box text-white eb-box1 danger">';
                } else if (definition.days < 5) {
                    template += '   <div class="expiration-box-box text-white eb-box1 amber">';
                } else {
                    template += '   <div class="expiration-box-box text-white eb-box1 primary">';
                }
                
                if (definition.days < 0) {
                	template += '       <span class=" eb-number">{{days * -1}}</span>';
                    template += '       <span class=" eb-days">{{ \'global.tram.literals.daysexpired\' | translate}}</span>';
                } else {
                	template += '       <span class=" eb-number">{{days}}</span>';
                    template += '       <span class=" eb-days">{{ \'global.tram.literals.daysexpire\' | translate}}</span>';
                }  
                
                template += '   </div>';
            } else {
                if (definition.days < 0) {
                    template += '   <div class="expiration-box-box eb-box1 text-white danger" ng-if="guided">';
                } else if (definition.days < 5) {
                    template += '   <div class="expiration-box-box eb-box1 text-white amber" ng-if="guided">';
                } else {
                    template += '   <div class="expiration-box-box eb-box1 text-white primary" ng-if="guided">';
                }
                
                if (definition.days < 0) {
                	template += '       <h4 class=" eb-number">{{days * -1}}';
                    template += '       <span class="eb-days">{{ \'global.tram.literals.daysexpired\' | translate}}</span></h4>';
                } else {
                	template += '       <h4 class=" eb-number">{{days}}';
                    template += '       <span class="eb-days">{{ \'global.tram.literals.daysexpire\' | translate}}</span></h4>';
                }
                
                template += '   </div>';
            }

            template += '   <div class="expiration-box-box eb-date eb-box2 ng-class: {\'no-guided\' : !guided}">';
            if (definition.type == 'dossier') {
                template += '   {{label | translate}}:';
                template += '   <br/>';
                template += '   {{date | date: \'dd/MM/yyyy\'}}';
            } else { /* it's a transaction! */
                template += '   <div class="" ng-if="guided">';
                template += '       <div class=" m-b m-t">';
                template += '           {{label | translate}}: {{date | date: \'dd/MM/yyyy\'}}';
                template += '       </div>';
                template += '   </div>';
                template += '   <div class=" ">';
                template += '      <!-- <div class=" "> -->';
                template += '           <button class="btn primary text-white" ng-click="auditDossierTransaction()"  permission permission-only="\'admin_audit\'">';
                template += '               <i class="fa fa-eye"></i>';
                template += '               <span translate="global.documents.new.audit">Audit</span>';
                template += '           </button>';
                template += '           <button class="btn primary text-white" ng-click="reopen()"  permission permission-only="\'reopen_dossier_transaction\'" ng-if="!guided && !actual && isUpdateDossier && !isProposalTransaction && !isAgreementTransaction && !isAmendmentTransaction && !isTransferTransaction">';
                template += '               <i class="fa fa-share"></i>';
                template += '               <span translate="global.literals.reopen">Reopen</span>';
                template += '           </button>';
                template += '           <button class="btn grey-500 text-white" ng-click="back()" permission permission-only="\'back_dossier\'" ng-if="!guided && actual && isUpdateDossier && !isAgreementTransaction && !isTransferTransaction">';
                template += '               <i class="fa fa-mail-reply"></i>';
                template += '               <span translate="global.literals.anular">Cancel</span>';
                template += '           </button>';
                template += '       <!-- </div> -->';
                template += '       <!-- <div class=" "> -->';
                template += '           <button class="btn primary text-white" ng-click="complete()" ng-if="!isAgreementTransaction" ng-disabled="!isUpdateDossier || !actual">';
                template += '               <i ng-if="!isProposalTransaction && !isAmendmentTransaction" class="fa fa-check"></i>';
                template += '               <span ng-if="!isProposalTransaction && !isAmendmentTransaction" translate="global.tram.literals.completetransaction">Complete</span>';
                template += '               <i ng-if="isProposalTransaction || isAmendmentTransaction" class="fa fa-remove"></i>';
                template += '               <span ng-if="isProposalTransaction || isAmendmentTransaction" translate="global.tram.literals.cancelproposal">Cancel proposal</span>';
                template += '           </button>';
                template += '           <button class="btn primary text-white" ng-click="sendProposalDossierTransaction()" ng-if="isProposalTransaction || isAmendmentTransaction" ng-disabled="!isUpdateDossier || !actual">';
                template += '               <i class="fa fa-send-o fa-fw"></i>';
                template += '               <span translate="global.tram.literals.sendProposal">Send proposal</span>';
                template += '           </button>';
                template += '       <!-- </div> -->';
                template += '   </div>';
            }
            template += '   </div>';

            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                type: '@',
                label: '@',
                days: '=',
                date: '=',
                back: '&',
                reopen: '&',
                sendProposalDossierTransaction: '&',
                complete: '&',
                backbutton: '=',
                isUpdateDossier: '=',
                isProposalTransaction: '=',
                isAmendmentTransaction: '=',
                isAgreementTransaction: '=',
                isTransferTransaction: '=',
                actual: '=',
                guided: '=',
                transactionType: '=',
                auditDossierTransaction: '&'
            },
            link: function (scope, element, attr) {
                element.html(getTemplate(scope)).show();
                $compile(element.contents())(scope);
            }
        }
    }])
    .directive('annexaBoxReceivers', ['$compile', 'TramFactory', 'Language', function ($compile, TramFactory, Language) {
        var getTemplate = function (scope) {
            var template = '';

            template += '<div ng-if="receivers.length==0" class="text-center cursiva-grey" translate="global.literals.empty_receivers">Add</div>';

            template += '<div ng-if="receivers.length>0">';
            template += '   <div class="row ng-class:{\'m-b-p-b-sm-b-b\' : !$last}" ng-repeat="receiver in receivers" >';
            template += '       <div class="col-xs-11">';
            template += '           <div class="row m-b-xs _600">';
            template += '               <div class="col-xs-1">';
            template += '                   <i ng-if="receiver.principal" class="fa fa-lg text-warn fa-flag " title="{{\'global.literals.principalDest\' | translate}}"></i>';
            template += '                   <a ng-if="!receiver.principal" ng-click="setPrincipal(receiver.id, $index)" title="{{\'global.literals.makePrincipalDest\' | translate}}">';
            template += '                       <i ng-if="!receiver.principal" class="fa fa-lg text-grey fa-flag-o"></i>';
            template += '                   </a>';
            template += '               </div>';
            template += '               <div class="pull-left m-l m-r-xs">';
            template += '                   <href-permission class-href="text-primary _600"  link-href="annexa.thirds.edit" attr-compare="id" id-href="receiver.third.id" name-href="receiver.third.identificationDocument" href-permission-only="\'view_thirds\'"/>';
            template += '               </div>';
            template += '               <div class="col-sm-4 col-xxl-5">';
            template += '                   <span ng-if="receiver.third.corporateName">{{receiver.third.corporateName}}</span>';
            template += '                   <span ng-if="!receiver.third.corporateName && receiver.third.surename2">{{receiver.third.name + \' \' + receiver.third.surename1+ \' \' + receiver.third.surename2}}</span>';
            template += '                   <span ng-if="!receiver.third.corporateName && !receiver.third.surename2">{{receiver.third.name + \' \' + receiver.third.surename1}}</span>';
            template += '               </div>';
            template += '               <div class="col-sm-2 col-lg-offset-1 col-lg-3 col-xl-offset-0 col-xl-2" ng-if="receiver.interested">';
            template += '                   <span class="label label-xxs" translate="global.literals.interested">Interessted</span>';            
            template += '                   <span ng-if="receiver.roleInterested" class="label label-xxs">{{receiver.roleInterested[languageColumn]}}</span>';            
            template += '               </div>';
            template += '               <div class="col-sm-2 col-lg-3 col-xl-2">';
            template += '                   <span ng-if="receiver.representative && receiver.representated && receiver.representated.name && !receiver.representated.surename2" class="label label-xxs" translate="global.literals.representative" translate-values="{ representated: \' (\' + receiver.representated.name + \' \' + receiver.representated.surename1 + \' (\' + receiver.representated.identificationDocument + \')\' + \')\' }">Representative</span>';
            template += '                   <span ng-if="receiver.representative && receiver.representated && receiver.representated.name && receiver.representated.surename2" class="label label-xxs" translate="global.literals.representative" translate-values="{ representated: \' (\' + receiver.representated.name + \' \' + receiver.representated.surename1 + \' \' + receiver.representated.surename2 + \' (\' + receiver.representated.identificationDocument + \')\' + \')\' }">Representative</span>';
            template += '                   <span ng-if="receiver.representative && receiver.representated && !receiver.representated.name" class="label label-xxs" translate="global.literals.representative" translate-values="{ representated: \' (\' + receiver.representated.corporateName + \' (\' + receiver.representated.identificationDocument + \')\' + \')\' }">Representative</span>';
            template += '               </div>';
            template += '           </div>';
            template += '           <div class="row m-b-xs" ng-repeat="address in receiver.addresses" >';
            template += '               <div class="col-sm-11 col-sm-offset-1 " ng-bind-html="address"></div>';
            template += '           </div>';
            template += '       </div>';            
            template += '       <div class="col-xs-1">';
            template += '           <annexa-edit-button class="pull-right" edit-function="editFunction" edit-object="receiver" edit-object-index="$index" actual="true"></annexa-edit-button>';
            template += '       </div>';            
            template += '       <div class="col-xs-1">';
            template += '           <annexa-delete-button class="pull-right"  delete-function="deleteFunction" delete-object="receiver" delete-object-index="$index" actual="true"></annexa-delete-button>';
            template += '       </div>';
            template += '   </div>';
            template += '</div>';

            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                receivers: '=',
                deleteFunction: '=',
                editFunction: '=',
                setPrincipal: '='
            },
            link: function (scope, element, attr) {
                element.html(getTemplate(scope)).show();
                $compile(element.contents())(scope);                
                scope.languageColumn = Language.getActiveColumn();
            }
        }
    }])
    .directive('annexaBoxDocumentsTram', ['$compile','$rootScope', 'TramFactory', 'globalModals', 'AnnexaFormlyFactory', function ($compile, $rootScope, TramFactory, globalModals, AnnexaFormlyFactory) {
        var getTemplate = function (scope) {
            var template = '';
            template += '<div ng-if="documents.length == 0" class="text-center cursiva-grey" translate="global.literals.empty_documents">Add</div>';
            template += '<div ng-if="documents.length > 0">';
            template += '   <div class="row m-b-p-b-sm-b-b">';
            template += '       <div class="inline m-l-sm">';
            template += '		   <button class="btn btn-sm label label-breakable {{labelDossierTransactionDocs}}" ng-click="filterRelationType(\'DOCUMENT\')">';
            template += '		        <span>{{ \'global.relationType.DOCUMENT\' | translate}} ({{counterDossierTransactionDocs}})</span>';
            template += '		    </button>';
            template += '		</div>';
            template += '       <div class="inline m-l-xs">';
            template += '		    <button class="btn btn-sm label label-breakable {{labelDossierTransactionWorkDocs}}" ng-click="filterRelationType(\'WORK_DOCUMENT\')">';
            template += '		        <span>{{ \'global.relationType.WORK_DOCUMENT\' | translate}} ({{counterDossierTransactionWorkDocs}})<span>';
            template += '		    </button>';
            template += '		</div>';
            template += '       <div class="inline m-l-xs">';
            template += '		    <button class="btn btn-sm label label-breakable {{labelDossierTransactionInternalDocs}}" ng-click="filterRelationType(\'INTERNAL_DOCUMENT\')">';
            template += '		        <span>{{ \'global.relationType.INTERNAL_DOCUMENT\' | translate}} ({{counterDossierTransactionInternalDocs}})<span>';
            template += '		    </button>';
            template += '		</div>';
            template += '	</div>';
            template += '   <div ng-if="filteredContent.length == 0" class="text-center cursiva-grey" translate="global.literals.empty_documents">Add</div>';
            template += '   <div ng-if="filteredContent.length > 0" class="documentRows">';
        	template += '    <div class="row ng-class:{\'m-b-sm-p-b-sm-b-b\' : !$last} " ng-repeat="document in filteredContent | startFrom:page*itemsPage | limitTo:itemsPage">';
            template += '       <div class="col-sm-9 col-xxl-10">';
            template += '           <div class="row m-b-xs p-b-xs m-t-sm">';
            template += '               <div class="col-xs-3 col-sm-2 col-xxl-1">';
            template += '                   <span ng-if="document.document.publicDocument && actionFunction"><i title="{{\'global.documentState.public\' | translate}}" class="m-l-xs m-r-lg fa fa-check-square-o"></i></span>';
            template += '                   <span ng-if="!document.document.publicDocument && actionFunction"><i title="{{\'global.documentState.no_public\' | translate}}" class="m-l-xs m-r-lg text-grey fa fa-square-o"></i></span>';
            template += '                   <span ng-if="document.document.docStatus == \'NEW\'"><i title="{{\'global.documentState.NEW\' | translate}}" class="fa fa-circle-o text-blue fa-lg v-t"></i></span>';
            template += '                   <span ng-if="document.document.docStatus == \'NEW_AND_ATTACHMENT\'"><i title="{{\'global.documentState.NEW_AND_ATTACHMENT\' | translate}}" class="fa v-t fa-adjust text-blue fa-lg"></i></span>';
            template += '                   <span ng-if="document.document.docStatus == \'PENDING_SIGN\'"><i title="{{\'global.documentState.PENDING_SIGN\' | translate}}" class="fa fa-circle text-blue v-t fa-lg "></i></span>';
            template += '                   <span ng-if="document.document.docStatus == \'SIGNED\'"><i title="{{\'global.documentState.SIGNED\' | translate}}" class="fa fa-circle text-success fa-lg v-t"></i></span>';
            template += '                   <span ng-if="document.document.docStatus == \'REJECTED\'"><i title="{{\'global.documentState.REJECTED\'| translate}}" class="fa fa-circle text-danger fa-lg v-t"></i></span>';
            template += '                   <span ng-if="document.document.docStatus == \'COMPLETE\'"><i title="{{\'global.documentState.COMPLETE\'| translate}}" class="fa fa-dot-circle-o text-success fa-lg v-t"></i></span>';
            template += '               </div>';
            template += '               <div class="col-xs-9 col-sm-10 col-md-5 col-lg-4 small-lg">';
            template += '                   <annexa-href-permission-document document="document.document" name-properties="\'code\'" origin="DOSSIER"></annexa-href-permission-document>'
            template += '               </div>';
            template += '               <div class=" col-md-6 _600">';
            template += '                   <span >{{document.document.name}}</span>';
            template += '               </div>';
            template += '           </div>';
            template += '       </div>';
            template += '       <div class="col-sm-3 col-xxl-2">';
            template += '           <annexa-document-actions-button dossier-transaction-document="document" transaction-init="transaction" list="true" module="EXPED" object-to-call="archiveClassification"></annexa-document-actions-button>'
            template += '       </div>'; // /col
            template += '    </div>';  // /row
            template += '   </div>'; // /documentRows
            template += '</div>';

            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                documents: '=',
                deleteFunction: '=',
                actionFunction: '=',
                actualTransaction: '=',
                transaction: '=',
                page: '=',
                itemsPage: '='
            },
            link: function (scope, element, attr) {
                scope.$on('annexaBoxDocumentsTramUpdateCounter', function (event, args) {
                	if(!args.transactionId || (scope.transaction && args.transactionId == scope.transaction.id)){
	                	if (args.content && args.relationType) {
	                		scope.filteredContent = $linq(args.content).where("x => x.relationType == '" + args.relationType + "'").toArray();
	                		if (args.relationType == 'DOCUMENT') {
	                    		scope.labelDossierTransactionDocs = 'success';
	                    		scope.labelDossierTransactionWorkDocs = '';
	                    		scope.labelDossierTransactionInternalDocs = '';
	                    		scope.counterDossierTransactionDocs = scope.filteredContent.length;
	                    	} else if (relationType && relationType == 'WORK_DOCUMENT') {
	                    		scope.labelDossierTransactionDocs = '';
	                    		scope.labelDossierTransactionWorkDocs = 'success';
	                    		scope.labelDossierTransactionInternalDocs = '';
	                    		scope.counterDossierTransactionWorkDocs = scope.filteredContent.length;
	                		} else {
	                    		scope.labelDossierTransactionDocs = '';
	                    		scope.labelDossierTransactionWorkDocs = '';
	                    		scope.labelDossierTransactionInternalDocs = 'success';
	                    		scope.counterDossierTransactionInternalDocs = scope.filteredContent.length;
	                		}
	                	} else if (args.content) {
	                		scope.counterDossierTransactionDocs = $linq(args.content).where("x => x.relationType == 'DOCUMENT'").count();
	                		scope.counterDossierTransactionWorkDocs = $linq(args.content).where("x => x.relationType == 'WORK_DOCUMENT'").count();
	                		scope.counterDossierTransactionInternalDocs = $linq(args.content).where("x => x.relationType == 'INTERNAL_DOCUMENT'").count();
	                		if(scope.documents){
		                		if(scope.labelDossierTransactionDocs == 'success'){
		                			scope.filteredContent = $linq(scope.documents).where("x => x.relationType == 'DOCUMENT'").toArray();
		                		}else if(scope.labelDossierTransactionWorkDocs == 'success'){
		                			scope.filteredContent = $linq(scope.documents).where("x => x.relationType == 'WORK_DOCUMENT'").toArray();
		                		}else if(scope.labelDossierTransactionInternalDocs == 'success'){
		                			scope.filteredContent = $linq(scope.documents).where("x => x.relationType == 'INTERNAL_DOCUMENT'").toArray();
		                		}
	                		}else{
	                			if(scope.filteredContent){
	                				scope.filteredContent.length = 0;
	                			}else{
	                				scope.filteredContent = [];
	                			}
	                		}
	                	}
	                	$rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounterReloadTable', { reload:true });
                	}
                });

                scope.counterDossierTransactionDocs = 0;
                scope.counterDossierTransactionWorkDocs = 0;
                scope.counterDossierTransactionInternalDocs = 0;
                scope.labelDossierTransactionDocs = '';
                scope.labelDossierTransactionWorkDocs = '';
                scope.labelDossierTransactionInternalDocs = '';
                scope.filteredContent = [];
                if (scope.documents) {
                	scope.filteredContent = $linq(scope.documents).where("x => x.relationType == 'DOCUMENT'").toArray();
                	scope.labelDossierTransactionDocs = 'success';
                	scope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: scope.documents, transactionId:((scope.transaction)?scope.transaction.id:undefined) });
                }

                scope.filterRelationType = function (relationType) {
                	scope.filteredContent = $linq(scope.documents).where("x => x.relationType == '" + relationType + "'").toArray();
                	if (relationType && relationType == 'DOCUMENT') {
                		scope.labelDossierTransactionDocs = 'success';
                		scope.labelDossierTransactionWorkDocs = '';
                		scope.labelDossierTransactionInternalDocs = '';
                	} else if (relationType && relationType == 'WORK_DOCUMENT') {
                		scope.labelDossierTransactionDocs = '';
                		scope.labelDossierTransactionWorkDocs = 'success';
                		scope.labelDossierTransactionInternalDocs = '';
                	} else {
                		scope.labelDossierTransactionDocs = '';
                		scope.labelDossierTransactionWorkDocs = '';
                		scope.labelDossierTransactionInternalDocs = 'success';
                	}
                }

                element.html(getTemplate(scope)).show();
                scope.archiveClassification = ((TramFactory.Dossier && TramFactory.Dossier.archiveClassification)?TramFactory.Dossier.archiveClassification:undefined);
                $compile(element.contents())(scope);
                
                scope.$on('documentActionButtonExecuted', function (event, args) {
                    if (args.button == 'deleteDossierTransactionDocument') {
                    	var indexRemoved = $linq(scope.filteredContent).indexOf("x => x.document.id == " + args.data.documentId);
                        
                        if (indexRemoved != -1) {
                        	var relationTypeBeforeDelete = scope.filteredContent[indexRemoved].relationType;
                            scope.filteredContent.splice(indexRemoved, 1);
                        	scope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: scope.filteredContent, relationType: relationTypeBeforeDelete, transactionId: ((scope.transaction)?scope.transaction.id:undefined) });
                        }
                    } else if (args.button == 'updateRelationTypeDossierTransactionDocument') {
                        var indexDTD = $linq(scope.documents).indexOf("x => x.id == " + args.data.id);

                        if (indexDTD != -1) {
                        	var relationTypeBeforeUpdate = scope.documents[indexDTD].relationType;
                            scope.documents[indexDTD].relationType = args.data.relationType;
                            scope.filteredContent = $linq(scope.documents).where("x => x.relationType == '" + relationTypeBeforeUpdate + "'").toArray();
                            scope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: scope.documents, transactionId: ((scope.transaction)?scope.transaction.id:undefined) });
                        }                    	
                    }
                });

                scope.$on('SendToSignModalFinished', function (event, args) {
                    if (args.data) {
                        _.forEach(args.data, function (item) {
                            var indexDoc = $linq(scope.documents).indexOf("x => x.document.id == " + item.id);

                            if (indexDoc != -1) {
                                scope.documents[indexDoc].document.docStatus = item.docStatus;
                            }
                        })
                    }
                });
                
            }
        }
    }])
    .directive('annexaBoxRegisterEntries', ['$compile', function ($compile) {
        var getTemplate = function (scope) {
            var template = '';
            template += '<div ng-if="registerEntries.length==0 && type == \'INPUT\'" class="text-center cursiva-grey" translate="global.literals.empty_registerEntries">Add</div>';
            template += '<div ng-if="registerEntries.length==0 && type == \'OUTPUT\'" class="text-center cursiva-grey" translate="global.literals.empty_registerEntriesOutput">Add</div>';
            template += '<div ng-if="registerEntries.length>0" class="striped" >';
            template += '   <div class="row p-y-sm" ng-repeat="registerEntry in registerEntries">';
            template += '       <div class="col-xs-11">';
            template += '           <div class="row m-b-xs">';
            template += '               <div class="col-xs-10 col-sm-5 col-xl-3 hellip ">';
            template += '                   <annexa-href-permission-reg-input register-entry="registerEntry"></annexa-href-permission-reg-input>'
            template += '               </div>';
            template += '               <div class="col-sm-7 col-xl-9  _600">';
            template += '                   <span>{{registerEntry.extract}}</span>';
            template += '               </div>';
            template += '           </div>';
            template += '       </div>';
            template += '       <div class="col-xs-1">';
            template += '           <annexa-delete-button class="pull-right" delete-function="deleteFunction" delete-object="registerEntry" delete-object-index="$index" actual="true"></annexa-delete-button>';
            template += '       </div>';
            template += '   </div>';
            template += '</div>';

            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                registerEntries: '=',
                deleteFunction: '=',
                type: '@'
            },
            link: function (scope, element, attr) {
                element.html(getTemplate(scope)).show();
                $compile(element.contents())(scope);
            }
        }
    }])
    .directive('annexaBoxDossiers', ['$compile', function ($compile) {
        var getTemplate = function (scope) {
            var template = '';
            template += '<div ng-if="dossiers.length==0" class="text-center cursiva-grey" translate="global.literals.empty_dossiers">Empty</div>';
            template += '<div ng-if="dossiers.length>0">';
            template += '   <div class="row ng-class:{\'m-b-p-b-sm-b-b\' : !$last} " ng-repeat="dossier in dossiers">';
            template += '       <div class="col-xs-11">';
            template += '           <div class="row m-b-xs">';
            //template += '               <div class="col-sm-3 small-lg">';
            //template += '                   <span>{{dossier.dossierNumber}}</span>';
            template += '               <div class="col-xs-10 col-sm-3 col-md-4 col-lg-3 hellip ">';
            template += '                   <a class="text-primary _400 small-lg" href="" ui-sref="annexa.tram.pending.viewdossier({dossier: dossier.id, dossierTransaction: (dossier.actualDossierTransactionId ? dossier.actualDossierTransactionId : 0)})" target="_blank"> {{dossier.dossierNumber}}</a>';
            template += '               </div>';
            template += '               <div ng-class="dossier.closed ? \' col-sm-5 col-md-8 col-lg-5 \' : \' col-sm-6 col-md-8 col-lg-6 \'">';
            template += '                   <span class=" _600">{{dossier.subject}}</span>';
            template += '               </div>';
            template += '               <div class="col-sm-3 col-md-12 col-lg-3" ng-if="!dossier.closed">';
            template += '                   <span class="label label-xxs" translate="global.literals.in_process">In proces</span>';
            template += '               </div>';
            template += '               <div class="col-sm-4 col-md-12 col-lg-4" ng-if="dossier.closed">';
            template += '                   <span class="label label-xxs success" ng-if="dossier.dossierStatus != \'CANCELLED\'" translate="global.literals.ended">Ended</span>';
            template += '                   <span class="label label-xxs danger" ng-if="dossier.dossierStatus == \'CANCELLED\'" translate="CANCELLED">Cancelled</span>';
            template += '                   <span class="small text-success">{{dossier.endDate | date: \'dd/MM/yyyy HH:mm:ss\'}}</span>';
            template += '               </div>';
            template += '           </div>';
            template += '       </div>';
            template += '       <div class="col-xs-1">';
            template += '           <annexa-delete-button class="pull-right" delete-function="deleteFunction" delete-object="dossier" delete-object-index="$index" actual="true"></annexa-delete-button>';
            template += '       </div>';
            template += '   </div>';
            template += '</div>';
            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                dossiers: '=',
                deleteFunction: '='
            },
            link: function (scope, element, attr) {
                element.html(getTemplate(scope)).show();
                $compile(element.contents())(scope);
            }
        }
    }])
    .directive('annexaBoxTasks', ['$filter', '$compile', 'HelperService', '$rootScope', 'globalModals', 'AnnexaFormlyFactory', 'TaskFactory', 'AnnexaModalFactory', 'RestService',
        function ($filter, $compile, HelperService, $rootScope, globalModals, AnnexaFormlyFactory, TaskFactory, AnnexaModalFactory, RestService) {
            var getTemplate = function (scope) {
                var template = '';
                template += '<div ng-if="tasks.length==0" class="text-center cursiva-grey" translate="global.literals.empty_receivers">Add</div>';
                template += '<div ng-if="tasks.length>0" class="scroll-x p-b">';
                template += '   <table class="wi-100 m-b  b-b tableWidthGreenButtons">';
                template += '       <thead >';
                template += '           <tr class=" m-b-xs _600 b-b hidden-md-down">';
                template += '               <th class="p-b-sm"><span class="text-primary" translate="global.literals.days">days</span> / <span class="text-danger" translate="global.literals.state">state</span></th>';
                template += '               <th class="p-b-sm"><span translate="global.literals.task_name">name</span></th>';
                template += '               <th class="p-b-sm"><span translate="global.literals.created_for">started</span></th>';
                template += '               <th class="p-b-sm"><span translate="global.literals.creation_date">start date</span></th>';
                template += '               <th class="p-b-sm"><span translate="global.literals.assign_to">assign</span></th>';
                template += '               <th class="p-b-sm text-center"><span translate="global.literals.actions">actions</span></th>';
                template += '           </tr>';
                template += '       </thead>';
                template += '       <tbody>';
                template += '           <tr class=" ng-class:{\'m-b-p-b-sm-b-b\' : !$last} " ng-repeat="task in tasks">';
                template += '               <td >';
                template += '                   <i ng-if="task.endedTask && task.finishOk" class="fa fa-check text-success"></i>';
                template += '                   <i ng-if="task.endedTask && !task.finishOk" class="fa fa-times-circle fa-lg text-danger"></i>';
                template += '                   <span ng-if="!task.endedTask && task.startDate"  class="label ng-class:getClass(task)">{{getLabel(task)}}</span>';
                template += '               </td>';
                template += '               <td>';
                template += '                   {{task.description}}';
                template += '               </td>';
                template += '               <td>';
                template += '                   {{task.createdUser.name + " " + task.createdUser.surename1}}';
                template += '               </td>';
                template += '               <td>';
                template += '                   {{task.createdDate | date:\'dd/MM/yyyy\'}}';
                template += '               </td>';
                template += '               <td >';
                template += '                   {{task.user.name + " " + task.user.surename1}}';
                template += '               </td>';
                template += '               <td class="p-y-sm text-center ">';
                template += '                   <div class="btn-group ng-class: { \'dropdown\' : !task.startDate && !task.endedTask && !task.endDate }">';
                template += '                       <a class="btn primary" ui-sref="annexa.tasks.assignedTasks.edit({ task: task.id})" target="_blank">';
                template += '                           <i class="fa fa-eye" aria-hidden="true"></i>';
                template += '                           <span translate="global.literals.see">Show</span>';
                template += '                       </a>';
                template += '                       <button ng-if="!task.startDate && !task.endedTask && !task.endDate" class="btn primary dropdown-toggle" data-toggle="dropdown" title="{{ \'global.literals.showOptions\' | translate }}"><span class="sr-only">{{ \'global.literals.showOptions\' | translate }}</span></button>';
                template += '                       <div ng-if="!task.startDate && !task.endedTask && !task.endDate" class="dropdown-menu primary pull-right">';
                template += '                           <button type="button" class="dropdown-item" ng-click="rejectTask(task.id)">';
                template += '                               <span translate="global.literals.rejectTask">Edit Online</span>';
                template += '                           </button>';
                template += '                       </div>';
                template += '                   </div>';
                template += '               </td>';
                template += '           </tr>';
                template += '       </tbody>';
                template += '   </table>';
                template += '</div>';

                return template;
            };

            var getMaxDays = function (task, $rootScope) {
                var holidays = [];
                var workinDays = 0;
                if ($rootScope && $rootScope.LoggedUser && $rootScope.LoggedUser.entity && $rootScope.LoggedUser.entity.holidays) {
                    holidays = $rootScope.LoggedUser.entity.holidays;
                }
                workinDays = HelperService.getWorkingDays(new Date(task.createdDate), new Date(), holidays);
                return task.days - workinDays;
            }
            return {
                restrict: 'E',
                replace: true,
                scope: {
                    tasks: '=',
                    deleteFunction: '='
                },
                link: function (scope, element, attr) {
                    scope.getClass = function (task) {
                        return HelperService.getWorkingDaysClass(getMaxDays(task, $rootScope));
                    };
                    scope.actual = false;
                    if (scope.deleteFunction) {
                        scope.actual = true;
                    }

                    scope.rejectTask = function (data) {
                        if (scope.tasks && scope.tasks.length > 0) {
                            var aux = HelperService.findFirstElementWhere(scope.tasks, 'id', data, -1);
                            var modal = angular.copy(globalModals.taskUpdateComment);
                            modal.title = 'global.literals.rejectTask';
                            modal.annexaFormly.model = {};
                            modal.annexaFormly.model.modal_body = {task: aux};
                            modal.annexaFormly.options = {};
                            modal.annexaFormly.options.formState = {readOnly: false};
                            modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.placeholder = $filter('translate')('global.literals.commentReject');
                            modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.required = true;
                            var rejectTaskComplete = function () {
                                if (modal.annexaFormly.model.modal_body.task && modal.annexaFormly.model.modal_body.task.comments) {
                                    modal.annexaFormly.model.modal_body.task.endedTask = true;
                                    modal.annexaFormly.model.modal_body.task.endDate = new Date();
                                    modal.annexaFormly.model.modal_body.task.finishOk = false;

                                    TaskFactory.updateTask(modal.annexaFormly.model.modal_body.task, './api/tasks/rejectTask').then(function (data) {
                                        modal.close();
                                    }).catch(function (msg, error) {
                                        modal.close();
                                    });
                                }
                            };
                            AnnexaFormlyFactory.showModal("modalUpdateComentTask", modal, rejectTaskComplete, false);
                        }
                    }
                    scope.searchTasks = function (value) {
                        var valueOk = value;
                        if (valueOk && valueOk.val) {
                            valueOk = valueOk.val;
                        }
                        if (!valueOk) {
                            return [];
                        } else if (valueOk != '*' && valueOk.length < 3) {
                            return [];
                        } else {
                            if (valueOk == '*') {
                                valueOk = '';
                            }
                            return RestService.loadData('common', 'User', valueOk).then(function (dataSearch) {
                                var usersLoad = [];
                                if (dataSearch.data && dataSearch.data.content && dataSearch.data.content.length > 0) {
                                    angular.forEach(JSOG.decode(dataSearch.data.content), function (val, key) {
                                        var name = val.name + " " + val.surename1;
                                        if (val.surename2) {
                                            name = name + " " + val.surename2;
                                        }
                                        usersLoad.push({'id': val.id, 'user': val, 'value': name});
                                    });
                                }
                                return usersLoad;
                            }).catch(function () {
                                return [];
                            })
                        }
                    }

                    scope.getLabel = function (task) {
                        var maxDays = getMaxDays(task, $rootScope);
                        if (maxDays > 5) {
                            return "+" + maxDays;
                        } else {
                            return maxDays;
                        }
                    };

                    element.html(getTemplate(scope)).show();
                    $compile(element.contents())(scope);
                }
            }
        }])
    .directive('annexaDossierTransaction', ['$state', '$filter', '$rootScope', 'RestService', 'globalModals', 'AnnexaFormlyFactory', 'AnnexaModalFactory', 'TramFactory', 'Language', 'HelperService', 'dialogs', 'ErrorFactory', 'AnnexaPermissionsFactory', 'SecFactory', '$q', 'SecModals', 'DialogsFactory', '$stateParams', 'apiAdmin'
    	, function ($state, $filter, $rootScope, RestService, globalModals, AnnexaFormlyFactory, AnnexaModalFactory, TramFactory, Language, HelperService, dialogs, ErrorFactory, AnnexaPermissionsFactory, SecFactory, $q, SecModals, DialogsFactory, $stateParams, apiAdmin) {
        return {
            restrinct: 'E',
            replace: true,
            scope: {
                transaction: '=',
                previous: '=',
                order: '@',
                languageColumn: '=',
                viewData: '=',
                backbutton: "=",
                isUpdateDossier: '=',
                registerOutputBox: '=',
                notificationBox: '=',
                openTransaction: '=',
                activeTab: '=',
                loggedUser: '='
            },
            templateUrl: './views/layout/viewdossier/general_left/transaction.html',
            controller: ['$scope', '$rootScope', 'TramFactory', function ($scope, $rootScope, TramFactory) {
            	$scope.loggedUser = $rootScope.LoggedUser;
            	
                var getCurrentDateFormat = function () {
                    var now = new Date();
                    var year = now.getFullYear();
                    var month = now.getMonth();
                    var day = now.getDate();
                    var hour = now.getHours();
                    var min = now.getMinutes();
                    return day + "/" + month + "/" + year + ", " + hour + ":" + min;
                };
                $scope.guided = false;
                if (TramFactory.Dossier && TramFactory.Dossier && TramFactory.Dossier.procedure) {
                    $scope.guided = TramFactory.Dossier.procedure.guided;
                }
                $scope.transactionTabSelected = function ($event, tab) {
                    $scope.activeTab = tab;
                    if(tab == 2){
                    	$scope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: $scope.transaction.documents, transactionId:(($scope.transaction)?$scope.transaction.id:undefined) });
                    }
                    if(tab == 4){
                    	$scope.$broadcast('proposalDocumentsList', {docs: $scope.transaction.documents});
                    }
                    $rootScope.$broadcast('addRemovedObjectEvent', {addRemoveObject: true});
                    $rootScope.$broadcast('transactionTabSelected', {tab: tab});
                }

                $scope.daysexpire = $scope.transaction.transactionRemainingDays;
                $scope.expiredate = HelperService.getExpirationDate(new Date(), $scope.daysexpire, $rootScope.LoggedUser.entity.holidays);
                
                var canEditDossierTransactionResponsibleUser = function() {
                	var res = false;
                	
            		if (TramFactory.Dossier.responsibleUser && TramFactory.Dossier.responsibleUser.id == $rootScope.LoggedUser.id) {
            			//El usuario responsable del expediente simpre que tenga el permiso, puede modificar el usuario responsable del trámite
            			res = true;
            		} else {
                		//Los usuarios del perfíl responsabe del expediente simpre que tengan el permiso, pueden modificar el usuario responsable del trámite
            			angular.forEach($scope.transaction.processProfiles, function (profile) {
            				var users = $linq(profile.profile.userProfiles).selectMany('x => x.user', '(p, obj) => p').toArray();
            				users = $linq(users).where("x => !x.expiryDate && !x.deleted").distinctBy("x => x.id").toArray();
    	
    	                    angular.forEach(users, function (user) {
    	                    	if (user.id == $rootScope.LoggedUser.id) {
    	                    		res = true;
    	                    	}
    	                    });
            			});       
                	}
                	
                	return res;
                };                
				$scope.canEditDossierTransactionResponsibleUser = canEditDossierTransactionResponsibleUser();
				
				var canSetDossierTransactionResponsibleUser = function () {
					var res = false;
                	
                	if (!$rootScope.esetMode) {
                		//El usuario actual se puede hacer responsable del trámite, aunque no tenga el permiso y simpre que se encuentre dentro del perfil responsable del trámitre
            			angular.forEach($scope.transaction.processProfiles, function (profile) {
            				var users = $linq(profile.profile.userProfiles).selectMany('x => x.user', '(p, obj) => p').toArray();
            				users = $linq(users).where("x => !x.expiryDate && !x.deleted").distinctBy("x => x.id").toArray();
    	
    	                    angular.forEach(users, function (user) {
    	                    	if (user.id == $rootScope.LoggedUser.id) {
    	                    		res = true;
    	                    	}
    	                    });
            			});
                	}
                	
					return res;
				}
				$scope.canSetDossierTransactionResponsibleUser = canSetDossierTransactionResponsibleUser();
                
                $scope.comboTransactionResponsibleUser = [];                
                
                $scope.loadComboTransactionResponsibleUser = function () {
                	$scope.comboTransactionResponsibleUser = [];
	                angular.forEach($scope.transaction.processProfiles, function (profile) {
	                    var users = $linq(profile.profile.userProfiles).selectMany('x => x.user', '(p, obj) => p').toArray();
	                    users = $linq(users).where("x => !x.expiryDate && !x.deleted").distinctBy("x => x.id").toArray();
	
	                    angular.forEach(users, function (user) { 
	                    	if (!$scope.transaction.responsibleUser || user.id != $scope.transaction.responsibleUser.id) {
		                    	var responsibleUser = "";
		                        if (user.name) {
		                      	  responsibleUser = responsibleUser + user.name + " ";
		                        }
		                        if (user.surename1) {
		                      	  responsibleUser = responsibleUser + user.surename1 + " ";
		                        }
		                        if (user.surename2) {
		                      	  responsibleUser = responsibleUser + user.surename2;
		                        }
		                    	
		                    	$scope.comboTransactionResponsibleUser.push({id: user.id, description: responsibleUser});
	                    	}
	                    });
	                });
                };   
                
                $scope.loadComboTransactionResponsibleUser();
	                
                $scope.auditDossierTransaction = function () {
                    if ($scope.transaction && $scope.transaction.id) {
                        window.open($state.href('annexa.admin.audit_dossier_transaction', {"objectId": $scope.transaction.id}), '_blank');
                    }
                };
                // "Retrocedir" fase de tramitació crea un modal de confirmació amb textarea de "motiu"
                $scope.goBack = function () {
                	var trans = this.transaction;
                    if (trans.transactionType && trans.transactionType.transactionSubtype 
                    		&& trans.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[1].id) {
                        DialogsFactory.confirm('global.tram.literals.goback_transaction_title', 'global.tram.literals.confirmGoBackTransaction').then(function () {
                        	existsDocumentsGoBackConfirm();
                        }).catch(function () {
                            //Empty
                        });
                    } else {
                    	existsDocumentsGoBackConfirm();
                    }
                };
                
                var existsDocumentsGoBackConfirm = function () {
                    if ($scope.transaction.documents.length > 0) {
                        var dlg = dialogs.confirm(
                            $filter('translate')('global.tram.literals.dialog_exists_documents_goback'),
                            $filter('translate')('global.tram.literals.dialog_exists_documents_goback_confirm'),
                            {
                                animation: true,
                                backdrop: 'static',
                                keyboard: true,
                                size: ''
                            }
                        );
                        dlg.result.then(function (btn) {
                            $scope.goBackOK();
                        }, function (btn) {

                        });
                    } else {
                        $scope.goBackOK();
                    }                	
                }
                
                $scope.goBackOK = function () {
                    var data = {
                        row: true,
                        colClass: ' col-sm-12',
                        labelClass: ''
                    };
                    var actualTransactionsNum = $linq(TramFactory.Dossier.dossierTransactions).count("x => x.actual");
                    if (actualTransactionsNum > 1) {
                        var modal = angular.copy(globalModals.goBackTransactionPopup);
                        modal.annexaFormly.model = {};
                        modal.annexaFormly.model.modal_body = {};
                        modal.annexaFormly.options = {};
                        modal.extra = {transaction: this.transaction};
                        AnnexaFormlyFactory.showModal('modalGoBackTransaction', modal, $scope.goBackSubmitPopup, false);

                    } else {
                        var modal = angular.copy(globalModals.goBackTransactionPopupWizard);
                        _.forEach(modal.wizard.steps, function (step, key) {
                            modal.wizard.steps[key].annexaFormly.model = {};
                            modal.wizard.steps[key].annexaFormly.options = {};
                            if (key == 1) {
                                modal.wizard.steps[key].annexaFormly.model = {
                                    transactionNoGuiadaList: [],
                                    haveReopenPermission: AnnexaPermissionsFactory.havePermission("reopen_dossier_transaction")
                                };
                                modal.wizard.steps[key].annexaFormly.fields[1].templateOptions.loadFunction = $scope.searchProfiles;
                                modal.wizard.steps[key].annexaFormly.fields[1].templateOptions.atributes = {
                                    transaction: $scope.transaction,
                                    backbutton: $scope.backbutton
                                };
                                modal.wizard.steps[key].annexaFormly.fields[1].templateOptions.numActuals = $linq(TramFactory.Dossier.dossierTransactions).count("x => x.actual");
                            }
                            if (key == 0) {
                                modal.wizard.steps[key].annexaFormly.fields[0].templateOptions.closedDossierTransactions = $linq(TramFactory.Dossier.dossierTransactions).where("x => !x.actual").toArray();
                            }
                        });
                        modal.wizard.modal = modal;
                        modal.extra = {transaction: this.transaction};
                        AnnexaFormlyFactory.showModal('modalGoBackTransaction', modal, $scope.goBackSubmitPopup, false, true);
                    }

                }
                // Submit del modal de confirmació al "Retrocedir" fase tramitació
                $scope.goBackSubmitPopup = function () {
                    var actualTransactionsNum = $linq(TramFactory.Dossier.dossierTransactions).count("x => x.actual")
                    var self = this;
                    var motiu = "";
                    if (actualTransactionsNum > 1) {
                        motiu = this.annexaFormly.model.motiu;
                    } else {
                        motiu = this.wizard.steps[0].annexaFormly.model.motiu;
                    }

                    var okFields = false;
                    var transactionLists = [];
                    var dossierTransactionToReopen = null;
                    if (this.wizard && this.wizard.steps && this.wizard.steps[1] && this.wizard.steps[1].annexaFormly && this.wizard.steps[1].annexaFormly.fields && this.wizard.steps[1].annexaFormly.fields[1] && this.wizard.steps[1].annexaFormly.fields[1].templateOptions && this.wizard.steps[1].annexaFormly.fields[1].templateOptions.numActuals < 2) {
                        if (this.wizard.steps[1].annexaFormly.model.dossierTransactionType == 1 || !AnnexaPermissionsFactory.havePermission("reopen_dossier_transaction")) {
                            if (this.wizard.steps[1].annexaFormly.model && this.wizard.steps[1].annexaFormly.model.transactionNoGuiadaList && this.wizard.steps[1].annexaFormly.model.transactionNoGuiadaList.length > 0) {
                                okFields = true;
                                transactionLists = this.wizard.steps[1].annexaFormly.model.transactionNoGuiadaList;
                            }
                        } else if (this.wizard.steps[1].annexaFormly.model.dossierTransactionType == 2) {
                            if (this.wizard.steps[1].annexaFormly.model && this.wizard.steps[1].annexaFormly.model.dossierTransactions) {
                                okFields = true;
                                dossierTransactionToReopen = this.wizard.steps[1].annexaFormly.model.dossierTransactions;
                            }
                        }
                    } else {
                        okFields = true;
                    }
                    if (okFields) {
                        TramFactory.deleteDossierTransaction($scope.transaction.id, transactionLists, motiu, dossierTransactionToReopen).then(function (data) {
                            if (data != null) {
                                self.close();
                                $state.reload();
                            }
                        }).catch(function (error) {
                            self.alerts.push({msg: 'global.errors.unknownDelete'});
                        });
                    } else {
                        this.alerts.push({msg: 'global.tram.errors.noTransactionsFinal'});
                    }
                };
                $scope.reopen = function () {
                    var dlg = dialogs.confirm(
                        $filter('translate')('global.tram.literals.reopen_dossier_transaction'),
                        $filter('translate')('global.tram.literals.reopen_dossier_transaction_confirm'),
                        {
                            animation: true,
                            backdrop: 'static',
                            keyboard: true,
                            size: ''
                        }
                    );
                    dlg.result.then(function (btn) {
                        TramFactory.reopenDossierTransaction($scope.transaction.id).then(function (data) {
                            if (data != null) {
                                $state.reload();
                                var err = dialogs.notify(
                                    '',
                                    $filter('translate')('global.tram.literals.reopen_dossier_transaction_OK'),
                                    {
                                        animation: true,
                                        backdrop: 'static',
                                        keyboard: true,
                                        size: ''
                                    }
                                );
                            }
                        }).catch(function (error) {
                            var err = dialogs.error(
                                $filter('translate')('DIALOGS_ERROR_MSG'),
                                $filter('translate')('global.tram.literals.reopen_dossier_transaction_error'),
                                {
                                    animation: true,
                                    backdrop: 'static',
                                    keyboard: true,
                                    size: ''
                                }
                            );
                        });
                    }, function (btn) {

                    });
                };

                $scope.completeTransactionPopupNoGuiats = function () {
                    var trans = this.transaction;
                    var obligOK = true;
                    angular.forEach(trans.customFields, function (value, key) {
                        if (value.required) {
                            var valueField = angular.fromJson(value.value);
                            if (!valueField || !valueField.value) {
                                obligOK = false;
                            }
                        }
                    });

                    if (obligOK) {
                        var data = {
                            row: true,
                            colClass: ' col-sm-12',
                            labelClass: ''
                        };
                        var modal = angular.copy(globalModals.completeTransactionPopup);
                        modal.alerts = [];
                        modal.annexaFormly = new AnnexaFormly();

                        var fields = [];
                        fields.push(modal.annexaFormly.createField('profile', 'annexaLoadUserAndComboButtonRow', 'col-sm-12', new AnnexaFormlyLoadUserFieldTemplateOptions('text', 'global.literals.profile_handler_finally', false, false, [], $scope.searchProfiles, function () {
                        }, 'Profile', 'global.literals.select', {transaction: this.transaction}, undefined, 'global.reg.literals.profileOrUserName', false, false, true), data));
                        modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
                        modal.annexaFormly.model = {};
                        modal.annexaFormly.model.modal_body = {transactionNoGuiadaList: []};
                        modal.annexaFormly.options = {};
                        modal.annexaFormly.options.formState = {readOnly: false};
                        modal.extra = {transaction: this.transaction};
                        AnnexaFormlyFactory.showModalPromise("modalCloseTransaction", modal, $scope.completeTransaction, false, false, 'global.tram.literals.completetransaction').then(function (modalAux) {
                            modal.modal = modalAux;
                            modal.close = modalAux.element.scope().close;
                            modalAux.element.modal();
                            angular.element(".modal-title").focus();
                        }).catch(function () {
                            var a = 0;
                        });
                    } else {
                        DialogsFactory.error('global.literals.customFieldOblig', $filter('translate')('DIALOGS_ERROR_MSG'));
                    }
                };
                
                $scope.searchProfiles = function (value) {
                    if (!value || !value.val) {
                        return [];
                    } else if (value.val != '*' && value.val.length < 3) {
                        return [];
                    } else {
                        if (value.val == '*') {
                            value.val = '';
                        }
                        return RestService.filterData('common', 'Profile', {}, {search: value.val}, 0, 100, '', 3)
                            .then(function (data) {
                                var items = [];

                                if (data.data && data.data.content) {
                                    _.forEach(JSOG.decode(data.data.content), function (item) {
                                        items.push({
                                            id: item.id,
                                            value: item[Language.getActiveColumn()],
                                            profile: item
                                        });
                                    })
                                }
                                return items;
                            }).catch(function () {
                                return [];
                            });
                    }
                };

                // Aqui nos iremos al formulario para completar un tramite
                $scope.completeTransaction = function () {
                    var self = this;
                    if (self.annexaFormly.model.modal_body.transactionNoGuiadaList && self.annexaFormly.model.modal_body.transactionNoGuiadaList.length > 0) {
                        if ($rootScope.esetMode && self.extra.transaction && self.extra.transaction.transactionType && self.extra.transaction.transactionType.finallyType) {
                            self.alerts.push({msg: 'global.tram.errors.noCompleteFinallyTransactionHaveOpenTransactions'});
                        } else {
                            TramFactory.completeDossierTransaction(self.extra.transaction, undefined, false, self.annexaFormly.model.modal_body.transactionNoGuiadaList).then(function (data) {
                                var actualTransactionsWithPermission = [];
                                if (data && data.actualsTransaction) {
                                    data = JSOG.decode(data);
                                    _.forEach(data.actualsTransaction, function (value) {
                                        if (value.processProfiles) {
                                            _.forEach(value.processProfiles, function (value2) {
                                                if (actualTransactionsWithPermission.length == 0 && AnnexaPermissionsFactory.haveProfile(value2.profile)) {
                                                    actualTransactionsWithPermission.push(value);
                                                }
                                            })
                                        }
                                    });
                                }
                                if (actualTransactionsWithPermission && actualTransactionsWithPermission.length > 0) {
                                    if (self && self.extra && self.extra.transaction && self.extra.transaction.documents) {
                                        _.forEach(self.extra.transaction.documents, function (document) {
                                            if (document.document && document.document.docStaus == "NEW_AND_ATTACHMENT" && document.relationType == "DOCUMENT") {
                                                document.document.docStauts = "COMPLETE";
                                            }
                                        });
                                    }
                                    self.extra.transaction.actual = false;
                                    $state.transitionTo('annexa.tram.pending.viewdossier', {
                                        "dossier": TramFactory.Dossier.id,
                                        "dossierTransaction": actualTransactionsWithPermission[0].id
                                    });
                                } else {
                                    $state.transitionTo('annexa.tram.pending');
                                }
                                self.close();
                            }).catch(function (error) {
                                self.alerts.push({msg: ErrorFactory.getErrorMessage('dossier', 'complete', error.data)});
                            });
                        }
                    } else {
                        if (TramFactory.isResponsible(TramFactory.Dossier)) {
                            //TODO --> si sol en queda un i no es final no es podra finalitzar
                            var dossierTransactionIsFinal = false;
                            if (self.extra.transaction && self.extra.transaction.transactionType && self.extra.transaction.transactionType.finallyType) {
                                dossierTransactionIsFinal = true;
                            }
                            var openTransactions = $linq(TramFactory.Dossier.dossierTransactions).where("x => x.actual == true").toArray();
                            var canCompleteEset = false;
                            var canCompleteNoEset = false;
                            if ($rootScope.esetMode) {
                                if ((openTransactions && openTransactions.length > 1 && !dossierTransactionIsFinal) || (openTransactions && openTransactions.length == 1 && dossierTransactionIsFinal)) {
                                    canCompleteEset = true;
                                }
                            } else {
                                if ((openTransactions && openTransactions.length > 1) || (openTransactions && openTransactions.length == 1 && dossierTransactionIsFinal)) {
                                    canCompleteNoEset = true;
                                }
                            }
                            if (canCompleteEset || canCompleteNoEset) {
                                TramFactory.completeDossierTransaction(self.extra.transaction, undefined, false, undefined, true).then(function (data) {
                                    self.extra.transaction.actual = false;
                                    if (self && self.extra && self.extra.transaction && self.extra.transaction.documents) {
                                        _.forEach(self.extra.transaction.documents, function (document) {
                                            if (document.document && document.document.docStaus == "NEW_AND_ATTACHMENT" && document.relationType == "DOCUMENT") {
                                                document.document.docStauts = "COMPLETE";
                                            }
                                        });
                                    }
                                    if (TramFactory.isResponsible(TramFactory.Dossier)) {
                                        $state.reload();
                                    } else {
                                        $state.transitionTo('annexa.tram.pending');
                                    }
                                    self.close();
                                }).catch(function (error) {
                                    if (error.data && error.data.message && error.data.message == 'error-requestCompleteDossier-endDossier not found') {
                                        var dlg = dialogs.confirm(
                                            $filter('translate')('global.literals.confirmEndDossierTitle'),
                                            $filter('translate')('global.literals.confirmEndDossierBody'),
                                            {
                                                animation: true,
                                                backdrop: 'static',
                                                keyboard: true,
                                                size: '',
                                                backdropClass: 'zMax',
                                                windowClass: 'zMax2'
                                            }
                                        );

                                        dlg.result.then(function (btn) {
                                            TramFactory.endDossier(self.extra.transaction).then(function (data) {
                                                $state.transitionTo('annexa.tram.pending');
                                                self.close();
                                            }).catch(function (error) {
                                                self.alerts.push({msg: ErrorFactory.getErrorMessage('dossier', 'complete', error.data)});
                                            });
                                        }, function (btn) {
                                            var a = 0;
                                        });
                                    } else {
                                        self.alerts.push({msg: ErrorFactory.getErrorMessage('dossier', 'complete', error.data)});
                                    }
                                });
                            } else {
                                if (dossierTransactionIsFinal) {
                                    self.alerts.push({msg: 'global.tram.errors.noCompleteFinallyTransactionHaveOpenTransactions'});
                                } else {
                                    self.alerts.push({msg: 'global.tram.errors.noTransactions'});
                                }
                            }
                        } else {
                            var openTransactions = $linq(TramFactory.Dossier.dossierTransactions).where("x => x.actual == true").toArray();
                            if (openTransactions && openTransactions.length > 1) {
                                self.alerts.push({msg: 'global.tram.errors.noTransactions'});
                            } else {
                                self.alerts.push({msg: 'global.tram.errors.onlyResponsible'});
                            }
                        }
                    }
                };
                
                $scope.completeTransactionPopup = function () {
                    var haveNotificationCertificate = function(proposal){
                    	var count = 0;
                    	if(proposal && proposal.documents){
                    		_.forEach(proposal.documents, function(proposalDocument){
                    			if(proposalDocument.proposalDocumentType && proposalDocument.proposalDocumentType == 'SCR'){
                    				if(TramFactory.Dossier && TramFactory.Dossier.notifications){
                    					count = count + $linq(TramFactory.Dossier.notifications).count(function(x){
                    						var exist = false;
                    						if(x.documents){
                    							_.forEach(x.documents, function(doc){
                    								if(doc.id == proposalDocument.document.id){
                    									exist = true;
                    								}
                    							})
                    						}
                    						return exist;
                    					});
                    				}
                    			}
                    		});
                    	}
                    	if(count > 0){
                    		return true;
                    	}else{
                    		return false;
                    	}
                    }
                	var trans = this.transaction;
                    if (trans.transactionType && trans.transactionType.transactionSubtype 
                    		&& trans.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[1].id) {
                        DialogsFactory.confirm('global.tram.literals.complete_transaction_title', 'global.tram.literals.confirmCompleteTransaction').then(function () {
                        	pendingSingDocumentsFunction(trans);
                        }).catch(function () {
                            //Empty
                        });
                    } else if (trans.transactionType && trans.transactionType.transactionSubtype 
                    		&& trans.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[3].id && !haveNotificationCertificate(this.proposal)) {
                        DialogsFactory.confirm('global.tram.literals.complete_transaction_title', 'global.tram.literals.confirmCompleteTransactionTransfer').then(function () {
                        	pendingSingDocumentsFunction(trans);
                        }).catch(function () {
                            //Empty
                        });
                    } else {
                    	pendingSingDocumentsFunction(trans);
                    }
                };

                var pendingSingDocumentsFunction = function (trans) {
                    var pendingSingDocuments = 0;
                    if (trans.documents) {
                        pendingSingDocuments = $linq(trans.documents).where("x => x.document.docStatus == 'PENDING_SIGN' || x.document.docStatus == 'NEW'").toArray();
                    }
                    if (pendingSingDocuments.length > 0) {
                        DialogsFactory.error('global.errors.pendingSingDocumentsOrNew', $filter('translate')('DIALOGS_ERROR_MSG'));
                    } else {
                        var notDefinitiveDocuments = 0;
                        if (trans.documents) {
                            notDefinitiveDocuments = $linq(trans.documents).where( function (x) {
                            	return x.document.docStatus == 'NEW_AND_ATTACHMENT' && x.relationType == 'DOCUMENT';
                            }).toArray();
                        }
                        if (notDefinitiveDocuments.length > 0) {
                            DialogsFactory.confirm('global.literals.completeDocument', 'global.tram.literals.not_definitive_documents').then(function () {
                                if (trans.graphNodeId != undefined && trans.graphNodeId != "") {
                                    $scope.completeTransactionPopupGuiats();
                                }
                                else {
                                    $scope.completeTransactionPopupNoGuiats();
                                }
                            }).catch(function () {
                                //Empty
                            });
                        } else {
                            if (trans.graphNodeId != undefined && trans.graphNodeId != "") {
                                $scope.completeTransactionPopupGuiats();
                            }
                            else {
                                $scope.completeTransactionPopupNoGuiats();
                            }
                        }
                    }
                };

                $scope.completeTransactionPopupGuiats = function () {
                    var trans = this.transaction;
                    var obligOK = true;
                    angular.forEach(trans.customFields, function (value, key) {
                        if (value.required) {
                            var valueField = angular.fromJson(value.value);
                            if (!valueField || !valueField.value) {
                                obligOK = false;
                            }
                        }
                    });

                    if (obligOK) {

                        TramFactory.getNextDossierTransactions(trans.id).then(function (data) {
                            if (data) {
                                var node = JSOG.decode(data);
                                if (node) {
                                    var dossiersNonAgreementTransactionsInfo = 
                                  	  $linq(node.dossiersTransactionsInfo).where("x => !x.dossierTransactionDefinition || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype != " 
                                  			  + apiAdmin.transactionSubtypes[2].id + ")").toArray();
                                    node.dossiersTransactionsInfo = dossiersNonAgreementTransactionsInfo;
                                    
                                    var modal = angular.copy(globalModals.completeTransactionPopupGuided);
                                    modal.annexaFormly.model = {};
                                    modal.annexaFormly.model.modal_body = {};
                                    modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.options = node;
                                    modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.transaction = trans;
                                    modal.nextDossierTransactionInfo = node;
                                    modal.transaction = trans;
                                    AnnexaFormlyFactory.showModal("modalCloseTransaction", modal, $scope.completeTransactionGuiat, false);
                                } else {
                                    TramFactory.isFinalTransaction(trans);
                                }
                            } else {
                                TramFactory.isFinalTransaction(trans);
                            }
                        });

                    } else {
                        alert($filter('translate')('global.literals.customFieldOblig'));
                    }
                };
                $scope.completeTransactionGuiat = function (modal) {
                    var self = this;

                    var inputs = modal.annexaFormly.model.modal_body;

                    $scope.changeFreeNodes(this.nextDossierTransactionInfo);

                    TramFactory.completeDossierTransactionGuiat(this.transaction, this.nextDossierTransactionInfo).then(function (data) {
                        if (data && data.id) {
                            self.transaction.actual = false;
                            if (data.id && $stateParams.dossierTransaction != data.id) {
                                $state.transitionTo('annexa.tram.pending.viewdossier', {
                                    "dossier": TramFactory.Dossier.id,
                                    "dossierTransaction": data.id
                                });
                            } else {
                                $state.reload();
                            }
                        } else {
                            $state.transitionTo('annexa.tram.pending');
                        }
                        modal.close();
                    }).catch(function (error) {
                        modal.alerts.push({msg: ErrorFactory.getErrorMessage('dossier', 'complete', error.data)});
                    });
                };

                $scope.changeFreeNodes = function (dossierTransactionInfo) {
                    if (dossierTransactionInfo.graphNodeType == "TransactionFreeNormal" || dossierTransactionInfo.graphNodeType == "TransactionFreeFinal" || dossierTransactionInfo.graphNodeType == "SubprocessFreeNormal") {
                        if (dossierTransactionInfo && dossierTransactionInfo.profile && dossierTransactionInfo.profile.id) {
                            dossierTransactionInfo.profiles = [];
                            dossierTransactionInfo.profiles.push({id: dossierTransactionInfo.profile.id});
                        }
                    }
                    angular.forEach(dossierTransactionInfo.dossiersTransactionsInfo, function (value, key) {
                        $scope.changeFreeNodes(value);
                    });
                };

                $scope.completeTransactionEndDossierTest = function (modal) {
                    var self = this;

                    TramFactory.endDossier(this.atributes.transaction).then(function (data) {
                        $state.transitionTo('annexa.tram.pending');
                        self.modal.close();
                    }).catch(function (error) {
                        modal.alerts.push({msg: ErrorFactory.getErrorMessage('dossier', 'complete', error.data)});
                    });
                };
                
                $scope.getDossierResponsibleUser = function () {
                	return TramFactory.Dossier.responsibleUser;
                }

                $scope.canModifyTransaction = function () {
                    var modifyTransaction = false;
                    if ($scope.transaction && $scope.transaction.processProfiles && $scope.transaction.processProfiles.length > 0) {
                        if ($rootScope.LoggedUser && $rootScope.LoggedUser.userProfiles) {
                            var existProfile = false;
                            angular.forEach($scope.transaction.processProfiles, function (value, key) {
                                if (!existProfile && value.profile && value.profile.id) {
                                    var profiles = $linq($rootScope.LoggedUser.userProfiles).where("x => x.profile.id == " + value.profile.id).toArray();
                                    if (profiles && profiles.length > 0) {
                                        existProfile = true;
                                    }
                                }
                            });
                            if (existProfile) {
                                if ($rootScope.esetMode && $scope.transaction && $scope.transaction.transactionType && $scope.transaction.transactionType.finallyType) {
                                    if (TramFactory.isResponsible($scope.transaction.dossier)) {
                                        modifyTransaction = true;
                                    }
                                } else {
                                    modifyTransaction = true;
                                }
                            }
                        }
                    }
                    return modifyTransaction;
                }
                
              $scope.sendProposalDossierTransaction = function () {
                  var prop = this.proposal;
            	  DialogsFactory.confirm('global.tram.literals.sendProposal', 'global.tram.literals.confirmSendProposal').then(function () {
                	  //1.- comprovar que els camps estiguin informats
                      var propDossierTransaction = $scope.transaction;
                      $scope.proposalDocumentsDT = [];
	                  var documentTypeProp = $rootScope.app.configuration.sec_document_types["SPR"];
	                  if(propDossierTransaction && propDossierTransaction.documents){
	                	  _.forEach(propDossierTransaction.documents, function(documentDT){
	                		  if(documentDT && documentDT.document && documentDT.document.type && _.contains(documentTypeProp.id, documentDT.document.type.id+"")){
	                			  $scope.proposalDocumentsDT.push(documentDT);
	                		  }
	                	  });
	                  }
                      
                      var obligOK = true;
                      var requiredFields = [];
                      angular.forEach(prop, function (value, key) {
                          if (key) {
                        	  switch (key) {
                        	  	case 'proposalType':
                        	  	case 'extract':
                        	  	case 'notificatorProfile':
                        	  		if (!value) {
                        	  			obligOK = false;
                        	  			requiredFields.push(key);
                        	  		}
                        	  		break;
                        	  	case 'notificatorResponsible':
                        	  		if ($rootScope.esetMode && !value) {
                        	  			obligOK = false;
                        	  			requiredFields.push(key);
                        	  		}
                        	  		break;
                        	  	case 'anonymousThirds':
                        	  		if (value == undefined || value == null) {
                        	  			obligOK = false;
                        	  			requiredFields.push(key);
                        	  		}
                        	  		break;
                        	  	case 'organs':
                        	  		if (!value || value.length == 0) {
                        	  			obligOK = false;
                        	  			requiredFields.push(key);
                        	  		} else {
                        	  			var isAgreementOrgan = false;
                        	  			_.forEach(value, function(proposalOrgan){
                        	  				if (proposalOrgan.organ && proposalOrgan.organ.resolutionType == 'AGREEMENT') {
                        	  					isAgreementOrgan = true;
                        	  				}
                        	  			});
                        	  			
                        	  			if (!isAgreementOrgan) {
                                        	obligOK = false;
                                          	requiredFields.push(key);
                        	  			}
                        	  		}
                        	  		break;
                        	  	case 'documents':
                        	  		if ($scope.proposalDocumentsDT && $scope.proposalDocumentsDT.length > 1 
                        	  				&& value && value.length == 0) {
                        	  			obligOK = false;
                        	  			requiredFields.push(key);
                        	  		}
                        	  	case 'amendmentTransactionType':
                        	  	case 'transferTransactionType':
                        	  	case 'cancelTransactionType':
                        	  		if (!propDossierTransaction.graphNodeId && !value) {
                        	  			obligOK = false;
                        	  			requiredFields.push(key);
                        	  		}
                        	  		break;
                        	  }
                          } else {
                        	  obligOK = false;
                          }
                      });

                      if (obligOK) {
                    	  //2.- mirar si el document de la proposta està firmat
                          var proposalDocumentDossierTransactionSigned = 0;
                          var proposalDocumentNotFound = false;
                          if (propDossierTransaction && propDossierTransaction.documents && propDossierTransaction.documents.length > 0
                        		  && prop && prop.documents && prop.documents.length > 0) { //primer mirem si s'ha informat des de la pantalla de proposta
                        	  var proposalDocument = $linq(prop.documents).select("x => x.document").toArray();
                        	  proposalDocumentDossierTransactionSigned = $linq(propDossierTransaction.documents).where("x => x.document.id == " + proposalDocument[0].id).where("x => x.document.docStatus == 'SIGNED'").toArray();
                          } else if ($scope.proposalDocumentsDT && $scope.proposalDocumentsDT.length > 0) { //si no s'ha seleccionat des de la pantalla de proposta
                        	  proposalDocumentDossierTransactionSigned = $linq($scope.proposalDocumentsDT).where("x => x.document.docStatus == 'SIGNED'").toArray();
                          } else {
                        	  proposalDocumentNotFound = true;
                          }
                          if (proposalDocumentNotFound) {
                              DialogsFactory.error('global.sec.errors.noProposalDocumentCreated', $filter('translate')('DIALOGS_ERROR_MSG'));
                          } else if (proposalDocumentDossierTransactionSigned == 0 || proposalDocumentDossierTransactionSigned.length <= 0) {
                        	  DialogsFactory.error('global.sec.errors.noProposalDocumentSigned', $filter('translate')('DIALOGS_ERROR_MSG'));
                          } else {
                              if (propDossierTransaction.graphNodeId != undefined && propDossierTransaction.graphNodeId != "") {
                                  TramFactory.getNextDossierTransactions(propDossierTransaction.id).then(function (data) {
                                      if (data) {
                                          var node = JSOG.decode(data);
                                          if (node) {
                                              var dossiersAgreementTransactionsInfo = 
                                            	  $linq(node.dossiersTransactionsInfo).where("x => !x.dossierTransactionDefinition || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype == " 
                                            			  + apiAdmin.transactionSubtypes[2].id + ")").toArray();
                                              node.dossiersTransactionsInfo = dossiersAgreementTransactionsInfo;
                                              
                                              var modal = angular.copy(globalModals.sendGuidedProposalDossierTransactionPopup);
                                              modal.annexaFormly.model = {};
                                              modal.annexaFormly.model.modal_body = {};
                                              modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.options = node;
                                              modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.transaction = propDossierTransaction;
                                              modal.nextDossierTransactionInfo = node;
                                              modal.transaction = propDossierTransaction;
                                              modal.extra = {proposal: prop};
                                              AnnexaFormlyFactory.showModal("sendProposalDossierTransaction", modal, $scope.sendGuidedProposalDossierTransaction, false);
                                          } else {
                                              TramFactory.isFinalTransaction(propDossierTransaction);
                                          }
                                      } else {
                                          TramFactory.isFinalTransaction(propDossierTransaction);
                                      }
                                  });
                              } else {
                                  var dataModal = {
                                      row: true,
                                      colClass: ' col-sm-12',
                                      labelClass: ''
                                  };
                                  var modal = angular.copy(globalModals.sendProposalDossierTransactionPopup);
                                  modal.alerts = [];
                                  modal.annexaFormly = new AnnexaFormly();
                                  var fields = [];
                                  fields.push(modal.annexaFormly.createField('profile', 'annexaLoadUserAndComboButtonRow', 'col-sm-12',
                                      new AnnexaFormlyLoadUserFieldTemplateOptions('text', 'global.literals.profile_handler_finally', false, false, [], TramFactory.searchProfiles, function () {},
                                          'Profile', 'global.literals.select', {dossier: TramFactory.Dossier}, undefined, 'global.reg.literals.profileOrUserName', false, false, true)
                                      , dataModal)
                                  );
                                  modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
                                  modal.annexaFormly.model = {};
                                  modal.annexaFormly.model.modal_body = {transactionNoGuiadaList: []};
                                  modal.annexaFormly.options = {};
                                  modal.annexaFormly.options.formState = {readOnly: false};
                                  modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.transaction = propDossierTransaction;
                                  modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.sendProposal = true;
                                  modal.extra = {transaction: propDossierTransaction, proposal: prop};
                                  AnnexaFormlyFactory.showModalPromise("sendProposalDossierTransaction", modal, $scope.sendNoGuidedProposalDossierTransaction, false, false, 'global.literals.create').then(function (modalAux) {
                                      modal.modal = modalAux;
                                      modal.close = modalAux.element.scope().close;
                                      modalAux.element.modal();
                                      angular.element(".modal-title").focus();
                                  }).catch(function () {
                                      var a = 0;
                                  });
                              }
                          }
                      } else {
                    	  DialogsFactory.error(getDetailedRequiredFields(requiredFields), $filter('translate')('global.tram.literals.sendProposal'));
                      }
                  }).catch(function () {
                      //Empty
                  });
              };
              
              var getDetailedRequiredFields = function (requiredFields) {
                  var template = $filter('translate')('global.tram.errors.requiredSendProposalFields');

                  template += '<ul>';

                  _.forEach(requiredFields, function (requiredField) {
                      switch (requiredField) {
                          case 'proposalType':
                              template += '<li>' + $filter('translate')('global.sec.literals.proposalType') + '</li>';
                              break;
                          case 'extract':
                              template += '<li>' + $filter('translate')('global.literals.extract') + '</li>';
                              break;
                          case 'notificatorProfile':
                              template += '<li>' + $filter('translate')('global.sec.literals.notificator_profile') + '</li>';
                              break;
                          case 'notificatorResponsible':
                              template += '<li>' + $filter('translate')('global.sec.literals.notificator_responsible') + '</li>';
                              break;
                          case 'anonymousThirds':
                              template += '<li>' + $filter('translate')('global.sec.literals.anonymReceiver') + '</li>';
                              break;
                          case 'organs':
                              template += '<li>' + $filter('translate')('global.sec.literals.agreementOrgan') + '</li>';
                              break;
                          case 'documents':
                              template += '<li>' + $filter('translate')('global.sec.literals.proposalDocs') + '</li>';
                              break;
                          case 'amendmentTransactionType':
                              template += '<li>' + $filter('translate')('global.sec.literals.amendmentProposal') + '</li>';
                              break;
                          case 'transferTransactionType':
                              template += '<li>' + $filter('translate')('global.sec.literals.transferProposal') + '</li>';
                              break;
                          case 'cancelTransactionType':
                              template += '<li>' + $filter('translate')('global.sec.literals.cancelProposal') + '</li>';
                              break;
                      }
                  });

                  template += '</ul>';

                  return template;
              }

              $scope.sendNoGuidedProposalDossierTransaction = function (modal) {
                  var self = this;

                  TramFactory.sendProposalDossierTransaction(self).then(function (data) {
                      var actualTransactionsWithPermission = [];
                      if (TramFactory.Dossier && TramFactory.Dossier.dossierTransactions) {
                          _.forEach(TramFactory.Dossier.dossierTransactions, function (value) {
                              if (value.actual && $scope.transaction.id != value.id) {
                                  _.forEach(value.processProfiles, function (value2) {
                                      if (actualTransactionsWithPermission.length == 0 && AnnexaPermissionsFactory.haveProfile(value2.profile)) {
                                          actualTransactionsWithPermission.push(value);
                                      }
                                  })
                              }
                          });
                      }
                      if (actualTransactionsWithPermission && actualTransactionsWithPermission.length > 0) {
                    	  $scope.transaction.actual = false;
                    	  if (actualTransactionsWithPermission[0].id && $stateParams.dossierTransaction != actualTransactionsWithPermission[0].id) {
                        	  $state.transitionTo('annexa.tram.pending.viewdossier', {
                                  "dossier": TramFactory.Dossier.id,
                                  "dossierTransaction": actualTransactionsWithPermission[0].id
                              });                    		  
	                      } else {
	                          $state.reload();
	                      }
                      } else {
                          $state.transitionTo('annexa.tram.pending');
                      }
                      modal.close();
                  }).catch(function (error) {
                      modal.alerts.push({msg: ErrorFactory.getErrorMessage('proposal', 'send', error.data)});
                  });
              };
              
              $scope.sendGuidedProposalDossierTransaction = function (modal) {
                  var self = this;

                  $scope.changeFreeNodes(this.nextDossierTransactionInfo);

                  TramFactory.sendGuidedProposalDossierTransaction(this.transaction, modal.extra.proposal, this.nextDossierTransactionInfo).then(function (data) {
                      var actualTransactionsWithPermission = [];
                      if (TramFactory.Dossier && TramFactory.Dossier.dossierTransactions) {
                          _.forEach(TramFactory.Dossier.dossierTransactions, function (value) {
                              if (value.actual && $scope.transaction.id != value.id) {
                                  _.forEach(value.processProfiles, function (value2) {
                                      if (actualTransactionsWithPermission.length == 0 && AnnexaPermissionsFactory.haveProfile(value2.profile)) {
                                          actualTransactionsWithPermission.push(value);
                                      }
                                  })
                              }
                          });
                      }
                      if (actualTransactionsWithPermission && actualTransactionsWithPermission.length > 0) {
                    	  $scope.transaction.actual = false;
                    	  if (actualTransactionsWithPermission[0].id && $stateParams.dossierTransaction != actualTransactionsWithPermission[0].id) {
                        	  $state.transitionTo('annexa.tram.pending.viewdossier', {
                                  "dossier": TramFactory.Dossier.id,
                                  "dossierTransaction": actualTransactionsWithPermission[0].id
                              });                    		  
	                      } else {
	                          $state.reload();
	                      }
                      } else {
                          $state.transitionTo('annexa.tram.pending');
                      }
                      modal.close();
                  }).catch(function (error) {
                      modal.alerts.push({msg: ErrorFactory.getErrorMessage('proposal', 'send', error.data)});
                  });
              };
              
              $scope.isProposalTransaction = function () {
                  if ($scope.transaction && $scope.transaction.transactionType 
                  		&& $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[1].id) {
                      return true;
                  } else {
                  	return false;
                  }
              };

              $scope.isAgreementTransaction = function () {
                  if ($scope.transaction && $scope.transaction.transactionType 
                  		&& $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[2].id) {
                      return true;
                  } else {
                  	return false;
                  }
              };

              $scope.isTransferTransaction = function () {
                  if ($scope.transaction && $scope.transaction.transactionType 
                  		&& $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[3].id) {
                      return true;
                  } else {
                  	return false;
                  }
              };

              $scope.isAmendmentTransaction = function () {
                  if ($scope.transaction && $scope.transaction.transactionType 
                  		&& $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[4].id) {
                      return true;
                  } else {
                  	return false;
                  }
              };

              if (TramFactory.proposalsDossierTransaction && TramFactory.proposalsDossierTransaction.length > 0) {
              	var proposals = $linq(TramFactory.proposalsDossierTransaction).where("x => x.dossierTransaction.id == " + $scope.transaction.id).select("x => x.proposal").toArray();
              	if (proposals && proposals.length > 0) {
              		$scope.proposal = proposals[0];
              	}
              }
              
              $scope.updateDossierTransactionLanguage = function(property, val){
                  TramFactory.updateDossierTransaction($scope.transaction)
                      .then(function(returnData) {
                          var returnDataDecoded = JSOG.decode(returnData);
                      }).catch(function (error) {
                  });
              }      
              
              $scope.setDossierTransactionResponsibleUser = function(){
            	  $scope.transaction.responsibleUser = $scope.transaction.responsibleUser ? undefined : $rootScope.LoggedUser;
            	  
            	  TramFactory.updateDossierTransaction($scope.transaction)
	                  .then(function(returnData) {
	                      var returnDataDecoded = JSOG.decode(returnData);
	                      $scope.transaction.responsibleUser = returnDataDecoded.responsibleUser;
	                  }).catch(function (error) { 
	              });
              }
              
              $scope.updateDossierTransactionResponsibleUser = function(property, val){
            	  if(property && val && !isNaN(val)) {
            		  $scope.transaction[property] = {id: val};
            		  
                      TramFactory.updateDossierTransaction($scope.transaction)
	                      .then(function(returnData) {
	                          var returnDataDecoded = JSOG.decode(returnData);
	                          $scope.transaction.responsibleUser = returnDataDecoded.responsibleUser;
	                          $scope.loadComboTransactionResponsibleUser();
	                      }).catch(function (error) { 
	                  });
            	  }            	  
              }
              
              $scope.printTransactionResponsibleUser = function(){            	  
                  if($scope.transaction && $scope.transaction.responsibleUser){
                      var responsibleUser = "";
                      if ($scope.transaction.responsibleUser.name) {
                    	  responsibleUser = responsibleUser + $scope.transaction.responsibleUser.name + " ";
                      }
                      if ($scope.transaction.responsibleUser.surename1) {
                    	  responsibleUser = responsibleUser + $scope.transaction.responsibleUser.surename1 + " ";
                      }
                      if ($scope.transaction.responsibleUser.surename2) {
                    	  responsibleUser = responsibleUser + $scope.transaction.responsibleUser.surename2;
                      }
                      
                      return responsibleUser;
                  }else{
                      return "";
                  }
              }
              
            }]
        }
    }])
    .directive('annexaDossierTransactionInformation', ['TramFactory', 'AnnexaPermissionsFactory', function (TramFactory, AnnexaPermissionsFactory) {
        return {
            restrinct: 'E',
            replace: true,
            scope: {
                transaction: '=',
                order: '@',
                languageColumn: '=',
                isUpdateDossier: '=',
                dossierResponsibleUser: '=',
                loggedUser: '='
            },
            link: function(scope, element, attr) {
            	scope.canEditResponsibleProfile = function() {
                	var res = AnnexaPermissionsFactory.havePermission('change_responsible_profile_dossier_transaction');
                	return res;
                };
            },
            templateUrl: './views/layout/viewdossier/general_left/information.html',
            controller: ['$scope', function ($scope) {
                $scope.isProfileOpen = false;
                $scope.updateTransaction = function (property, data) {
                    $scope.transaction[property] = data;
                    TramFactory.updateDossierTransaction($scope.transaction)
                        .then(function (data) {
                            var a = 0;
                        }).catch(function (error) {
                    });
                }
                $scope.informationLanguageColumn = "";
                if ($scope.languageColumn) {
                    $scope.informationLanguageColumn = "informationLanguage" + $scope.languageColumn.substr($scope.languageColumn.length - 1);
                }

                $scope.loadProcProfiles = function() {
	                $scope.procProfiles = [];
	
	                angular.forEach($scope.transaction.processProfiles, function (profile) {
	                    var users = $linq(profile.profile.userProfiles).selectMany('x => x.user', '(p, obj) => p').toArray();
	                    users = $linq(users).where("x => !x.expiryDate && !x.deleted").distinctBy("x => x.id").toArray();
	
	                    $scope.procProfiles.push({profile: profile, users: users});
	                });
                }
                $scope.loadProcProfiles();
                
                $scope.allProfiles = TramFactory.allProfiles;
                
                $scope.updateResponsibleProfile = function (property, data) {  
                	TramFactory.updateDossierTransactionProcessProfile($scope.transaction.processProfiles[0].id, data.id)
	                    .then(function (data) {
	                    	$scope.transaction.processProfiles[0] = JSOG.decode(data);	                    	
	                    	$scope.loadProcProfiles();	                    	
	                    }).catch(function (error) {
	                });
                }
                
                $scope.updateOpenFrofileStatus = function () {
                    $scope.isProfileOpen = $scope.procProfiles.some(function (item) {
                        return item.isProfileOpen;
                    })
                }
            }]
        }
    }])
    .directive('annexaDossierTransactionCustomFields', ['TramFactory', '$filter', function (TramFactory, $filter) {
        return {
            restrinct: 'E',
            replace: true,
            scope: {
                transaction: '=',
                order: '@',
                languageColumn: '=',
                isUpdateDossier: '='
            },
            templateUrl: './views/layout/viewdossier/general_left/customFields.html',
            controller: ['$scope', function ($scope) {
                angular.forEach($scope.transaction.customFields, function (value, key) {
                    if (value.value) {
                        var object = angular.fromJson(value.value);
                        value.valueFromJSON = object.value;
                        if (value.customField.backendType == "DATETIME") {
                            value.valueFromJSON = new Date(value.valueFromJSON);
                        }
                    } else {
                        if (value.customField.frontendType == 'MULTIPLESELECT' || value.customField.frontendType == 'CHECKBOX') {
                            value.valueFromJSON = [];
                        } else {
                            value.valueFromJSON = '';
                        }
                    }
                    if (value && value.customField && value.customField.backendType == "DECIMAL") {
                        if (value.customField.decimalPositions) {
                            value.step = '0.' + Array(value.customField.decimalPositions).join('0') + '1';
                        } else {
                            value.step = '0.01'
                        }
                    }
                });

                $scope.getFieldValue = function (field) {
                    var valueLiteral = '';
                    if (field.customField.frontendType == 'MULTIPLESELECT' || field.customField.frontendType == 'CHECKBOX') {
                        angular.forEach(field.valueFromJSON, function (valueJ, keyJ) {
                            angular.forEach(field.customField.listValues, function (value, key) {
                                if (value.value == valueJ) {
                                    if (valueLiteral) {
                                        valueLiteral = valueLiteral + ', ' + value[$scope.languageColumn];
                                    } else {
                                        valueLiteral = value[$scope.languageColumn];
                                    }
                                }
                            });
                        });
                    } else if (field.customField.frontendType == 'SELECT' || field.customField.frontendType == 'SELECT_LINKED' || field.customField.frontendType == 'RADIO') {
                        angular.forEach(field.customField.listValues, function (value, key) {
                            if (value.value == field.valueFromJSON) {
                                valueLiteral = value[$scope.languageColumn];
                            }
                        });
                    } else if (field.customField.frontendType == 'INPUT') {
                        if (field.customField.backendType == "DATETIME") {
                            valueLiteral = $filter('date')(field.valueFromJSON, 'dd/MM/yyyy');
                        } else if (field.customField.backendType == "DECIMAL") {
                            if (field.valueFromJSON) {
                                valueLiteral = field.valueFromJSON;
                                var valueLiteral_parts = ("" + valueLiteral).split('.');
                                if (valueLiteral_parts && valueLiteral_parts.length > 0) {
                                    if (valueLiteral_parts[1]) {
                                        if (field.customField && field.customField.decimalPositions) {
                                            valueLiteral = parseFloat(valueLiteral_parts[0]).toLocaleString() + parseFloat("0." + valueLiteral_parts[1]).toFixed(field.customField.decimalPositions).replace('0.', ',');
                                        } else {
                                            valueLiteral = parseFloat(valueLiteral_parts[0]).toLocaleString() + parseFloat("0." + valueLiteral_parts[1]).toFixed(2).replace('0.', ',');
                                        }
                                    } else {
                                        if (field.customField && field.customField.decimalPositions) {
                                            valueLiteral = parseFloat(valueLiteral_parts[0]).toLocaleString() + parseFloat("0.00").toFixed(field.customField.decimalPositions).replace('0.', ',');
                                        } else {
                                            valueLiteral = parseFloat(valueLiteral_parts[0]).toLocaleString() + ",00";
                                        }
                                    }
                                }
                            } else {
                                valueLiteral = field.valueFromJSON;
                            }
                        } else if (field.customField.backendType == "INTEGER") {
                            if (field.valueFromJSON) {
                                valueLiteral = field.valueFromJSON.toLocaleString();
                            } else {
                                valueLiteral = field.valueFromJSON;
                            }
                        } else {
                            valueLiteral = field.valueFromJSON;
                        }
                    }
                    return valueLiteral;
                }

                $scope.updateField = function (field, data) {
                    if (!field.required || (field.required && data)) {
                        var fieldValue = angular.fromJson(field.value);
                        if (fieldValue == null || fieldValue == undefined) {
                            fieldValue = {id: 'cf_' + field.customField.id, value: ''};
                        }
                        if (data && data instanceof Date) {
                            fieldValue.value = new Date(Date.UTC(data.getFullYear(), data.getMonth(), data.getDate(), 00, 00, 00));
                        } else {
                            fieldValue.value = data;
                        }
                        field.value = angular.toJson(fieldValue);
                        if (!field.dossierTransaction) {
                            field.dossierTransaction = {id: $scope.transaction.id};
                        }
                        TramFactory.updateDossierTransactionCustomField(field)
                            .then(function (data) {
                                if (field.customField.frontendType == 'SELECT') {
                                    $scope.$broadcast('customFieldSelectSelected', {
                                        customField: field.customField.id,
                                        selectedValue: field.id
                                    });
                                }
                                if (TramFactory.Dossier && TramFactory.Dossier.dossierTransactions) {
                                    angular.forEach(TramFactory.Dossier.dossierTransactions, function (valueDT) {
                                        angular.forEach(valueDT.customFields, function (valueCF) {
                                            if (valueCF.customField && valueCF.customField.id && field.customField && field.customField.id && valueCF.customField.id == field.customField.id) {
                                                valueCF.value = field.value;
                                                if (valueCF.value) {
                                                    var object = angular.fromJson(valueCF.value);
                                                    valueCF.valueFromJSON = object.value;
                                                    if (valueCF.customField.backendType == "DATETIME") {
                                                        valueCF.valueFromJSON = new Date(valueCF.valueFromJSON);
                                                    }
                                                } else {
                                                    if (valueCF.customField.frontendType == 'MULTIPLESELECT' || valueCF.customField.frontendType == 'CHECKBOX') {
                                                        valueCF.valueFromJSON = [];
                                                    } else {
                                                        valueCF.valueFromJSON = '';
                                                    }
                                                }
                                                if (valueCF && valueCF.customField && valueCF.customField.backendType == "DECIMAL") {
                                                    if (valueCF.customField.decimalPositions) {
                                                        valueCF.step = '0.' + Array(valueCF.customField.decimalPositions).join('0') + '1';
                                                    } else {
                                                        valueCF.step = '0.01'
                                                    }
                                                }
                                            }
                                        })
                                    });
                                }
                            }).catch(function (error) {
                        });
                    }
                }

                $scope.getOptions = function (field) {
                    if (field.customField.frontendType == 'SELECT') {
                        return field.customField.listValues;
                    } else if (field.customField.frontendType == 'SELECT_LINKED') {
                        var options = [];

                        var parentField = $linq($scope.transaction.customFields).singleOrDefault(undefined, "x => x.customField.id == " + field.customField.linkedCustomField.id);

                        if (parentField) {
                            var parentValue = $linq(parentField.customField.listValues).singleOrDefault(undefined, "x => x.value == " + parentField.valueFromJSON);

                            if (parentValue) {
                                options = $linq(field.customField.listValues).where("x => x.parentValue.id == " + parentValue.id).toArray();
                            }
                        }

                        return options;
                    }
                }

                $scope.$on('customFieldSelectSelected', function (event, args) {
                    if (args.customField && args.selectedValue) {
                        var linkedFields = $linq($scope.transaction.customFields).where("x => x.customField.linkedCustomField && x.customField.linkedCustomField.id == " + args.customField).toArray();

                        _.forEach(linkedFields, function (field) {
                            $scope.updateField(field, '');
                            field.valueFromJSON = '';
                        });
                    }
                });
            }]
        }
    }])
    .directive('annexaDossierTransactionDocs', ['BoxFactory', 'TramFactory', '$filter', 'Language', 'AnnexaEntityFactory', 'RegFactory', 'HelperService', 'dialogs', 'ErrorFactory', 'NotificationFactory',
        function (BoxFactory, TramFactory, $filter, Language, AnnexaEntityFactory, RegFactory, HelperService, dialogs, ErrorFactory, NotificationFactory) {
            return {
                restrinct: 'E',
                replace: true,
                scope: {
                    transaction: '=',
                    order: '@',
                    languageColumn: '=',
                    viewData: '=',
                    isUpdateDossier: '=',
                    registerOutputBox: '=',
                    notificationBox: '='
                },
                templateUrl: './views/layout/viewdossier/general_left/docs.html',
                controller: ['$scope', 'HelperService', '$rootScope', 'GlobalDataFactory', function ($scope, HelperService, $rootScope, GlobalDataFactory) {
                    $scope.statuses = GlobalDataFactory.documentStatuses;

                    if ($scope.transaction.actual && $scope.isUpdateDossier) {
                        $scope.documentBox = angular.copy(BoxFactory.DocumentChildBox).initialize('global.literals.documents', 'global.literals.placeholser_search_documents', 'global.literals.advanced_search_documents', $scope.isUpdateDossier, $scope.isUpdateDossier, $scope.isUpdateDossier, $scope.isUpdateDossier);
                        if (TramFactory.Dossier && TramFactory.Dossier.archiveClassification && TramFactory.Dossier.archiveClassification.model && TramFactory.Dossier.archiveClassification.model.$selected) {
                            $scope.documentBox.boxDefinition.archiveClassification = TramFactory.Dossier.archiveClassification;
                        } else if (TramFactory.Dossier && TramFactory.Dossier && TramFactory.Dossier.procedure && TramFactory.Dossier.procedure.archiveClassification) {
                            var arcClas = TramFactory.Dossier.procedure.archiveClassification;
                            $scope.documentBox.boxDefinition.archiveClassification = {
                                model: {
                                    $selected: {
                                        id: arcClas.id,
                                        title: arcClas[$scope.languageColumn]
                                    }
                                }
                            };
                        }
                    } else {
                        $scope.documentBox = angular.copy(BoxFactory.DocumentChildBox).initialize('global.literals.documents', 'global.literals.placeholser_search_documents', 'global.literals.advanced_search_documents');
                    }
                    $scope.documentBox.boxDefinition.transaction = $scope.transaction;
                    $scope.documentBox.boxDefinition.module = "EXPED";
                    $scope.documentBox.boxDefinition.pagination = true;
                    $scope.documentBox.boxDefinition.itemsPage = 15;
                    $scope.documentBox.boxDefinition.paginationAnchor = "exp-docs-" + $scope.transaction.id;
                    $scope.documentBox.boxDefinition.page = 0;

                    //region Nous

                    var updateDocumentInList = function (document) {
                        if(document && document.id) {
                            var index = $linq($scope.documentBox.boxDefinition.content).indexOf("x => x.document.id == " + document.id);

                            if (index != -1) {
                                $scope.documentBox.boxDefinition.content[index].document.urgent = document.urgent;
                                $scope.documentBox.boxDefinition.content[index].document.docStatus = document.docStatus;
                                $scope.documentBox.boxDefinition.content[index].document.idPDFDocumentManager = document.idPDFDocumentManager;
                                $scope.documentBox.boxDefinition.content[index].document.filePDFTypeDoc = document.filePDFTypeDoc;
                                $scope.documentBox.boxDefinition.content[index].document.sizePDFDoc = document.sizePDFDoc;
                            }
                        }
                    }

                    $scope.$on('SendToSignModalFinished', function (event, args) {
                        if (args.data && args.data.length > 0) {
                            updateDocumentInList(args.data[0]);
                        }
                    });

                    $scope.$on('documentActionButtonExecuted', function (event, args) {
                        if (args.button) {
                            switch (args.button) {
                                case 'deleteDossierTransactionDocument':
                                    var indexDeleted = $linq($scope.documentBox.boxDefinition.content).indexOf("x => x.document.id == " + args.data.documentId);
                                    if (indexDeleted != -1) {
                                        $scope.documentBox.boxDefinition.content.splice(indexDeleted, 1);
                                        $rootScope.$broadcast('proposalDocumentsList', {docs: $scope.documentBox.boxDefinition.content});
                                        $rootScope.$broadcast('addRemovedObjectEvent', {addRemoveObject: true});
                                    }
                                    break;
                                case 'returnToDraft':
                                case 'complete':
                                    updateDocumentInList(args.data);
                                    break;
                            }
                        }
                    });
                    
                    $scope.$on('created_multiple_notifications', function (event, args) {
              		   $rootScope.$broadcast('created_multiple_notifications_from_document', args);
                    });

                    $scope.$on('createdRegisterEntry', function (event, args) {
               		   $rootScope.$broadcast('createdRegisterEntry_from_document', args);
                    });
                    
                    //endregion

                    $scope.documentBox.boxDefinition.content = [];
                    if ($scope.transaction.documents) {
                        if ($scope.transaction && $scope.transaction.documents) {
                            var documentTrans = $linq($scope.transaction.documents).orderBy("x => x.createdDate").toArray();
                            $scope.transaction.documents.length = 0;
                            _.forEach(documentTrans, function (value) {
                                $scope.transaction.documents.push(value);
                            });

                        }
                        $scope.documentBox.boxDefinition.content = $scope.transaction.documents;
                    }
                    $scope.documentBox.boxDefinition.saveSearch = function (document) {
                        TramFactory.updateDossierTransaction($scope.transaction).then(function (data) {
                            var docsTr = JSOG.decode(data).documents;
                            if (docsTr) {
                                $scope.transaction.documents.length = 0;
                                angular.forEach(docsTr, function (value, key) {
                                    $scope.transaction.documents.push(value);
                                });
                                $rootScope.$broadcast('proposalDocumentsList', {docs: $scope.transaction.documents});
                                $rootScope.$broadcast('addRemovedObjectEvent', {addRemoveObject: true});
                                $rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: $scope.transaction.documents, relationType: 'DOCUMENT', transactionId: (($scope.transaction)?$scope.transaction.id:undefined) });
                            }
                        }).catch(function (error) {
                        });
                    };
                    if (!$scope.isUpdateDossier) {
                        $scope.documentBox.boxDefinition.showTemplates = false;
                    } else if (!$scope.transaction.actual) {
                        $scope.documentBox.boxDefinition.showTemplates = false;
                    } else {
                        $scope.documentBox.boxDefinition.showTemplates = true;
                    }
                    $scope.documentBox.boxDefinition.placeholderTemplate = $filter('translate')('global.tram.literals.placeholderTemplate');
                    $scope.documentBox.boxDefinition.fromTemplate = function () {
                        AnnexaEntityFactory.showNewDocumentFromTemplateModal($scope.transaction, new SortedArray($scope.viewData.tramTemplates, Language.getActiveColumn()).sort(), new SortedArray($scope.viewData.documentTypes, Language.getActiveColumn()).sort(), $scope.documentBox.boxDefinition);
                    };
                    $scope.documentBox.boxDefinition.actualTransaction = $scope.transaction.actual;
                }]
            }
        }])
    .directive('annexaDossierTransactionTasks', ['$rootScope', 'BoxFactory', 'TramFactory', 'TaskFactory', 'RestService', 'AnnexaEntityFactory', function ($rootScope, BoxFactory, TramFactory, TaskFactory, RestService, AnnexaEntityFactory) {
        return {
            restrinct: 'E',
            replace: true,
            scope: {
                transaction: '=',
                order: '@',
                languageColumn: '=',
                isUpdateDossier: '='
            },
            templateUrl: './views/layout/viewdossier/general_left/tasks.html',
            controller: ['$scope', function ($scope) {
                if ($scope.transaction.actual) {
                    $scope.tasksBox = angular.copy(BoxFactory.TaskBox).initialize('global.literals.tasks', '', '', $scope.isUpdateDossier, $scope.isUpdateDossier, AnnexaEntityFactory, {
                        assignedCertificated: true,
                        finished: false
                    });
                    $scope.tasksBox.boxDefinition.deleteFunction = function (objectId, index) {
                        TramFactory.deleteTask($scope.transaction.id, objectId).then(function (data) {
                            $scope.tasksBox.boxDefinition.content.splice(index, 1);
                        }).catch(function (error) {
                        });
                    };
                } else {
                    $scope.tasksBox = angular.copy(BoxFactory.TaskBox).initialize('global.literals.tasks', '', '', false, false, undefined, {
                        assignedCertificated: true,
                        finished: false
                    });
                }
                $scope.tasksBox.boxDefinition.isTramBox = $scope.transaction;
                $scope.tasksBox.boxDefinition.templateTasks = TaskFactory.templateTypes;
                $scope.tasksBox.boxDefinition.content = [];
                if ($scope.transaction.tasks) {
                    $scope.tasksBox.boxDefinition.content = $scope.transaction.tasks;
                }
                $scope.tasksBox.boxDefinition.saveSearch = function (task) {
                    if (task) {
                        TramFactory.updateDossierTransaction($scope.transaction).then(function (data) {
                        }).catch(function (error) {
                            box.boxDefinition.content.pop();
                        });
                    }
                };
                $scope.tasksBox.boxDefinition.searchTasks = function (value) {
                    if (!value || !value.val) {
                        return [];
                    } else if (value.val != '*' && value.val.length < 3) {
                        return [];
                    } else {
                        if (value.val == '*') {
                            value.val = '';
                        }
                        return RestService.loadData('common', 'User', value.val).then(function (dataSearch) {
                            var usersLoad = [];
                            if (dataSearch.data && dataSearch.data.content && dataSearch.data.content.length > 0) {
                                angular.forEach(JSOG.decode(dataSearch.data.content), function (value, key) {
                                    var name = value.name + " " + value.surename1;
                                    if (value.surename2) {
                                        name = name + " " + value.surename2;
                                    }
                                    usersLoad.push({'id': value.id, 'value': name});
                                });
                            }
                            return usersLoad;
                        }).catch(function () {
                            return [];
                        })
                    }
                };
            }]
        }
    }])
    .directive('annexaDossierTransactionProposal', ['TramFactory', 'RestService', 'GlobalDataFactory', 'SecFactory', 'DialogsFactory', 'ErrorFactory', 'apiAdmin', 'AnnexaFormlyFactory',
    		function (TramFactory, RestService, GlobalDataFactory, SecFactory, DialogsFactory, ErrorFactory, apiAdmin, AnnexaFormlyFactory) {
        return {
            restrinct: 'E',
            replace: true,
            scope: {
                transaction: '=',
                proposal: '=?',
                order: '@',
                languageColumn: '=',
                isUpdateDossier: '='
            },
            templateUrl: './views/layout/viewdossier/general_left/proposal.html',
            controller: ['$scope', '$filter', '$rootScope', function ($scope, $filter, $rootScope) {

            	//region basic info
            	$scope.proposalTypes = TramFactory.proposalTypes;
            	
            	$scope.proposalTypeSelected = function() {
        			if($scope.proposal && $scope.proposal.proposalType) {
        				return $scope.proposal.proposalType[$scope.languageColumn];
                    } else {
            			return '';
                    }
                }
            	
            	$scope.notificatorProfiles = [];
            	var notificatorProfilesTransaction = [];
            	if($scope.transaction && $scope.transaction.processProfiles){
            		notificatorProfilesTransaction = $linq($scope.transaction.processProfiles).select("x => x.profile").toArray();
                }
            	
            	var secretary = $linq(GlobalDataFactory.allProfiles).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.secretary_properties.secretary_profile);
            	
            	if(notificatorProfilesTransaction) {
            		_.forEach(notificatorProfilesTransaction, function(notificatorProfileTransaction){
            			if(notificatorProfileTransaction && secretary 
            					&& notificatorProfileTransaction.id != secretary.id){
            				$scope.notificatorProfiles.push(notificatorProfileTransaction);
                        }
                    });
            		$scope.notificatorProfiles.push(secretary);
            	}
            	
            	$scope.notificatorProfileSelected = function() {
        			if($scope.proposal && $scope.proposal.notificatorProfile) {
        				return $scope.proposal.notificatorProfile[$scope.languageColumn];
                    } else {
                    	return '';
                    }
                }

            	$scope.notificatorResponsibles = [];

            	$scope.$on('getNotificatorResponsibles', function (event, args) {
            		$scope.notificatorResponsibles = [];
                    if (args.notificatorProfile && args.notificatorProfile.userProfiles) {
                        angular.forEach(args.notificatorProfile.userProfiles, function (userProfile) {
                        	if (userProfile && userProfile.user && userProfile.user.name) {
                        		userProfile.user.completeName = userProfile.user.name + ' ' + userProfile.user.surename1 + ((userProfile.user.surename2) ? ' ' + userProfile.user.surename2 + '\n' : '\n');
                        		$scope.notificatorResponsibles.push(userProfile.user);
                        	}
                        });
                    }	
                });
                
            	if ($rootScope.esetMode && $scope.proposal && $scope.proposal.notificatorProfile) $scope.$broadcast('getNotificatorResponsibles', {notificatorProfile: $scope.proposal.notificatorProfile});

            	$scope.notificatorResponsibleSelected = function() {
        			if($scope.proposal && $scope.proposal.notificatorResponsible && $scope.proposal.notificatorResponsible.completeName) {
        				return $scope.proposal.notificatorResponsible.completeName;
                    } else {
            			return '';
                    }
                }

            	$scope.popupNotificatorResponsibles = function () {
                    var dataModal = {
                        row: true,
                        colClass: 'col-sm-12',
                        labelClass: ''
                    };
                    var modal = {
                        title: 'global.tram.literals.selectResponsible',
                        size: 'modal-sm',
                        icon: '',
                        submitModal: function () {
                        },
                        alerts: []		
                    }
                    modal.annexaFormly = new AnnexaFormly();
                    
                    var saveProfilesAndResponsibles = function(){
                    	if (modal.annexaFormly.model.modal_body.profile && modal.annexaFormly.model.modal_body.selectedResponsible) {
                    		$scope.proposal.notificatorProfile = modal.annexaFormly.model.modal_body.profile;
                    		$scope.proposal.notificatorResponsible = modal.annexaFormly.model.modal_body.selectedResponsible;
                    		
                            RestService.update('./api/sec/proposal/' + $scope.proposal.id, $scope.proposal).then(function (data) {
                            	$scope.proposal.proposalNumber = data.proposalNumber;
                            	if ($scope.proposal.notificatorResponsible) $scope.proposal.notificatorResponsible.completeName = $scope.proposal.notificatorResponsible.name + ' ' + $scope.proposal.notificatorResponsible.surename1 + (($scope.proposal.notificatorResponsible.surename2) ? ' ' + $scope.proposal.notificatorResponsible.surename2 + '\n' : '\n');
                            	$scope.$broadcast('getNotificatorResponsibles', {notificatorProfile: $scope.proposal.notificatorProfile});
                            	modal.close();
                            }).catch(function (error) {
                                console.error(error);
                            });
                    	} else {
                    		modal.alerts.push({msg: 'global.tram.errors.noProfileNoResponsibleSelected'});
                    	} 
                    }
                    
                    var fields = [];
                    fields.push(modal.annexaFormly.createField('profile','annexaSelectResponsibleRow', '', new AnnexaFormlyFieldSelectTemplateOptions('global.tram.literals.selectProfileAndResponsible','id', $scope.languageColumn, $scope.notificatorProfiles, true), dataModal));
                    fields[0].templateOptions.profileId = $scope.proposal.notificatorProfile ? $scope.proposal.notificatorProfile.id : undefined;
                    fields[0].templateOptions.responsibleId = $scope.proposal.notificatorResponsible ? $scope.proposal.notificatorResponsible.id : undefined;
                    modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
                    modal.annexaFormly.options = {};
                    modal.annexaFormly.options.formState = {readOnly: false};
                    AnnexaFormlyFactory.showModalPromise("updateNotificatorResponsible", modal, saveProfilesAndResponsibles, false, false, 'global.literals.select').then(function (modalAux) {
                        modal.modal = modalAux;
                        modal.close = modalAux.element.scope().close;
                        modalAux.element.modal();
                        angular.element(".modal-title").focus();
                    }).catch(function () {
                        var a = 0;
                    });
                
                }
            	
            	$scope.comboYesNo = [
                    { id: true, description: $filter('translate')('global.literals.yes')},
                    { id: false, description: $filter('translate')('global.literals.no')}
                ];

                $scope.printAnonymizeThirds = function(){
                    if($scope.proposal && $scope.proposal.anonymousThirds){
                        return $filter('translate')('global.literals.yes');
                    } else {
                        return $filter('translate')('global.literals.no');
                    }
                }

                $scope.allFooterClaims = TramFactory.footerClaims;
                $scope.footerClaimsAux = [];
                
            	if($scope.proposal && $scope.proposal.footerClaims){
            		$scope.footerClaimsAux = $linq($scope.proposal.footerClaims).select("x => x.footerClaim").toArray();
            	}

            	$scope.printFooterClaims = function() {
        			var content = '';
        			if($scope.footerClaimsAux && $scope.footerClaimsAux.length > 0) {
                        _.forEach($scope.footerClaimsAux, function(footerClaim){
                        	content += ((content == '')?'':', ') + footerClaim[$scope.languageColumn];
                        });
                    }
        			return content;
                }
            	
                $scope.updateProposal = function(val, prop) {
                	if(prop == 'footerClaims') {
	               		var footerClaims = [];
	            		_.forEach(val, function(fc){
	            			var fcAct = $linq($scope.proposal.footerClaims).where("x => x.footerClaim.id == "+fc.id).toArray();
	            			if(fcAct && fcAct.length > 0){
	            				footerClaims.push(fcAct[0]);
	            			}else{
	            				footerClaims.push({proposal:{id:$scope.proposal.id}, footerClaim:{id:fc.id}});
	            			}
	            		});
	            		$scope.proposal.footerClaims = footerClaims;
                	} else if(prop == 'internalCommunications') {
                		$scope.proposal[prop] = [];
                		if(val) {
                    		_.forEach(val,function(profile){
                    			$scope.proposal[prop].push({profile:{id:profile.id}});
                    		});                			
                		}
                	} else if(prop == 'documents') {
                		if(val) {
                    		$scope.proposal[prop] = [{document:{id:val.id}}];
                		} else {
                			$scope.proposal[prop] = [];
                		}
                	} else if(prop == 'notificatorProfile' || prop == 'notificatorResponsible') {
                		$scope.proposal[prop] = ((val && val.id)?{id:val.id}:undefined);
                	} else if(prop == 'amendmentTransactionType' || prop == 'transferTransactionType' || prop == 'cancelTransactionType') {
                		$scope.proposal[prop] = ((val && val.id)?{id:val.id}:((val)?{id:val}:undefined));
                	} else {
                    	$scope.proposal[prop] = val;
                	}

                    RestService.update('./api/sec/proposal/' + $scope.proposal.id, $scope.proposal)
                        .then(function (data) {
                        	$scope.proposal.proposalNumber = data.proposalNumber;
                        	if(prop == 'notificatorProfile') {
                        		$scope.proposal[prop] = val;
                        		if ($rootScope.esetMode) $scope.$broadcast('getNotificatorResponsibles', {notificatorProfile: $scope.proposal[prop]});
                        	} else if(prop == 'notificatorResponsible') {
                        		$scope.proposal[prop] = val;
                        		if ($rootScope.esetMode) $scope.$broadcast('getNotificatorResponsibles', {notificatorProfile: data.notificatorProfile});
                        	} else if (prop == 'amendmentTransactionType' || prop == 'transferTransactionType' || prop == 'cancelTransactionType') {
                        		$scope.proposal[prop] = $linq(TramFactory.transactionsTypes).singleOrDefault(undefined, "x => x.id == " + ((val.id)?val.id:val));
                        	}
                        }).catch(function (error) {
                            console.error(error);
                    });
                }

            	$scope.propDocument = null;
            	$scope.proposalDocuments = [];
            	
            	$scope.$on('proposalDocumentsList', function (event, args) {
            		if($scope.proposalDocuments){
            			$scope.proposalDocuments.length = 0;
            		} else {
            			$scope.proposalDocuments = [];
            		}
                	var documentTypeProp = $rootScope.app.configuration.sec_document_types["SPR"];
                	if(args.docs && documentTypeProp){
                		_.forEach(args.docs, function(documentDT){
                			if(documentDT && documentDT.document && documentDT.document.type && _.contains(documentTypeProp.id, documentDT.document.type.id+"")){
                				$scope.proposalDocuments.push(documentDT.document);
                			}
                		});
                	}
            		if($scope.proposal && $scope.proposal.state && _.contains(['DRAFT'], $scope.proposal.state) 
            				&& args.docs && $scope.proposal.documents && $scope.proposal.documents.length > 0) {
            			var removeDocuments = [];
            			var found = false;
            			_.forEach($scope.proposal.documents, function(proposalDocument){
                			if(proposalDocument && proposalDocument.document) {
                        		_.forEach(args.docs, function(documentDT){
                        			if(documentDT && documentDT.document && proposalDocument.document.id == documentDT.document.id){
                        				found = true;
                        			}
                    			});
                        		
                        		if (!found) {
                        			removeDocuments.push(proposalDocument.document)
                        		}
                			}
                		});
                    	_.forEach(removeDocuments, function(removeDocument){
                    		var index = $linq($scope.proposal.documents).indexOf("x => x.document.id == " + removeDocument.id);
                            if(index != -1) {
                                $scope.proposal.documents.splice(index,1);
                            }
                    	});
                    	
                    	if (removeDocuments && removeDocuments.length > 0) {
                			$scope.updateProposal($scope.proposal.documents.document, 'documents');
                			$scope.propDocument = [];
                    	}
            		}
            		
            	});
                
            	$scope.$broadcast('proposalDocumentsList', {docs: $scope.transaction.documents});

            	if($scope.proposal && $scope.proposal.documents){
            		$scope.propDocument = $linq($scope.proposal.documents).select("x => x.document").toArray();
            	}

            	$scope.proposalDocumentSelected = function() {
        			if($scope.propDocument && $scope.propDocument.length > 0) {
        				return $scope.propDocument[0].name;
                    } else if ($scope.propDocument) {
                    	return $scope.propDocument.name;
                    } else {
                    	return '';
                    }
                }
                //endregion
                
                $scope.transaction_id = (($scope.transaction && $scope.transaction.id) ? $scope.transaction.id : '');
                
                //region Organs Box
                $scope.organsBox = {
                    content: (($scope.proposal && $scope.proposal.organs) ? $scope.proposal.organs : []),
                    permissions: {},
                    new: {},
                    config: {},
                    origin: 'transactionProposal-' + $scope.transaction_id
                }
                
                $scope.isEdit = true;
                $scope.canEdit = (($scope.proposal && $scope.proposal.state && _.contains(['DRAFT'], $scope.proposal.state)) ? true : false);
                        
                $scope.$on('annexaBoxOrgansAddOpinionOrgan', function (event, args) {
                    if(args.origin && args.origin == ('transactionProposal-' + $scope.transaction_id) && $scope.isEdit) {
                    	if(args.organ && args.organ && args.modal){
                    		SecFactory.addProposalOrgan($scope.proposal.id, args.organ).then(function(data) {
    	                        if(data) {
    	                        	 $scope.organsBox.content.push(data);
    	                        }
    	                        args.modal.close();
    	                    }).catch(function(error) {
    	                    	args.modal.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'addOrgan', error)});
    	                    })
    	                }else{
    	            		DialogsFactory.error('global.sec.literals.error_required_files_organ');
    	            	}
                    }
                });
                
        		$scope.$on('annexaBoxOrgansModifyOpinionOrgan', function (event, args) {
                    if(args.origin && args.origin == ('transactionProposal-' + $scope.transaction_id) && $scope.isEdit) {
                    	if(args.organ && args.organ.id && args.organ.organ && args.organ.organ.id){
    	                	SecFactory.updateProposalOrgan($scope.proposal.id, args.organ.id, args.organ.organ.id).then(function(data) {
    	                		if(data) {
    		                		var index = $linq($scope.organsBox.content).indexOf("x => x.id == " + data.id);
    		                        if(index != -1) {
    		                            $scope.organsBox.content[index] = data;
    		                        }
    	                		}
    	                    }).catch(function(error) {
    	                    	$scope.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'modifyOrgan', error)});
    	                    })
                    	}else{
                    		DialogsFactory.error('global.sec.literals.error_required_files_organ');
                    	}
                    }
                });
    			
        		$scope.$on('annexaBoxOrgansModifyAgreementOrgan', function (event, args) {
                    if(args.origin && args.origin == ('transactionProposal-' + $scope.transaction_id) && $scope.isEdit) {
                    	if(args.organ && args.organ.id && args.organ.organ && args.organ.organ.id){
    	                	SecFactory.updateProposalOrgan($scope.proposal.id, args.organ.id, args.organ.organ.id).then(function(data) {
    		                	if(data){
    		                		var index = $linq($scope.organsBox.content).indexOf("x => x.id == " + data.id);
    			                    if(index != -1) {
    			                        $scope.organsBox.content[index] = data;
    			                    }
    		                	}
    	                    }).catch(function(error) {
    	                    	$scope.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'modifyOrgan', error)});
    	                    })
                    	} else if (args.organ && args.organ.organ && args.organ.organ.id){
                    		SecFactory.addProposalOrgan($scope.proposal.id, args.organ.organ.id).then(function(data) {
    	                        if(data) {
    	                        	 $scope.organsBox.content.push(data);
    	                        }
    	                        
    	                    }).catch(function(error) {
    	                    	$scope.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'addOrgan', error)});
    	                    })                    		
                    	}else{
                    		DialogsFactory.error('global.sec.literals.error_required_files_organ');
                    	}
                    }
                });
    			
        		$scope.$on('annexaBoxOrgansDelete', function (event, args) {
                    if(args.origin && args.origin == ('transactionProposal-' + $scope.transaction_id) && $scope.isEdit) {
                    	if(args.removedIds && args.removedIds.length > 0){
    	                	SecFactory.deleteProposalOrgan($scope.proposal.id, args.removedIds).then(function(data) {
    	                        if(data) {
    	                        	_.forEach(args.removedIds, function(id){
    	                        		var index = $linq($scope.organsBox.content).indexOf("x => x.id == " + id);
    	                                if(index != -1) {
    	                                    $scope.organsBox.content.splice(index,1);
    	                                }
    	                        	});
    	                        	
    	                        }
    	                    }).catch(function(error) {
    	                    	$scope.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'deleteOrgan', error)});
    	                    })
                    	}else{
                    		DialogsFactory.error('global.sec.literals.error_required_files_removedIds');
                    	}
                    }
                });
        		
        		 $scope.$on('annexaBoxUpdateSaveProposal', function (event, args) {
    	            if(args.origin && args.origin == ('transactionProposal-' + $scope.transaction_id)) {
    	            	$scope.canEdit = (($scope.proposal && $scope.proposal.state && _.contains(['DRAFT'],$scope.proposal.state))?true:false);
    	            	HeaderService.changeState($state.current, true);
    	            }
    	        });
        		//endregion

                //region internal communication box
                $scope.internalCommunicationBox = {
            		content: $scope.proposal,
                    permissions: {},
                    new: {},
                    config: {},
                    origin: 'transactionProposal-' + $scope.transaction_id
                }

                $scope.$on('annexaBoxInternalCommunicationAdd', function (event, args) {
                	if(args.origin && args.origin == ('transactionProposal-' + $scope.transaction_id)) {
                		$scope.updateProposal(args.internalCommunication, 'internalCommunications');
                	}
                });
                //endregion
                
                //region transactionTypes box
                if (TramFactory.Dossier && TramFactory.Dossier.dossierTramitationType && TramFactory.Dossier.dossierTramitationType.id) {
                    var select = "x => x.tramitationType.id == " + TramFactory.Dossier.dossierTramitationType.id;
                    var selectTransferTransactionTypes = select + " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[3].id;
                    $scope.transferTransactionTypes = $linq(TramFactory.transactionsTypes).where(selectTransferTransactionTypes).toArray();
                    
                    var selectAmendmentTransactionTypes = select + " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[4].id;
                    $scope.amendmentTransactionTypes = $linq(TramFactory.transactionsTypes).where(selectAmendmentTransactionTypes).toArray();
                    
                    var selectCancelTransactionTypes = select + " && (x.transactionSubtype == " + apiAdmin.transactionSubtypes[0].id + " || x.transactionSubtype == " + apiAdmin.transactionSubtypes[1].id + ")";
                    $scope.cancelTransactionTypes = $linq(TramFactory.transactionsTypes).where(selectCancelTransactionTypes).toArray();
                }
                
            	$scope.amendmentProposalSelected = function() {
                    if ($scope.proposal && $scope.proposal.amendmentTransactionType) {
                    	return $scope.proposal.amendmentTransactionType[$scope.languageColumn];
                    } else {
                    	return '';
                    }
                }
            	$scope.transferProposalSelected = function() {
                    if ($scope.proposal && $scope.proposal.transferTransactionType) {
                    	return $scope.proposal.transferTransactionType[$scope.languageColumn];
                    } else {
                    	return '';
                    }
                }
            	$scope.cancelProposalSelected = function() {
                    if ($scope.proposal && $scope.proposal.cancelTransactionType) {
                    	return $scope.proposal.cancelTransactionType[$scope.languageColumn];
                    } else {
                    	return '';
                    }
                }
            	//endregion
            }]
        }
    }])
    .directive('annexaDossierTransactionOther', ['TramFactory', function (TramFactory) {
        return {
            restrinct: 'E',
            replace: true,
            scope: {
                transaction: '=',
                order: '@',
                languageColumn: '=',
                isUpdateDossier: '='
            },
            templateUrl: './views/layout/viewdossier/general_left/other.html',
            controller: ['$scope', function ($scope) {
                $scope.updateTransaction = function (property, data) {
                    $scope.transaction[property] = data;
                    TramFactory.updateDossierTransaction($scope.transaction)
                        .then(function (data) {
                            var a = 0;
                        }).catch(function (error) {
                    });
                }
            }]
        }
    }])
    .directive('annexaNextDossierTransactionInfo', ['$filter', '$compile', 'HelperService', '$rootScope', 'globalModals', 'AnnexaFormlyFactory', 'TaskFactory', 'AnnexaModalFactory', 'RestService', 'NotificationFactory', 'Language', 'ABMShapesFactory', 'TramFactory',
        function ($filter, $compile, HelperService, $rootScope, globalModals, AnnexaFormlyFactory, TaskFactory, AnnexaModalFactory, RestService, NotificationFactory, Language, ABMShapesFactory, TramFactory) {
            var getTemplate = function (scope) {
                var level = scope.level;
                if (!level) {
                    level = 1;
                } else {
                    level++;
                }
                var template = '';
                if (scope.isParallelClose(scope.dossierTransactionInfo)) {
                    template += '   <div class="row">';
                    template += '       <div class="col-xs-12">';
                    template += '           <p><strong translate="global.tram.literals.nextStepNotFound">The Step finished</strong></p>';
                    template += '       </div>';
                    template += '   </div>';
                } else {
                    template += '   <div class="row" ng-if="!level">';
                    template += '       <div class="col-xs-12">';
                    template += '           <p><strong translate="global.tram.literals.selectNextStep">Select next steps</strong>:</p>';
                    template += '       </div>';
                    template += '   </div>';
                    template += '   <div class="row " ng-if="!level">';
                    template += '       <div class="col-xs-4">';
                    //check radio o stick fase inicial
                    template += '           <input ng-if="dossierTransactionInfo.dossierTransactionDefinition && selectType(1, dossierTransactionInfo, true)" ng-disabled="isDisabled(dossierTransactionInfo)" type="checkbox" name="checkInitial{{dossierTransactionInfo.graphNodeId}}" id="checkInitial{{dossierTransactionInfo.graphNodeId}}" ng-model="dossierTransactionInfo.active"/>';
                    template += '           <input ng-if="dossierTransactionInfo.dossierTransactionDefinition && selectType(2, dossierTransactionInfo, true)" ng-disabled="isDisabled(dossierTransactionInfo)" type="radio" name="radioInitial{{dossierTransactionInfo.graphNodeId}}" id="radioInitial{{dossierTransactionInfo.graphNodeId}}" ng-value="true" ng-model="dossierTransactionInfo.active" ng-click="clickObject(dossierTransactionInfo)"/>';
                    template += '           <i class="fa fa-check" ng-if="dossierTransactionInfo.dossierTransactionDefinition && selectType(3, dossierTransactionInfo, true)" />';
                    template += '           <span ng-if="!dossierTransactionInfo.dossierTransactionDefinition && level" translate="global.literals.otherOptions"></span>';
                    template += '           <div class="guidedProcedureText" ng-if="dossierTransactionInfo.dossierTransactionDefinition">';
                    template += '               <p ng-if="dossierTransactionInfo.dossierTransactionDefinition" class="col-xs-12 col-md-12  m-b-0">';
                    template += '                   <span class="small" translate="global.literals.name">Name</span>';
                    template += '                   <strong class="block _600  l-h-1x">{{dossierTransactionInfo.dossierTransactionDefinition[languageColumn]}}</strong>';
                    template += '               </p>';
                    template += '               <p ng-if="dossierTransactionInfo.dossierTransactionDefinition && isSubprocessFree(dossierTransactionInfo)" class="col-xs-12 col-md-12 m-b-0">';
                    template += '                   <span class="small " translate="global.literals.SUBPROCEDURE">SubProcedure</span>';
                    template += '                   <strong class="block _600  l-h-1x">{{dossierTransactionInfo.dossierTransactionDefinition[languageColumn]}}</strong>';
                    template += '               </p>';
                    template += '               <p ng-if="dossierTransactionInfo.dossierTransactionDefinition && dossierTransactionInfo.dossierTransactionDefinition.subprocedure && dossierTransactionInfo.dossierTransactionDefinition.subprocedure.id" class="col-xs-12 col-md-12 m-b-0">';
                    template += '                   <span class="small " translate="global.literals.SUBPROCEDURE">SubProcedure</span>';
                    template += '                   <strong class="block _600  l-h-1x">{{dossierTransactionInfo.dossierTransactionDefinition.subprocedure[languageColumn]}}</strong>';
                    template += '               </p>';
                    template += '           </div>';
                    template += '       </div>';
                    template += '       <div class="col-xs-8">';
                    template += '           <div ng-if="dossierTransactionInfo.dossierTransactionDefinition && isFree(dossierTransactionInfo) && isSubprocessFree(dossierTransactionInfo)" class="col-xs-12 col-md-4">';
                    template += '               <span class="small " translate="global.literals.type">Type</span>';
                    template += '               <div class="pos-rlt ">';
                    template += '                   <button type="button" class="bt-deselect m-r-md" ng-click="clear()" ng-show="dossierTransactionInfo.transactionType && dossierTransactionInfo.transactionType.id" role="button"><i class="fa fa-remove" title="{{\'global.literals.remove\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.remove\' | translate}}</span></button>';
                    template += '                   <ui-select ng-model="dossierTransactionInfo.transactionType" theme="bootstrap">';
                    template += '                       <ui-select-match>{{$select.selected[languageColumn]}}</ui-select-match>';
                    template += '                       <ui-select-choices repeat="item in transactionsTypes | filter: $select.search">';
                    template += '                           <div ng-bind-html="item[languageColumn]"></div>';
                    template += '                       </ui-select-choices>';
                    template += '                   </ui-select>';
                    template += '               </div>';
                    template += '           </div>';
                    template += '           <div ng-if="dossierTransactionInfo.dossierTransactionDefinition && (!isFree(dossierTransactionInfo) || !isSubprocessFree(dossierTransactionInfo))" class="col-xs-12 col-md-4">';
                    template += '               <p ng-if="dossierTransactionInfo.dossierTransactionDefinition && dossierTransactionInfo.dossierTransactionDefinition.transactionType" class="col-xs-12 col-md-12 m-b-0">';
                    template += '                   <span class="small " translate="global.literals.type">Type</span>';
                    template += '                   <strong class="block _600  l-h-1x">{{dossierTransactionInfo.dossierTransactionDefinition.transactionType[languageColumn]}}</strong>';
                    template += '               </p>';
                    template += '           </div>';
                    template += '           <div ng-if="dossierTransactionInfo.dossierTransactionDefinition && isFree(dossierTransactionInfo)" class="col-xs-12 col-md-4 ico-floating-wrapper">';
                    template += '               <span class="small " translate="global.literals.profile">Profile</span>';
                    template += '               <input type="text" class="form-control ico-floating-label" ng-model="dossierTransactionInfo.profile" uib-typeahead="result as result.value for result in loadProfilesFunction({val: $viewValue})" typeahead-loading="loadingBox" typeahead-no-results="noResultBox" typeahead-wait-ms="300" typeahead-editable="false">';
                    template += '               <span class="ico-floating">';
                    template += '                   <i ng-show="loadingBox" class="fa fa-spinner fa-pulse "></i>';
                    template += '                   <i ng-show="noResultBox" class="fa fa-ban "></i>';
                    template += '               </span>';
                    template += '           </div>';
                    template += '           <div class="col-xs-12 col-md-4 m-b-0" ng-if="dossierTransactionInfo.dossierTransactionDefinition && !isFree(dossierTransactionInfo)">';
                    template += '               <p ng-if="dossierTransactionInfo.dossierTransactionDefinition && !isFree(dossierTransactionInfo)" class="col-xs-12 col-md-12 m-b-0">';
                    template += '               <span class="small " translate="global.literals.profile">Profile</span>';
                    template += '                   <strong ng-repeat="processProfile in dossierTransactionInfo.dossierTransactionDefinition.processProfiles" class="block _600 l-h-1x">{{processProfile.profile[languageColumn]}}</strong>';
                    template += '               </p>';
                    template += '           </div>';
                    template += '           <p class="col-xs-12 col-md-1 m-b-0" ng-if="dossierTransactionInfo.dossierTransactionDefinition">';
                    template += '               <span class="small " translate="global.literals.days">Max days</span>';
                    template += '               <strong class="block _600 ">{{dossierTransactionInfo.dossierTransactionDefinition.maxDays}}</strong>';
                    template += '           </p>';
                    template += '           <p class="col-xs-12 col-md-3 m-b-0" ng-if="dossierTransactionInfo.dossierTransactionDefinition">';
                    template += '               <span class="small " translate="global.literals.daysComputeGlobal">Compute</span>';
                    template += '               <strong class="block _600 " ng-if="dossierTransactionInfo.dossierTransactionDefinition.transactionType && dossierTransactionInfo.dossierTransactionDefinition.transactionType.countDays" translate="global.literals.yes"></strong>';
                    template += '               <strong class="block _600 " ng-if="!dossierTransactionInfo.dossierTransactionDefinition.transactionType || !dossierTransactionInfo.dossierTransactionDefinition.transactionType.countDays" translate="global.literals.no"></strong>';
                    template += '           </p>';
                    template += '       </div>';
                    template += '       <div class="col-xs-12">';
                    template += '           <p class="m-b-0" ng-if="dossierTransactionInfo.dossierTransactionDefinition">';
                    template += '               <span class="small " translate="global.literals.information_message">Description</span>';
                    template += '               <textarea class="block _600 w-full"  maxlength="500" rows="2"  ng-model="dossierTransactionInfo.description"></textarea>';
                    template += '           </p>';
                    template += '       </div>';
                    template += '   </div>';
                    template += '   <div class="row degraded-list">';
                    template += '       <div class="col-xs-12 indentation ">';
                    template += '           <div ng-repeat="dossierTransactionInfoAux in dossierTransactionInfo.dossiersTransactionsInfo">';
                    template += '               <div class="row">';
                    template += '                   <div class=" col-xs-4 col-sm-4 p-y-sm d-flex" ng-class="{\'disabled\': isDisabled(dossierTransactionInfoAux)}">';
                    template += '                       <div class="indent-padding"></div>';
                    //check radio o stick portes
                    template += '                       <input class=" m-r-sm" ng-if="!dossierTransactionInfoAux.dossierTransactionDefinition && selectType(1, dossierTransactionInfo, false)" ng-disabled="isDisabled(dossierTransactionInfoAux)" type="checkbox" name="checkTrans{{dossierTransactionInfo.graphNodeId}}" id="checkTrans{{dossierTransactionInfo.graphNodeId}}{{$index}}" ng-model="dossierTransactionInfoAux.active" />';
                    template += '                       <input class=" m-r-sm" ng-if="!dossierTransactionInfoAux.dossierTransactionDefinition && selectType(2, dossierTransactionInfo, false)" ng-disabled="isDisabled(dossierTransactionInfoAux)" type="radio" name="radioTrans{{dossierTransactionInfo.graphNodeId}}" id="radioTrans{{dossierTransactionInfo.graphNodeId}}{{$index}}" ng-value="true" ng-model="dossierTransactionInfoAux.active" ng-click="clickObject(dossierTransactionInfo, dossierTransactionInfoAux)"/>';
                    template += '                       <i class="fa fa-check m-r-sm" ng-if="!dossierTransactionInfoAux.dossierTransactionDefinition && selectType(3, dossierTransactionInfo, false)"/>';
                    //check radio o stick faese
                    template += '                       <input class=" m-r-sm" ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && selectType(1, dossierTransactionInfo, false)" ng-disabled="isDisabled(dossierTransactionInfoAux)" type="checkbox" name="checkTrans{{dossierTransactionInfo.graphNodeId}}" id="checkTrans{{dossierTransactionInfo.graphNodeId}}{{$index}}" ng-model="dossierTransactionInfoAux.active"/>';
                    template += '                       <input class=" m-r-sm" ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && selectType(2, dossierTransactionInfo, false)" ng-disabled="isDisabled(dossierTransactionInfoAux)" type="radio" name="radioTrans{{dossierTransactionInfo.graphNodeId}}" id="radioTrans{{dossierTransactionInfo.graphNodeId}}{{$index}}" ng-value="true" ng-model="dossierTransactionInfoAux.active" ng-click="clickObject(dossierTransactionInfo, dossierTransactionInfoAux)"/>';
                    template += '                       <i class="fa fa-check m-r-sm" ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && selectType(3, dossierTransactionInfo, false)"/>';
                    //literals portes o fases
                    template += '                       <span class="text-nowrap" ng-if="!dossierTransactionInfoAux.dossierTransactionDefinition" translate="global.literals.otherOptions"></span>';
                    template += '                       <div class="guidedProcedureText" ng-if="dossierTransactionInfoAux.dossierTransactionDefinition">';
                    template += '                           <p ng-if="dossierTransactionInfoAux.dossierTransactionDefinition" class="col-xs-12 col-md-12  m-b-0">';
                    template += '                               <span class="small" translate="global.literals.name">Name</span>';
                    template += '                               <strong class="block _600 l-h-1x">{{dossierTransactionInfoAux.dossierTransactionDefinition[languageColumn]}}</strong>';
                    template += '                           </p>';
                    template += '                           <p ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && isSubprocessFree(dossierTransactionInfoAux)" class="col-xs-12 col-md-12 m-b-0">';
                    template += '                               <span class="small " translate="global.literals.SUBPROCEDURE">SubProcedure</span>';
                    template += '                               <strong class="block _600  l-h-1x">{{dossierTransactionInfoAux.dossierTransactionDefinition[languageColumn]}}</strong>';
                    template += '                           </p>';
                    template += '                           <p ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && dossierTransactionInfoAux.dossierTransactionDefinition.subprocedure && dossierTransactionInfoAux.dossierTransactionDefinition.subprocedure.id" class="col-xs-12 col-md-12 m-b-0">';
                    template += '                               <span class="small " translate="global.literals.SUBPROCEDURE">SubProcedure</span>';
                    template += '                               <strong class="block _600  l-h-1x">{{dossierTransactionInfoAux.dossierTransactionDefinition.subprocedure[languageColumn]}}</strong>';
                    template += '                           </p>';
                    template += '                       </div>';
                    template += '                  </div>';
                    //combo perfils o nom perfils
                    template += '                   <div class=" col-xs-8 col-sm-8 p-y-sm"  ng-class="{\'disabled\': isDisabled(dossierTransactionInfoAux)}">';
                    template += '                       <div ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && isFree(dossierTransactionInfoAux) && isSubprocessFree(dossierTransactionInfoAux)" class="col-xs-12 col-md-4">';
                    template += '                           <span class="small " translate="global.literals.type">Type</span>';
                    template += '                           <div class="pos-rlt ">';
                    template += '                               <button type="button" class="bt-deselect m-r-md" ng-click="clear()" ng-show="dossierTransactionInfoAux.transactionType && dossierTransactionInfoAux.transactionType.id" role="button"><i class="fa fa-remove" title="{{\'global.literals.remove\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.remove\' | translate}}</span></button>';
                    template += '                               <ui-select ng-model="dossierTransactionInfoAux.transactionType" theme="bootstrap">';
                    template += '                                   <ui-select-match>{{$select.selected[languageColumn]}}</ui-select-match>';
                    template += '                                   <ui-select-choices repeat="item in transactionsTypes | filter: $select.search">';
                    template += '                                       <div ng-bind-html="item[languageColumn]"></div>';
                    template += '                                   </ui-select-choices>';
                    template += '                               </ui-select>';
                    template += '                           </div>';
                    template += '                       </div>';
                    template += '                       <div ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && (!isFree(dossierTransactionInfoAux) || !isSubprocessFree(dossierTransactionInfoAux))" class="col-xs-12 col-md-4">';
                    template += '                           <p ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && dossierTransactionInfoAux.dossierTransactionDefinition.transactionType" class="col-xs-12 col-md-12 m-b-0">';
                    template += '                               <span class="small " translate="global.literals.type">Type</span>';
                    template += '                               <strong class="block _600  l-h-1x">{{dossierTransactionInfoAux.dossierTransactionDefinition.transactionType[languageColumn]}}</strong>';
                    template += '                           </p>';
                    template += '                       </div>';
                    template += '                       <div ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && isFree(dossierTransactionInfoAux)" class="col-xs-12 col-md-4 ico-floating-wrapper m-t-xs">';
                    template += '                           <span class="small " translate="global.literals.profile">Profile</span>';
                    template += '                           <input type="text" class="form-control ico-floating-label" ng-model="dossierTransactionInfoAux.profile" uib-typeahead="result as result.value for result in loadProfilesFunction({val: $viewValue})" typeahead-loading="loadingBox" typeahead-no-results="noResultBox" typeahead-wait-ms="300" typeahead-editable="false">';
                    template += '                           <span class="ico-floating">';
                    template += '                               <i ng-show="loadingBox" class="fa fa-spinner fa-pulse "></i>';
                    template += '                               <i ng-show="noResultBox" class="fa fa-ban "></i>';
                    template += '                           </span>';
                    template += '                       </div>';
                    template += '                       <div class="col-xs-12 col-md-4 m-b-0" ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && !isFree(dossierTransactionInfoAux)">';
                    template += '                           <p ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && !isFree(dossierTransactionInfoAux)" class="col-xs-12 col-md-12 m-b-0">';
                    template += '                               <span class="small " translate="global.literals.profile">Profile</span>';
                    template += '                               <strong ng-repeat="processProfile in dossierTransactionInfoAux.dossierTransactionDefinition.processProfiles" class="block _600 l-h-1x">{{processProfile.profile[languageColumn]}}</strong>';
                    template += '                           </p>';
                    template += '                       </div>';
                    template += '                       <p class="col-xs-12 col-md-1 m-b-0" ng-if="dossierTransactionInfoAux.dossierTransactionDefinition">';
                    template += '                          <span class="small " translate="global.literals.days">Max days</span>';
                    template += '                           <strong class="block _600 ">{{dossierTransactionInfoAux.dossierTransactionDefinition.maxDays}}</strong>';
                    template += '                       </p>';
                    template += '                       <p class="col-xs-12 col-md-3 m-b-0" ng-if="dossierTransactionInfoAux.dossierTransactionDefinition">';
                    template += '                          <span class="small " translate="global.literals.daysComputeGlobal">Compute</span>';
                    template += '                           <strong class="block _600 " ng-if=" dossierTransactionInfoAux.dossierTransactionDefinition.transactionType && dossierTransactionInfoAux.dossierTransactionDefinition.transactionType.countDays" translate="global.literals.yes"></strong>';
                    template += '                           <strong class="block _600 " ng-if="!dossierTransactionInfoAux.dossierTransactionDefinition.transactionType || !dossierTransactionInfoAux.dossierTransactionDefinition.transactionType.countDays" translate="global.literals.no"></strong>';
                    template += '                       </p>';
                    template += '                   </div>';
                    template += '                   <div class=" col-xs-12 col-sm-12 p-y-sm"  ng-class="{\'disabled\': isDisabled(dossierTransactionInfoAux)}">';
                    template += '                      <p class="m-b-0" ng-if="dossierTransactionInfoAux.dossierTransactionDefinition">';
                    template += '                           <span class="small " translate="global.literals.information_message">Description</span>';
                    template += '                           <textarea class="block _600 w-full"  maxlength="500" rows="2"  ng-model="dossierTransactionInfoAux.description"></textarea>';
                    template += '                      </p>';
                    template += '                   </div>';
                    template += '               </div>';
                    //següents fases
                    template += '           <annexa-next-dossier-transaction-info ng-if="dossierTransactionInfoAux.dossiersTransactionsInfo && dossierTransactionInfoAux.dossiersTransactionsInfo.length > 0" dossier-transaction-info="dossierTransactionInfoAux" dossier-transaction-info-orig="dossierTransactionInfoOrig" actual-transaction="actualTransaction" level="' + level + '"/>';
                    template += '        </div>';
                    template += '    </div>';
                    template += '   </div>';
                }
                return template;
            };

            return {
                restrict: 'E',
                replace: true,
                scope: {
                    dossierTransactionInfoOrig: '=',
                    dossierTransactionInfo: '=',
                    actualTransaction: '=',
                    level: "="
                },
                link: function (scope, element, attr) {
                    scope.profile = '';
                    scope.transactionsTypes = [];
                    scope.languageColumn = Language.getActiveColumn();
                    scope.descriptionLanguageColumn = "descriptionL" + scope.languageColumn.substr(1, scope.languageColumn.length);
                    if (!scope.dossierTransactionInfoOrig) {
                        scope.dossierTransactionInfoOrig = scope.dossierTransactionInfo;
                    }

                    if (TramFactory.Dossier && TramFactory.Dossier.dossierTramitationType && TramFactory.Dossier.dossierTramitationType.id) {
                        scope.transactionsTypes = $linq(TramFactory.transactionsTypes).where("x => x.tramitationType.id == " + TramFactory.Dossier.dossierTramitationType.id + " && x.finallyType == false").toArray();
                    }
                    scope.addProfileFree = function (dossierTransactionInfo) {
                        if (dossierTransactionInfo.graphNodeType == "TransactionFreeNormal" || dossierTransactionInfo.graphNodeType == "TransactionFreeFinal" || dossierTransactionInfo.graphNodeType == "SubprocessFreeNormal") {
                            if (dossierTransactionInfo.profiles && dossierTransactionInfo.profiles.length > 0) {
                                dossierTransactionInfo.profile = dossierTransactionInfo.profiles[0][scope.languageColumn];
                            }
                        }
                        if (dossierTransactionInfo.dossiersTransactionsInfo) {
                            angular.forEach(dossierTransactionInfo.dossiersTransactionsInfo, function (value, key) {
                                scope.addProfileFree(value);
                            });
                        }
                    };

                    if (scope.dossierTransactionInfo) {
                        scope.addProfileFree(scope.dossierTransactionInfo);
                    }

                    scope.loadProfilesFunction = function (value) {
                        if (!value || !value.val) {
                            return [];
                        } else if (value.val != '*' && value.val.length < 3) {
                            return [];
                        } else {
                            if (value.val == '*') {
                                value.val = '';
                            }
                            return RestService.filterData('common', 'Profile', {}, {search: value.val}, 0, 100, '', 3)
                                .then(function (data) {
                                    var items = [];

                                    if (data.data && data.data.content) {
                                        _.forEach(JSOG.decode(data.data.content), function (item) {
                                            items.push({id: item.id, value: item[Language.getActiveColumn()]});
                                        })
                                    }

                                    return items;
                                }).catch(function () {
                                    return [];
                                });
                        }
                    };

                    scope.selectType = function (type, dossierTransactionInfo, actual) {
                        if (actual && dossierTransactionInfo.graphNodeType != 'SelectOne' && dossierTransactionInfo.graphNodeType != 'SelectMultipleOpen') {
                            return false
                        } else {
                            if (type == 1) {
                                if (dossierTransactionInfo.graphNodeType == 'SelectMultipleOpen') {
                                    return true;
                                } else {
                                    return false;
                                }

                            } else if (type == 2) {
                                if (dossierTransactionInfo.graphNodeType == 'SelectOne') {
                                    return true;
                                } else {
                                    return false;
                                }
                            } else {
                                if (scope.selectType(1, dossierTransactionInfo, actual) || scope.selectType(2, dossierTransactionInfo, actual)) {
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                        }
                    };

                    scope.isFree = function (dossierTransactionInfo) {
                        if (dossierTransactionInfo.graphNodeType == "TransactionFreeNormal" || dossierTransactionInfo.graphNodeType == "TransactionFreeFinal" || dossierTransactionInfo.graphNodeType == "SubprocessFreeNormal") {
                            return true;
                        } else {
                            return false;
                        }
                    };

                    scope.isSubprocessFree = function (dossierTransactionInfo) {
                        if (dossierTransactionInfo.graphNodeType == "SubprocessFreeNormal") {
                            return true;
                        } else {
                            return false;
                        }
                    };

                    scope.clickObject = function (dossierTransactionInfo, dossierTransactionInfoAux) {
                        if (!dossierTransactionInfoAux) {
                            dossierTransactionInfo.active = true;
                        } else {
                            if (dossierTransactionInfo.dossiersTransactionsInfo) {
                                angular.forEach(dossierTransactionInfo.dossiersTransactionsInfo, function (value, key) {
                                    if (dossierTransactionInfoAux.graphNodeId != value.graphNodeId) {
                                        value.active = false;
                                    }
                                });
                            }
                        }
                    };

                    scope.isDisabled = function (dossierTransactionInfo) {
                        if (scope.dossierTransactionInfo.graphNodeId == dossierTransactionInfo.graphNodeId) {
                            return false;
                        } else {
                            if (!scope.level) {
                                return false;
                            } else if (scope.dossierTransactionInfo.active) {
                                return false;
                            } else {
                                if (scope.getAfterChecked(scope.dossierTransactionInfoOrig, dossierTransactionInfo)) {
                                    return false;
                                } else {
                                	if(scope.getAfterClosed(scope.dossierTransactionInfoOrig, dossierTransactionInfo)){
                                		return false;
                                    }else{
	                                    dossierTransactionInfo.active = false;
	                                    return true;
                                    }
                                }
                            }
                        }
                    };

                    scope.getAfterClosed = function (dossierTransactionInfoOrig, dossierTransactionInfo) {
                        var disabled = true;
                        if (dossierTransactionInfoOrig.graphNodeId == dossierTransactionInfo.graphNodeId) {
                            disabled = dossierTransactionInfo.active;
                        } else {
                            var parent = scope.getParent(scope.dossierTransactionInfoOrig, dossierTransactionInfo);
                            if (parent && parent.graphNodeType && _.contains(['SelectMultipleOpen', 'SelectOne', 'SelectAllOpen'], parent.graphNodeType)) {
                            	parent = scope.getParent(scope.dossierTransactionInfoOrig, parent);
                            	if(parent && parent.graphNodeType && _.contains(['SelectMultipleClose', 'SelectAllClose', 'SelectAllOpen'], parent.graphNodeType)){
                            	    disabled = true;
                            	}else{
                            		disabled = false;
                            	}
                            } else {
                                disabled = false;
                            }
                        }
                        return disabled;
                    };

                    scope.getAfterChecked = function (dossierTransactionInfoOrig, dossierTransactionInfo) {
                        var disabled = true;
                        if (dossierTransactionInfoOrig.graphNodeId == dossierTransactionInfo.graphNodeId) {
                            disabled = dossierTransactionInfo.active;
                        } else {
                            var parent = scope.getParent(scope.dossierTransactionInfoOrig, dossierTransactionInfo);
                            if (parent && !scope.selectType(3, parent, false)) {
                                disabled = scope.getAfterChecked(scope.dossierTransactionInfoOrig, parent);
                            } else {
                                disabled = parent.active;
                            }
                        }
                        return disabled;
                    };

                    scope.getParent = function (dossierTransactionInfoOrig, dossierTransactionInfo) {
                        var object;
                        if (dossierTransactionInfoOrig) {
                            if (dossierTransactionInfoOrig.graphNodeId == dossierTransactionInfo.graphNodeId) {
                                object = dossierTransactionInfo;
                            } else {
                                angular.forEach(dossierTransactionInfoOrig.dossiersTransactionsInfo, function (value, key) {
                                    if (!object) {
                                        if (value.graphNodeId == dossierTransactionInfo.graphNodeId) {
                                            object = dossierTransactionInfoOrig;
                                        } else {
                                            object = scope.getParent(value, dossierTransactionInfo);
                                        }
                                    }
                                });
                            }
                        }
                        return object;
                    };

                    scope.isParallelClose = function (dossierTransactionInfo) {
                        if (dossierTransactionInfo && dossierTransactionInfo.pending && (dossierTransactionInfo.graphNodeType == 'SelectMultipleClose' || dossierTransactionInfo.graphNodeType == 'SelectAllClose')) {
                            return true;
                        } else {
                            if (dossierTransactionInfo && dossierTransactionInfo.dossiersTransactionsInfo && dossierTransactionInfo.dossiersTransactionsInfo.length == 1) {
                                return scope.isParallelClose(dossierTransactionInfo.dossiersTransactionsInfo[0]);
                            } else {
                                return false;
                            }
                        }
                    }

                    element.html(getTemplate(scope)).show();
                    $compile(element.contents())(scope);
                }
            }
        }])
    .directive('annexaCompleteDossierTransaction', ['$filter', '$compile', 'Language', 'TramFactory', '$rootScope', 'apiAdmin', 'GlobalDataFactory', function ($filter, $compile, Language, TramFactory, $rootScope, apiAdmin, GlobalDataFactory) {
            var getTemplate = function (scope) {
                var template = '';
                template += '<div class="row">';
                template += '   <div class="col-sm-12">';
                template += '       <label ><span  translate="{{options.label}}">{{options.label}}</span></label>';
                template += '   </div>';
                template += '</div>';
                template += '<div class="row d-flex"  ng-repeat="next in transactionNoGuiadaList " ng-class="{\'m-b\':$last}">';
                template += '   <div class="col-xs-11 col-md-11 ">';
                template += '       <div class="row " >';
                template += '           <p class="col-xs-12 col-md-12  m-b-0">';
                template += '              <span class="small" translate="global.literals.name">Name</span>';
                template += '              <strong class="block _600 ">{{next.name}}</strong>';
                template += '           </p>';
                template += '           <p class="col-xs-12 col-md-5 m-b-0">';
                template += '               <span class="small " translate="global.literals.type">Type</span>';
                template += '               <strong class="block _600 ">{{next.transactionType[languageColumn]}}</strong>';
                template += '           </p>';
                template += '           <p class="col-xs-12 col-md-3 m-b-0">';
                template += '               <span class="small " translate="global.literals.profile">Type</span>';
                template += '               <strong class="pointer  _600 block" title="{{getProfileUsers(next.profile)}}">{{getProfileName(next)}}</strong>';
                template += '           </p>';
                template += '           <p class="col-xs-12 col-md-2 m-b-0">';
                template += '               <span class="small " translate="global.literals.days">Max days</span>';
                template += '               <strong class="block _600 ">{{next.maxDays}}</strong>';
                template += '           </p>';
                template += '           <p class="col-xs-12 col-md-2 m-b-0">';
                template += '               <span class="small " translate="global.literals.daysComputeGlobal">Compute</span>';
                template += '               <strong class="block _600 " ng-if="next.transactionType && next.transactionType.countDays" translate="global.literals.yes"></strong>';
                template += '               <strong class="block _600 " ng-if="!next.transactionType || !next.transactionType.countDays" translate="global.literals.no"></strong>';
                template += '           </p>';
                template += '           <p class="col-xs-12 col-md-12  m-b-0">';
                template += '               <span class="small " translate="global.literals.information_message">Description</span>';
                template += '               <strong class="block _600 ">{{next.description}}</strong>';
                template += '           </p>';
                template += '       </div>';
                template += '   </div>';
                template += '   <div class="col-xs-1 col-md-1 p-r-0">';
                template += '       <button  ng-click="removeFunction($index)" class="btn btn-xs l-h pull-right h-full b-a-0"><i class="fa fa-remove text-white" title="{{\'global.literals.remove\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.remove\' | translate}}</span></button>';
                template += '   </div>';
                template += '</div> <!-- /row -->';

                template += '<div class="row p-t-xs b-t d-flex" ng-if="!transactionNoGuiadaList || transactionNoGuiadaList.length == 0 || responsibleUserBoolean">';
                template += '   <div class="col-xs-11 col-md-11">';
                template += '       <div class="row">';
                template += '           <div class="col-xs-12 col-md-12 ">';
                template += '              <strong class="small _600" translate="global.literals.name">Name</strong>';
                template += '              <span class=""block"><input type="text" class="col-sm-12 form-control" ng-model="nextInfo.name" maxlength="255"></span>';
                template += '           </div>';
                template += '           <div class="col-xs-12 col-md-5">';
                template += '               <strong class="small _600" translate="global.literals.type">Type</strong>';
                template += '               <div class="pos-rlt ">';
                template += '                   <button type="button" class="bt-deselect m-r-md" ng-click="clear()" ng-show="nextInfo.transactionType && nextInfo.transactionType.id" role="button"><i class="fa fa-remove" title="{{\'global.literals.remove\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.remove\' | translate}}</span></button>';
                template += '                   <ui-select ng-model="nextInfo.transactionType" theme="bootstrap" ng-change="selectTransactionType()">';
                template += '                       <ui-select-match>{{$select.selected[languageColumn]}}</ui-select-match>';
                template += '                       <ui-select-choices repeat="item in transactionsTypes | filter: $select.search">';
                template += '                           <div ng-bind-html="item[languageColumn]"></div>';
                template += '                       </ui-select-choices>';
                template += '                   </ui-select>';
                template += '               </div>';
                template += '           </div>';
                template += '           <div class="col-xs-12 col-md-3">';
                template += '               <strong class="small _600" translate="global.literals.profile">Profile</strong>';
                template += '               <div class="ico-floating-wrapper" ng-if="!options.sendProposal || !nextInfo.profile">';
                template += '                   <input type="{{options.type}}" class=" form-control" ng-model="nextInfo.profile" placeholder="{{options.placeholder | translate}}" ng-disabled="formState.readOnly"  uib-typeahead="result as result.value for result in options.loadFunction({val: $viewValue})" typeahead-loading="loadingBox" typeahead-no-results="noResultBox" typeahead-wait-ms="300" typeahead-editable="false">';
                template += '                   <span class="ico-floating" aria-hidden="true">';
                template += '                       <i ng-show="loadingBox" class="fa fa-spinner fa-pulse " aria-hidden="true"></i>';
                template += '                       <i ng-show="noResultBox" class="fa fa-ban" title="{{ \'noResults\' | translate}}"></i>';
                template += '                   </span>';
                template += '               </div>';
                template += '               <span class="block p-y-sm" ng-if="options.sendProposal && nextInfo.profile">{{nextInfo.profile.value}}</span>';
                template += '               <div class="row m-b-125" ng-if="$root.esetMode" ng-show="nextInfo.profile.id">';
                template += '                   <div class="col-sm-12">';
                template += '                       <div class="row" ng-repeat="userProfile in nextInfo.profile.profile.userProfiles" ng-if="userProfile && userProfile.user && !userProfile.user.expiryDate  && !userProfile.user.deleted">';
                template += '                           <div class="col-sm-12">';
                template += '                               <input type="radio" ng-model="nextInfo.selectedResponsible" ng-value="userProfile.user"/>';
                template += '                               <span class="text-sm">';
                template += '                                   {{userProfile.user.name + \' \' + userProfile.user.surename1 + (userProfile.user.surename2 ? \' \' + userProfile.user.surename2 : \'\');}}';
                template += '                               </span>';
                template += '                           </div>';
                template += '                       </div>';
                template += '                   </div>';
                template += '               </div>';
                template += '           </div>';
                template += '           <div class="col-xs-12 col-md-2 ">';
                template += '               <strong class="small _600" translate="global.literals.days">Max days</strong>';
                template += '               <span class=""block"><input type="number" class=" form-control" ng-model="nextInfo.maxDays" ng-disabled="formState.readOnly" min="0"></span>';
                template += '           </div>';
                template += '           <div class="col-xs-12 col-md-2 ">';
                template += '               <strong class="small _600" translate="global.literals.daysComputeGlobal">Compute</strong>';
                template += '               <span class="block p-y-sm"><span ng-if="nextInfo.transactionType && nextInfo.transactionType.countDays" translate="global.literals.yes"></span><span ng-if="!nextInfo.transactionType || !nextInfo.transactionType.countDays" translate="global.literals.no"></span></span>';
                template += '           </div>';
                template += '           <div class="col-xs-12 col-md-12 ">';
                template += '              <strong class="small _600" translate="global.literals.information_message">Description</strong>';
                template += '              <textarea class="col-sm-12 form-control"  maxlength="500"  rows="2" ng-model="nextInfo.description"></textarea>';
                template += '           </div>';
                template += '       </div><!-- /row -->';
                template += '   </div>';
                template += '   <div class="col-xs-1 col-md-1 p-r-0">';
                template += '      <button type="button" ng-click="addFunction()" ng-disabled="!showAdd()" class="pull-right b-a-0 h-full btn btn-xs primary"><i class="fa fa-check text-white" title="{{\'global.literals.add\' | translate}}"></i></button>';
                template += '   </div>';
                template += '</div><!-- /row -->';
                return template;
            };

            return {
                restrict: 'E',
                replace: true,
                scope: {
                    ngModel: '=',
                    options: '='
                },
                link: function (scope, element, attr) {
                    scope.transactionNoGuiadaList = scope.ngModel;
                    scope.nextInfo = {};
                    scope.languageColumn = Language.getActiveColumn();
                    var dossier = TramFactory.Dossier;
                    scope.responsibleUserBoolean = false;
                    if (TramFactory.isResponsible(dossier)) {
                    	scope.responsibleUserBoolean = true;
                    }
                    scope.haveFinalTransaction = false;
                    var finalTransactions = $linq(dossier.dossierTransactions).where("x => x.transactionType != null && x.transactionType.finallyType == true").toArray();
                    if (finalTransactions && finalTransactions.length > 0) {
                    	_.forEach(finalTransactions, function(value){
                    		if(value.endDate == null){
                    			scope.haveFinalTransaction = true;
                    		}
                    	});
                    }
                    if (dossier.dossierTramitationType) {
                        var select = "x => x.tramitationType.id == " + dossier.dossierTramitationType.id;
                        if ($rootScope.esetMode) {
                            if (scope.haveFinalTransaction && dossier.dossierUrgent) {
                                select += " && x.finallyType == false && x.tramitationType.allowUrgentProcess == true"
                            } else if (scope.haveFinalTransaction) {
                                select += " && x.finallyType == false"
                            } else if (dossier.dossierUrgent) {
                                select += " && x.tramitationType.allowUrgentProcess == true"
                            }
                        } else {
                            if (dossier.dossierUrgent) {
                                select += " && x.tramitationType.allowUrgentProcess == true"
                            }
                        }
                        if (scope.options.sendProposal) {
                            //si s'envia la proposta es filtrarà només pel subtipus de tràmit 'Acord'
                        	select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[2].id;
                        } else {
                        	//en cas contrari es filtrarà pels subtipus de tràmit 'Estandard' i 'Proposta'
                        	select += " && (x.transactionSubtype == " + apiAdmin.transactionSubtypes[0].id + " || x.transactionSubtype == " + apiAdmin.transactionSubtypes[1].id + ")";
                        }
                        scope.transactionsTypes = $linq(TramFactory.transactionsTypes).where(select).toArray();
                    }
                    if (scope.options && scope.options.atributes && scope.options.atributes.backbutton && dossier.procedure && dossier.procedure.transactionTypes) {
                        scope.transactionsTypes = $linq(dossier.procedure.transactionTypes).select("x => x.transactionType").toArray();
                    }
                    if (!scope.transactionsTypes) {
                        scope.transactionsTypes = [];
                    }

                    scope.showAdd = function () {
                        var show = false;
                        if (scope.nextInfo && scope.nextInfo.profile && scope.nextInfo.profile.id && scope.nextInfo.transactionType && scope.nextInfo.transactionType.id && scope.nextInfo.maxDays > 0) {
                            if ($rootScope.esetMode && scope.nextInfo.selectedResponsible) {
                                show = true;
                            } else if (!$rootScope.esetMode) {
                                show = true;
                            }
                        }
                        return show;
                    };

                    scope.addFunction = function () {
                        if (scope.nextInfo && scope.nextInfo.transactionType && scope.nextInfo.transactionType.finallyType) {
                            scope.createTransactionTypeList();
                        }
                        scope.transactionNoGuiadaList.push(scope.nextInfo);
                        scope.nextInfo = {};
                        scope.responsibleUserBoolean = false;
                        if (TramFactory.isResponsible(dossier)) {
                        	if (!scope.options.sendProposal){
                        		scope.responsibleUserBoolean = true;
                        	}else{
                        		if(!scope.transactionNoGuiadaList || scope.transactionNoGuiadaList.length == 0){
                        			scope.responsibleUserBoolean = true;
                        		}
                        	}
                        }
                    };

                    scope.clear = function () {
                        scope.nextInfo.transactionType = undefined;
                    };

                    scope.completeNextInfoSendProposal = function(){
                    	var pdt = $linq(TramFactory.proposalsDossierTransaction).lastOrDefault(undefined, "x => x.proposalDossierTransactionType == 'PROPOSAL'");
                    	//si s'envia la proposta s'hauran d'omplir els camps
                    	scope.nextInfo = {
                    		name: $filter('translate')('global.literals.manageOf') + pdt.dossierTransaction[scope.languageColumn],
                    		transactionType: (scope.transactionsTypes && scope.transactionsTypes.length == 1) ? scope.transactionsTypes[0] : '',
                    		profile: $linq(GlobalDataFactory.allProfiles).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.secretary_properties.secretary_profile)
                    	};
                    	
                    	scope.nextInfo.profile.value = scope.nextInfo.profile[scope.languageColumn];                    	
                    	scope.nextInfo.profile.profile = scope.nextInfo.profile;
                    	
                    	if (scope.nextInfo.transactionType) scope.selectTransactionType();
                    };
                    
                    scope.createTransactionTypeList = function () {
                        var isFinal = false;
                        var finalTransactions = $linq(dossier.dossierTransactions).where("x => x.transactionType != null && x.transactionType.finallyType == true").toArray();
                        if (finalTransactions && finalTransactions.length > 0) {
                            isFinal = true;
                        }
                        if (dossier.dossierTramitationType) {
                            var select = "x => x.tramitationType.id == " + dossier.dossierTramitationType.id;
                            if ($rootScope.esetMode) {
                                if (isFinal && dossier.dossierUrgent) {
                                    select += " && x.finallyType == false && x.tramitationType.allowUrgentProcess == true"
                                } else if (isFinal) {
                                    select += " && x.finallyType == false"
                                } else if (dossier.dossierUrgent) {
                                    select += " && x.tramitationType.allowUrgentProcess == true"
                                }
                            } else {
                                if (dossier.dossierUrgent) {
                                    select += " && x.tramitationType.allowUrgentProcess == true"
                                }
                            }
                            if (scope.options.sendProposal) {
                            	//si s'envia la proposta es filtrarà només pel subtipus de tràmit 'Acord'
                            	select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[2].id;
                            } else {
                            	//en cas contrari es filtrarà pels subtipus de tràmit 'Estandard' i 'Proposta'
                            	select += " && (x.transactionSubtype == " + apiAdmin.transactionSubtypes[0].id + " || x.transactionSubtype == " + apiAdmin.transactionSubtypes[1].id + ")";
                            }
                            scope.transactionsTypes = $linq(TramFactory.transactionsTypes).where(select).toArray();

                        }
                        if (scope.options && scope.options.atributes && scope.options.atributes.backbutton && dossier.procedure && dossier.procedure.transactionTypes) {
                            scope.transactionsTypes = $linq(dossier.procedure.transactionTypes).select("x => x.transactionType").toArray();
                        }
                        
                        if (scope.options.sendProposal) {
                        	scope.completeNextInfoSendProposal();
                        }
                    }
                    scope.removeFunction = function (index) {
                        if (scope.transactionNoGuiadaList && scope.transactionNoGuiadaList.length > index) {
                            scope.transactionNoGuiadaList.splice(index, 1);
                            scope.createTransactionTypeList();
                        }
                    };
                    scope.selectTransactionType = function () {
                        scope.nextInfo.maxDays = scope.nextInfo.transactionType.days;
                        if (scope.nextInfo.maxDays && dossier && dossier.dossierUrgent) {
                            scope.nextInfo.maxDays = Math.round(scope.nextInfo.maxDays / 2);
                        }
                    };
                    scope.getProfileUsers = function (profile) {
                        var names = '';
                        if (profile && profile.profile && profile.profile.userProfiles) {
                            angular.forEach(profile.profile.userProfiles, function (value, key) {
                                if (value.user && value.user.name) {
                                    names += value.user.name + '  ' + value.user.surename1 + ((value.user.surename2) ? ' ' + value.user.surename2 + '\n' : '\n');
                                }
                            });
                        }
                        return names;
                    };
                    scope.getProfileName = function (nextInfo) {
                        var name = '';
                        if (nextInfo && nextInfo.profile) {
                            name = nextInfo.profile.value;
                            if ($rootScope.esetMode && nextInfo.selectedResponsible && nextInfo.selectedResponsible.id) {
                                name += " (" + nextInfo.selectedResponsible.name + '  ' + nextInfo.selectedResponsible.surename1 + ((nextInfo.selectedResponsible.surename2) ? ' ' + nextInfo.selectedResponsible.surename2 : '') + ")";
                            }
                        }
                        return name;
                    };
                    element.html(getTemplate(scope)).show();
                    $compile(element.contents())(scope);
                    
                    if (scope.options.sendProposal) {
                    	scope.completeNextInfoSendProposal();
                    }

                }
            }
        }]);