/**
 * Created by osirvent on 14/07/2016.
 */
angular
    .module('annexaApp')
    .factory('TramFactory',['$state', '$q', '$http', '$rootScope', 'Language', '$filter', 'AnnexaFormlyFactory', 'globalModals', 'HelperService', '$translate', 'ErrorFactory', 'dialogs', 'DccumentsFactory', 'ABMShapesFactory', 'RestService', 'SignLocalFactory', 'GlobalDataFactory', 'RegFactory',
        function ($state, $q, $http, $rootScope, Language, $filter, AnnexaFormlyFactory, globalModals, HelperService, $translate, ErrorFactory, dialogs, DccumentsFactory, ABMShapesFactory, RestService, SignLocalFactory, GlobalDataFactory, RegFactory ) {
            var factory = {};

            factory.TramTemplates = [];
            factory.DocumentTypes = [];
            factory.Register = undefined;
            factory.Diligence = undefined;
            factory.Dossier = undefined;
            factory.Procedure = undefined;
            factory.expireDate = undefined;
            factory.initialTransaction = undefined;
            factory.archiveClassifications = [];
            factory.transactionsTypes = [];
            factory.familyProcedures = [];
            factory.dossierTramitationTypes = [];
            factory.allProfiles = [];
            factory.proposalsDossierTransaction = [];
            factory.footerClaims = [];
            factory.profiles = [];
            factory.proposalTypes = [];
            
            factory.getTramTemplates = function (dossierId) {
                return $http({
                    url: './api/tram/template/procedure',
                    method: 'GET',
                    params: {dossierId: dossierId}
                }).then(function (data) {
                    factory.TramTemplates = JSOG.decode(data.data);
                }).catch(function (error) {
                });
            }

            factory.getDocumentTypes = function () {
                factory.DocumentTypes = new SortedArray(GlobalDataFactory.documentTypes, Language.getActiveColumn()).sort();

                return true;
            }

            factory.cancelDossier = function (reason) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/dossier',
                    method: 'DELETE',
                    params: {dossierId: factory.Dossier.id, reason: reason}
                }).then(function (data) {
                    deferred.resolve(data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            }

            factory.getDossierEni = function(id) {
                var deferred = $q.defer();

                $http({
                    method: 'GET',
                    url: './api/tram/expedienteEni/' + id
                }).success(function(data, status) {
                    deferred.resolve(JSOG.decode(data));
                }).error(function(msg,code) {
                    deferred.reject(msg);
                });
                return deferred.promise;
            }

            factory.fromTemplate = function (transaction, tramtemplate, document_title, section, profile, thirdsSelected, profiles, documentType, customFields, archiveClassification, relatedDocument) {
                var deferred = $q.defer();

                var send = {};
                send.transaction = JSOG.encode(transaction);
                send.tramTemplate = tramtemplate;
                send.user = JSOG.encode($rootScope.LoggedUser);
                send.langColumn = Language.getActiveColumn();
                send.title = document_title;
                send.translations = {
                    "third_relation_interested": $filter('translate')('global.literals.interested'),
                    "third_relation_representative": $filter('translate')('global.literals.representative'),
                    "yes": $filter('translate')('global.literals.yes'),
                    "no": $filter('translate')('global.literals.no')
                };
                send.section = section;
                send.profile = profile;
                send.profiles = profiles;
                send.type = documentType;
                send.customFields = customFields;
                send.archiveClassification = archiveClassification;
                if (thirdsSelected) {
                    send.thirdsSelected = JSOG.encode(thirdsSelected);
                }
                if(relatedDocument){
                	send.relatedDocument = relatedDocument;
                }
                
                $http({
                    url: './api/tram/fromtemplate',
                    method: 'POST',
                    data: send
                }).then(function (data) {
                    deferred.resolve(JSOG.decode(data.data));
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            };

            factory.getFamilyProcedures = function () {
            	if(GlobalDataFactory.familyProcedures && GlobalDataFactory.familyProcedures.length > 0){
            		var deferred = $q.defer();
            		factory.familyProcedures = angular.copy(GlobalDataFactory.familyProcedures);
            		factory.familyProcedures = $linq(factory.familyProcedures).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
                    deferred.resolve(true);
                	return deferred.promise;
            	}else{
                	return RestService.findAll('FamilyProcedure')
                    .then(function (data) {
                        var families = JSOG.decode(data.data);
                        families = $linq(families).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
                        factory.familyProcedures = families;
                    }).catch(function (error) {
                    });
            	}
            };

            factory.getProcedure = function (id, entity, register, diligence) {
                factory.Register = register;
                factory.Diligence = diligence;

                var promises = [];
                promises.push($http({url: './api/tram/procedure/start', method: 'GET', params: {idProcedure: id, idEntity: entity.id, idRegisterEntry:((register && register.id)?register.id:undefined) }}));
                promises.push(RestService.findAll('RoleInterested'));
                
                return $q.all(promises).then(function(data) {
                    var decData = JSOG.decode(data[0].data);
                    factory.Dossier = undefined;
                    factory.Procedure = decData.procedure;
                    factory.archiveClassifications = decData.archiveClassifications;
                    if (!factory.Procedure.archiveClassification) {
                        factory.Procedure.archiveClassification = {};
                    }
                    factory.expireDate = decData.expireDate;
                    factory.Procedure.maxDays = decData.maxDays;
                    if(factory.Procedure.guided){
                        var initialTransactions = $linq(factory.Procedure.transactions)
                            .where("x => x.transaction.graphNodeType == '"+ABMShapesFactory.TI+"'")
                            .toArray();
                        if(initialTransactions && initialTransactions.length > 0){
                            factory.initialTransaction = initialTransactions[0].transaction;
                        }
                    }else {
                        var initialTransactions = $linq(factory.Procedure)
                            .join(factory.Procedure.transactionTransitions, "x => x.id", "x => x.procedure.id", "(outer, inner) => inner")
                            .where("x => x.currentTransaction == null")
                            .toArray();
                        factory.initialTransaction = null;
                        if (initialTransactions.length != 0) {
                            factory.initialTransaction = initialTransactions[0].nextTransaction;
                        }
                    }                    
                    factory.optionsRoleInterested = JSOG.decode(data[1].data);
                    if (factory.optionsRoleInterested.length > 0) {
                    	$rootScope.existsOptionsRoleInterested = true;
                    } else {
                    	$rootScope.existsOptionsRoleInterested = false;
                    }
                }).catch(function (error) {
                	factory.optionsRoleInterested = [];
                    $rootScope.existsOptionsRoleInterested = false;
                });
            };
            
            factory.getDossier = function (id) {
                var entity = $rootScope.app.configuration.entity;
                var promises = [];
                promises.push($http({url: './api/tram/dossier', method: 'GET', params: {idDossier: id}}));
                promises.push($http({url: './api/tram/proposalsDossierTransaction', method: 'GET', params: {idDossier: id}}));
                promises.push(RestService.findAll('FooterClaim'));
                promises.push(RestService.findAll('Profile'));
                promises.push(RestService.findAll('ProposalType'));				
                promises.push(RestService.findAll('RoleInterested'));

                return $q.all(promises)
                    .then(function(data) {
                        factory.Dossier = JSOG.decode(data[0].data);
                        if (!factory.Dossier.archiveClassification) {
                            factory.Dossier.archiveClassification = {};
                        }
                        if(!factory.Dossier.dossierRelatedDossiers){
                            factory.Dossier.relatedDossiers = [];
                        }else{
                            factory.Dossier.relatedDossiers = factory.Dossier.dossierRelatedDossiers;
                            factory.Dossier.dossierRelatedDossiers = null;
                        }
                        factory.proposalsDossierTransaction = JSOG.decode(data[1].data);                        
                        factory.footerClaims = JSOG.decode(data[2].data);
                        factory.internalCommunicationProfiles = JSOG.decode(data[3].data);
                        factory.proposalTypes = JSOG.decode(data[4].data);						
                        factory.optionsRoleInterested = JSOG.decode(data[5].data);
                        if (factory.optionsRoleInterested.length > 0) {
                        	$rootScope.existsOptionsRoleInterested = true;
                        } else {
                        	$rootScope.existsOptionsRoleInterested = false;
                        }
                    }).catch(function() {
                        factory.Dossier = undefined;
                        factory.proposalsDossierTransaction = [];
                        factory.footerClaims = [];
                        factory.internalCommunicationProfiles = [];
                        factory.proposalTypes = [];
                        factory.optionsRoleInterested = [];
                        $rootScope.existsOptionsRoleInterested = false;
                    })
            };

            factory.updateDossier = function (dossier) {
                var deferred = $q.defer();
                var dossierAux = angular.copy(dossier);
                if (dossierAux.archiveClassification && dossierAux.archiveClassification.model) {
                    if (dossierAux.archiveClassification.model.$selected && dossierAux.archiveClassification.model.$selected.id) {
                        dossierAux.archiveClassification = {id: dossierAux.archiveClassification.model.$selected.id};
                    } else {
                        dossierAux.archiveClassification = undefined;
                    }
                }

                _.forEach(dossierAux.thirds, function (value, key) {
                    if (value.representated) {
                        delete dossierAux.thirds[key].representated.label;
                    }
                });

                $http({
                    url: './api/tram/dossier',
                    method: 'PUT',
                    data: JSOG.encode(dossierAux)
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

            factory.endDossier = function (transaction) {
                var deferred = $q.defer();
                var send = {};
                send.transaction = JSOG.encode(transaction);

                $http({
                    url: './api/tram/endDossier',
                    method: 'POST',
                    data: send
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

            factory.completeDossierTransaction = function (transaction, profile, puser, transactionNoGuiadaList, completeTransaction) {
                var deferred = $q.defer();

                var currentLanguage = $translate.use();
                var lang1 = Language.getByColumn('language1');
                var lang2 = Language.getByColumn('language2');
                var lang3 = Language.getByColumn('language3');
                var trans = {}
                $translate.use(lang1).then(function (translation) {
                    trans.tram1 = $translate.instant('global.literals.tramitation');
                    trans.puser1 = $translate.instant('global.tram.literals.pending_user');

                    $translate.use(lang2).then(function (translation) {
                        trans.tram2 = $translate.instant('global.literals.tramitation');
                        trans.puser2 = $translate.instant('global.tram.literals.pending_user');

                        $translate.use(lang3).then(function (translation) {
                            trans.tram3 = $translate.instant('global.literals.tramitation');
                            trans.puser3 = $translate.instant('global.tram.literals.pending_user');

                            $translate.use(currentLanguage);

                            var send = {};

                            send.transaction = JSOG.encode(transaction);
                            if (profile && profile.id) {
                                send.profile = JSOG.encode(profile);
                            }

                            if (transactionNoGuiadaList && transactionNoGuiadaList.length > 0) {
                                _.forEach(transactionNoGuiadaList, function(value){
                                    if(value.selectedResponsible){
                                        value.selectedResponsible.delegatorUsers = undefined;
                                        value.selectedResponsible.presetSignCircuits = undefined;
                                        value.selectedResponsible.userCertificates = undefined;
                                        value.selectedResponsible.userDefaultEntityProfiles = undefined;
                                        value.selectedResponsible.userModules = undefined;
                                        value.selectedResponsible.userPositions = undefined;
                                        value.selectedResponsible.userProfiles = undefined;
                                        value.selectedResponsible.userRoles = undefined;
                                        value.selectedResponsible.userSections = undefined;
                                        value.selectedResponsible = angular.copy(value.selectedResponsible);
                                    }
                                });
                                send.transactionNoGuiadaList = JSOG.encode(transactionNoGuiadaList);
                            }
                            if (completeTransaction) {
                                send.completeTransaction = completeTransaction;
                            }
                            send.translations = trans;
                            send.pendingUser = puser;

                            $http({
                                url: './api/tram/completeDossierTransaction',
                                method: 'POST',
                                data: send
                            }).then(function (data) {
                                if(data && data.status && (data.status == 500 || data.status == 406 || data.status == 403 || data.status == 404)){
                                    deferred.reject(data);
                                }
                                else{
                                    deferred.resolve(data.data);
                                }
                            }).catch(function (error) {
                                deferred.reject(error);
                            })
                        }).catch(function (error) {
                            $translate.use(currentLanguage);
                            deferred.reject(error);
                        })
                    }).catch(function (error) {
                        $translate.use(currentLanguage);
                        deferred.reject(error);
                    })
                }).catch(function (error) {
                    $translate.use(currentLanguage);
                    deferred.reject(error);
                })

                return deferred.promise;
            };

            factory.completeDossierTransactionGuiat = function (transaction, nextDossierTransactionInfo) {
                var deferred = $q.defer();
                var send = {};
                send.transaction = transaction;
                send.nextDossierTransactionInfo = nextDossierTransactionInfo;

                $http({
                    url: './api/tram/completeDossierTransactionGuiat',
                    method: 'POST',
                    data: JSOG.encode(send)
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

            factory.newDossier = function (dossier, cfvalues, user, thirds, registerEntryInput, dossiers, registerEntryOutput, transactionType, documents, responsibleTransactionUser) {
                var deferred = $q.defer();
                var send = {};
                send.dossier = JSOG.encode(dossier);
                send.cfValues = cfvalues;
                send.user = JSOG.encode(user);
                if (transactionType) {
                    send.transactionType = {id: transactionType};
                }
                if (responsibleTransactionUser) {
                    send.responsibleTransactionUser = {id: responsibleTransactionUser};
                }
                if(documents && documents.length > 0){
                    send.documents = [];
                    angular.forEach(documents, function (value, key) {
                        value.document = {id: value.document.id};
                        send.documents.push(value);
                    });
                }
                if (factory.Register) {
                    var fixed_thirds = []
                    angular.forEach(thirds, function (value, key) {
                        value.third = {id: value.third.id};
                        if(value.representated && value.representated.id){
                            value.representated = {id: value.representated.id};
                        }
                        fixed_thirds.push(value);
                    });
                    send.dossierThirds = fixed_thirds;

                    var fixed_ri = []
                    angular.forEach(registerEntryInput, function (value, key) {
                        fixed_ri.push({id: value.id});
                    });
                    send.registerEntryInput = fixed_ri;

                    var fixed_ro = []
                    angular.forEach(registerEntryOutput, function (value, key) {
                        fixed_ro.push({id: value.id});
                    });
                    send.registerEntryOutput = fixed_ro;

                    send.register = {id: factory.Register.id};
                } else {
                    send.dossierThirds = JSOG.encode(thirds);
                    send.registerEntryInput = JSOG.encode(registerEntryInput);
                    send.registerEntryOutput = JSOG.encode(registerEntryOutput);
                    send.register = {id: -1};
                }
                if (factory.Diligence) {
                    send.diligence = {id: factory.Diligence.id};
                }
                send.dossiers = JSOG.encode(dossiers);

                $http({
                    url: './api/tram/dossier',
                    method: 'POST',
                    data: send
                }).then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            };

            factory.deleteThird = function (dossier, thirdId) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/deleteThirdDossier',
                    method: 'POST',
                    params: {idDossier: dossier, idThird: thirdId}
                }).then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            };

            factory.deleteRegister = function (dossier, registerId, registerType) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/deleteRelatedEntriesDossier',
                    method: 'POST',
                    params: {idDossier: dossier, idRelatedEntry: registerId, relatedEntryType: registerType}
                }).then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            };

            factory.deleteDocument = function (transaction, documentId) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/deleteDocumentsDossierTransaction',
                    method: 'POST',
                    params: {idDossierTransaction: transaction, idDocument: documentId}
                }).then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            };

            factory.deleteTask = function (transaction, taskId) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/deleteTasksDossierTransaction',
                    method: 'POST',
                    params: {idDossierTransaction: transaction, idTask: taskId}
                }).then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            };

            factory.updateDossierTransaction = function (transaction) {
                var deferred = $q.defer();
                if(!transaction.dossier && transaction.dossierId){
                    transaction.dossier = {id:transaction.dossierId};
                }
                $http({
                    url: './api/tram/dossierTransaction',
                    method: 'PUT',
                    data: JSOG.encode(transaction)
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

            factory.deleteDossierTransaction = function (idTransaction, transactionLists, reason, dossierTransactionToReopen) {
                var deferred = $q.defer();
                var send = {};
                send.idDossierTransaction = idTransaction;
                send.reason = reason;
                send.dossierTransactionToReopen = dossierTransactionToReopen;
                if (transactionLists && transactionLists.length > 0) {
                    _.forEach( transactionLists, function(value){
                        if(value.selectedResponsible){
                            value.selectedResponsible.delegatorUsers = undefined;
                            value.selectedResponsible.presetSignCircuits = undefined;
                            value.selectedResponsible.userCertificates = undefined;
                            value.selectedResponsible.userDefaultEntityProfiles = undefined;
                            value.selectedResponsible.userModules = undefined;
                            value.selectedResponsible.userPositions = undefined;
                            value.selectedResponsible.userProfiles = undefined;
                            value.selectedResponsible.userRoles = undefined;
                            value.selectedResponsible.userSections = undefined;
                            value.selectedResponsible = angular.copy(value.selectedResponsible);
                        }
                    });
                    send.transactionNoGuiadaList = JSOG.encode(transactionLists);
                }
                $http({
                    url: './api/tram/deleteDossierTransaction',
                    method: 'POST',
                    data: send
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

            factory.updateDossierTransactionCustomField = function (field) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/dossierTransactionCustomField',
                    method: 'PUT',
                    data: JSOG.encode(field)
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

            factory.updateRelationTypeDossierTransactionDocument = function(dossierTransationDocumentId, relationType){
            	var deferred = $q.defer();

                $http({
                    url: './api/tram/dossierTransactionDocument/' + dossierTransationDocumentId + '/relationType/' + relationType,
                    method: 'PUT'
                }).then(function (data) {
                    deferred.resolve(JSOG.decode(data.data));
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            }
               
            factory.actionFunction = function (transaction, documentId, fileInfo, code, action, $scope, object) {
                var deferred = $q.defer();

                if (action == 'DELETE') {
                    //Delete document
                    $http({
                        url: './api/tram/deleteDocumentsDossierTransaction',
                        method: 'POST',
                        params: {idDossierTransaction: transaction.id, idDocument: documentId}
                    }).then(function (data) {
                        deferred.resolve(data.data);
                    }).catch(function (error) {
                        deferred.reject(error);
                    });

                } else if (action == 'DOWNLOAD') {

                    $scope.showLoadingdiv = true;
                    var href = "./api/repodocs/downloaddoc?id=";

                    if (fileInfo.pdf) {
                        if (fileInfo.idPdf && fileInfo.idPdf != 'null' && fileInfo.idPdf != 'undefined') {
                            href = href + fileInfo.idPdf + '&mimetype=' + fileInfo.fileTypePDF;
                        } else {
                            href = href + fileInfo.idDocumentManager + '&mimetype=application/pdf&convertPDF=true';
                        }
                    } else {
                        href = href + fileInfo.idDocumentManager + '&mimetype=' + fileInfo.fileType;
                    }
                    $http.get(href, {responseType: 'arraybuffer'})
                        .success(function (data, status, headers) {
                            headers = headers();

                            var contentType = headers['content-type'];
                            var file = new Blob([data], {type: contentType});
                            var fileName = fileInfo.name;
                            if (fileInfo.pdf) {
                                if (fileInfo.name.endsWith('.pdf')) {
                                    fileName = fileInfo.name;
                                } else {
                                    fileName = fileInfo.name + ".pdf";
                                }
                            }
                            if (window.navigator.msSaveOrOpenBlob) {
                                try {
                                    window.navigator.msSaveOrOpenBlob(file, fileName);
                                    $scope.showLoadingdiv = false;
                                } catch (e) {
                                    console.log(e.message);
                                    $scope.showLoadingdiv = false;
                                }
                            } else {
                                var linkElement = document.createElement('a');
                                try {
                                    var url = window.URL.createObjectURL(file);
                                    linkElement.setAttribute('href', url);
                                    linkElement.setAttribute("download", fileName);
                                    var clickEvent = new MouseEvent("click", {
                                        "view": window,
                                        "bubbles": true,
                                        "cancelable": false
                                    });
                                    $scope.showLoadingdiv = false;
                                    linkElement.dispatchEvent(clickEvent);
                                    deferred.resolve(true);
                                } catch (ex) {
                                    $scope.showLoadingdiv = false;
                                    console.log(ex);
                                }
                            }
                        }).error(function (error) {
                        deferred.reject(error);
                    });
                } else if (action == 'TOSIGN') {
                    SignLocalFactory.sendToSignModal(undefined, [object]);
                    $scope.$on('SendToSignModalFinished', function (event, args) {
                        deferred.resolve(args.data[0]);
                    });
                } else if (action == 'NEW_OUTPUT_REG') {
                    var retData = [];
                    var documentThird = undefined;
                    if(transaction && transaction.documents){
                        var docActual = $linq(transaction.documents).singleOrDefault(undefined, "x => x.document.id == "+documentId);
                        if(docActual && docActual.dossierThird){
                            retData.push(docActual.dossierThird);
                        }
                    }
                    if(retData && retData.length > 0) {
                        deferred.resolve(retData);
                    }else{
                        this.editReceiver(factory.Dossier.thirds, Language.getActiveColumn(), retData).then(function (data) {
                            deferred.resolve(data);
                        }).catch(function (error) {
                            deferred.reject(error);
                        });
                    }
                } else if (action == 'COMPLETE') {
                    if(Array.isArray(documentId)) {
                        DccumentsFactory.definitiveDocuments(documentId).then(function (data) {
                            if (data) {
                                deferred.resolve(data);
                            } else {
                                deferred.resolve("OK");
                            }
                        }).catch(function (error) {
                            deferred.reject(error);
                        });
                    }else{
                        DccumentsFactory.definitiveDocument({id: object.document.id}).then(function (data) {
                            if (data) {
                                object.document = data;
                                deferred.resolve(object.document);
                            } else {
                                deferred.resolve("OK");
                            }
                        }).catch(function (error) {
                            deferred.reject(error);
                        });
                    }
                }

                return deferred.promise;
            };

            factory.editReceiver = function (receiver, langCol, retData) {

                var deferred = $q.defer();
                var modal = angular.copy(globalModals.receiverEdit);
                modal.model = {};
                modal.model.modal_body = {};
                modal.options = {};

                try {
                    var receivers = angular.copy(receiver);

                    // Carregar el array amb els Destinataris actuals
                    var destinataris = [];
                    var destFinal = [];
                    angular.forEach(receivers, function (value, key) {
                        var destinatariType = '';
                        if (value.interested) {
                            destinatariType = $filter('translate')('global.literals.interested');
                        } else {
                            var representated = '';
                            if (value.representated && value.representated.identificationDocument) {
                                if (value.representated.name) {
                                    representated = '(' + value.representated.name + ' ' + value.representated.surename1 + ((value.representated.surename2)?' '+value.representated.surename2:'')+ ' (' + value.representated.identificationDocument + ')';
                                } else {
                                    representated = '(' + value.representated.corporateName + ' (' + value.representated.identificationDocument + ')';
                                }
                            }
                            destinatariType = $filter('translate')('global.literals.representative', {representated: representated});
                        }
                        //translate-values="{ representated: \' (\' + receiver.representated.name + \' \' + receiver.representated.surename + \' (\' + receiver.representated.identificationDocument + \')\' + \')\' }
                        var thirdName = '';
                        if (value.third.thirdType == 'PHISICAL') {
                            thirdName = value.third.name + ' ' + value.third.surename1+ ((value.third.surename2)?' '+value.third.surename2:'');
                        } else {
                            thirdName = value.third.corporateName;
                        }

                        destinataris.push({
                            'value': value.third.id,
                            'label': thirdName,
                            'labelHtml': destinatariType
                        });
                    });

                    modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.options = destinataris;

                    var submitFunction = function () {
                        angular.forEach(receivers, function (data) {
                            if (modal.annexaFormly.model.modal_body['editReceiver_' + data.third.id] == true) {
                                destFinal.push(data);
                            }
                        })
                        modal.close();
                        deferred.resolve(destFinal);
                    };
                    AnnexaFormlyFactory.showModal("modalEditReceiver", modal, submitFunction, false);
                }
                catch (error) {
                    deferred.reject(error);
                }
                return deferred.promise;
            };

            factory.getDefaultOutRegChannel = function () {
                return $http({
                    url: './api/tram/defaultOutRegChannel',
                    method: 'GET'
                }).then(function (data) {
                    return data.data;
                }).catch(function (error) {
                    var a = 0;
                });
            };

            factory.modifyFavorite = function (idDossierTransaction, idUser) {
                var deferred = $q.defer();
                $http({
                    url: './api/tram/dossierTransactionModifyFavorite',
                    method: 'POST',
                    params: {idDossierTransaction: idDossierTransaction, idUser: idUser}
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            }

            factory.getArchiveClassifications = function () {
                return $http({
                    url: './api/archiveClassifications/archiveClassification',
                    method: 'GET'
                }).then(function (data) {
                    factory.archiveClassifications = JSOG.decode(data.data);
                }).catch(function (error) {
                });
            };

            factory.generateFoliate = function () {
                if (factory.Dossier) {
                    var modal = angular.copy(globalModals.generateFoliate);
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = {};
                    modal.annexaFormly.options = {};
                    modal.annexaFormly.options.formState = {readOnly: false};
                    modal.annexaFormly.model.modal_body.documents = {};
                    var selectableDocuments = [];
                    _.forEach(factory.Dossier.dossierTransactions, function (value, key) {
                        if (value.documents) {
                            _.forEach(value.documents, function (value2, key2) {
                            	var type = $linq(GlobalDataFactory.documentRelationTypes).firstOrDefault({ id: 'DOCUMENT', language1: 'Document', language2: 'Documento', language3: 'DOCUMENT' }, "x => x.id == '"+value2.relationType+"'");
                            	selectableDocuments.push({ id: value2.id, value: value2.document.name, type:type.id, typeName:type[Language.getActiveColumn()], order:value2.document.createdDate});
                                modal.annexaFormly.model.modal_body.documents[value2.id] = false;
                            });
                        }
                    });
                    modal.annexaFormly.fields[0].fieldGroup[4].templateOptions.options = $linq(selectableDocuments).orderBy("x => x.order").toArray();
                    modal.alerts = [];
                    var submitFunction = function () {
                        var model = this.annexaFormly.model.modal_body;
                        var self = this;
                        self.alerts.length = 0;
                        var send = {};
                        send.dossier = JSOG.encode(angular.copy(factory.Dossier));
                        if (model.documents) {
                            var docs = [];
                            angular.forEach(model.documents, function (value, key) {
                                if (value) {
                                    var docToPush  = $linq(selectableDocuments).where("x => x.id == "+key).toArray();
                                    if(docToPush && docToPush.length > 0 && docToPush[0].id) {
                                        docs.push({id:docToPush[0].id});
                                    }
                                }
                            });
                            docs = $linq(docs).orderBy("x => x.id").toArray();
                            send.documents = JSOG.encode(docs);
                        }
                        send.languageColumn = Language.getActiveColumn();
                        if(!docs || docs.length == 0){
                            var errorTranslate = {msg: ErrorFactory.getErrorMessage('dossier', 'foliate', {message: 'Not found documents'})};
                            self.alerts.push({msg: errorTranslate.msg});
                        }else {
                            $http({
                                url: './api/tram/foliate',
                                method: 'POST',
                                data: send,
                                headers: {
                                    accept: 'application/zip'
                                },
                                responseType: 'arraybuffer',
                                cache: false
                            }).then(function (data, status, headers) {
                                var contentType = data.headers('content-type');
                                if (!contentType) {
                                    contentType = 'application/zip';
                                }
                                var name = data.headers('content-disposition');
                                if (name) {
                                    name = name.split(";");
                                    if (name && name.length > 1) {
                                        name = name[1].trim().split("=");
                                        if (name && name.length > 1) {
                                            name = name[1];
                                            name = name.replace(/"/g, '');
                                        } else {
                                            name = "foliateExp.zip";
                                        }
                                    } else {
                                        name = "foliateExp.zip";
                                    }
                                } else {
                                    name = "foliateExp.zip";
                                }
                                var file = new Blob([data.data], {type: contentType});
                                if (window.navigator.msSaveOrOpenBlob) {
                                    try {
                                        window.navigator.msSaveOrOpenBlob(file, name);
                                    } catch (e) {
                                        console.log(e.message);
                                    }
                                } else {

                                    var linkElement = document.createElement('a');
                                    try {
                                        var url = window.URL.createObjectURL(file);
                                        linkElement.setAttribute('href', url);
                                        linkElement.setAttribute("download", name);
                                        var clickEvent = new MouseEvent("click", {
                                            "view": window,
                                            "bubbles": true,
                                            "cancelable": false
                                        });
                                        linkElement.dispatchEvent(clickEvent);
                                    } catch (ex) {
                                        console.log(ex);
                                    }
                                }
                                self.close();
                            }).catch(function (error) {
                                var errorTranslate = {msg: ErrorFactory.getErrorMessage('dossier', 'foliate', error.data)};
                                self.alerts.push({msg: errorTranslate.msg});
                            });
                        }
                    };
                    AnnexaFormlyFactory.showModal('modalFoliateTram', modal, submitFunction, false, undefined, 'global.literals.generate_foliate');
                } else {
                    //ERROR no hi ha dossier
                }
            };

            factory.editReceiverAndDocuments = function (receivers, documents, langCol, retData) {
                var deferred = $q.defer();
                try {
                    var selfNewNotification = this;
                    var data = {
                        row: true,
                        colClass: 'col-sm-12',
                        labelClass: 'label-strong',
                        extralabel: true,
                        clear: function ($event, model, key, $select) {
                            $event.stopPropagation();
                            model[key] = undefined;
                            if ($select) {
                                $select.selected = undefined;
                                $select.search = undefined;
                            }
                        }
                    };

                    var modal = angular.copy(globalModals.editReceiverAndDocuments);
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = {};
                    modal.annexaFormly.options = {};
                    modal.annexaFormly.options.formState = {readOnly: false};
                    modal.extra = this;
                    var form = new AnnexaFormly();
                    if (documents) {
                        var i = 0;
                        angular.forEach(documents, function (value, key) {
                            var field = form.createField(
                                'documents' + i,
                                'annexaRadioCheckboxRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldSelectTemplateOptions(
                                    '',
                                    'value',
                                    'label',
                                    [{value: value.id, label: value.name}],
                                    false,
                                    'checkbox'
                                ),
                                data
                            );
                            modal.annexaFormly.fields[0].fieldGroup[1].fieldGroup.push(field);
                            i++;
                        });
                        modal.annexaFormly.fields[0].fieldGroup[1].fieldGroup[0].templateOptions.label = $filter('translate')('global.literals.documents');
                    }
                    if (receivers) {
                        var i = 0;
                        angular.forEach(receivers, function (value, key) {
                            var name = ((value.third.name) ? value.third.name + " " : "") + ((value.third.surename1) ? value.third.surename1 + " " : "") + ((value.third.surename2) ? value.third.surename2 + " " : "") + ((value.third.corporateName) ? value.third.corporateName : "");
                            var destinatariType = '';
                            if (value.interested) {
                                destinatariType = $filter('translate')('global.literals.interested');
                            } else {
                                var representated = '';
                                if (value.representated && value.representated.identificationDocument) {
                                    if (value.representated.name) {
                                        representated = '(' + value.representated.name + ' ' + value.representated.surename1 + ((value.representated.surename2)? ' '+value.representated.surename2:'')+ ' (' + value.representated.identificationDocument + ')';
                                    } else {
                                        representated = '(' + value.representated.corporateName + ' (' + value.representated.identificationDocument + ')';
                                    }
                                }
                                destinatariType = $filter('translate')('global.literals.representative', {representated: representated});
                            }
                            var field = form.createField(
                                'receivers' + i,
                                'annexaRadioCheckboxRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldSelectTemplateOptionsHtml(
                                    '',
                                    'value',
                                    'label',
                                    [{value: value.third.id, label: name, labelHtml: destinatariType}],
                                    false,
                                    'checkHTML',
                                    undefined,
                                    'labelHtml'
                                ),
                                data
                            );
                            modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup.push(field);
                            i++;
                        });
                        modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[0].templateOptions.label = $filter('translate')('global.literals.receivers');
                    }
                    modal.alerts = [];
                    var submitFunction = function () {
                        var dataRet = {thirds: [], documents: []};
                        if (receivers) {
                            angular.forEach(receivers, function (data, key) {
                                if (modal.annexaFormly.model.modal_body.thirds['receivers' + key + '_' + data.third.id] == true) {
                                    dataRet.thirds.push(data);
                                }
                            });
                        }
                        if (documents) {
                            angular.forEach(documents, function (data, key) {
                                if (modal.annexaFormly.model.modal_body.documents['documents' + key + '_' + data.id] == true) {
                                    dataRet.documents.push(data);
                                }
                            });
                        }
                        modal.close();
                        deferred.resolve(dataRet);
                    };

                    AnnexaFormlyFactory.showModal("modalEdit", modal, submitFunction, false, undefined, 'global.literals.accept');
                } catch (error) {
                    deferred.reject(error);
                }
                return deferred.promise;
            };

            factory.getNextDossierTransactions = function (transactionId) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/getNextDossierTransactions',
                    method: 'GET',
                    params: {transactionId: transactionId}
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

            factory.isFinalTransaction = function (transaction) {
                if (ABMShapesFactory.isTransactionFinal(transaction) == true) {
                    var dlg = dialogs.confirm(
                        $filter('translate')('global.literals.confirmEndDossierTitle'),
                        $filter('translate')('global.literals.confirmEndDossierBody'),
                        {
                            animation: true,
                            backdrop: 'static',
                            keyboard: true,
                            size: '',
                            backdropClass: 'zMax',
                            windowClass: 'zMax2'
                        }
                    );

                    dlg.result.then(function (btn) {
                        factory.endDossier(transaction).then(function (data) {
                            $state.transitionTo('annexa.tram.pending');
                        }).catch(function (error) {
                            var err = dialogs.error(
                                $filter('translate')('DIALOGS_ERROR_MSG'),
                                $filter('translate')(ErrorFactory.getErrorMessage('dossier', 'complete', error.data)),
                                {
                                    animation: true,
                                    backdrop: 'static',
                                    keyboard: true,
                                    size: ''
                                }
                            );
                        });
                    }, function (btn) {
                        var a = 0;
                    });
                } else {
                    var err = dialogs.error(
                        $filter('translate')('DIALOGS_ERROR_MSG'),
                        $filter('translate')('global.literals.errorCompletNotFinalTransaction'),
                        {
                            animation: true,
                            backdrop: 'static',
                            keyboard: true,
                            size: ''
                        }
                    );
                }
            };

            factory.getTransactionTypes = function () {
                return $http({
                    url: './api/transactiontypes/types',
                    method: 'GET'
                }).then(function (data) {
                    factory.transactionsTypes = JSOG.decode(data.data);
                    if(factory.transactionsTypes && factory.transactionsTypes.length > 0){
                        factory.transactionsTypes = $linq(factory.transactionsTypes).orderBy("x => x.orderView").toArray();
                    }
                }).catch(function (error) {
                });
            };

            factory.getTramitationTypes = function () {
                return $http({
                    url: './api/tramitationTypes/types',
                    method: 'GET'
                }).then(function (data) {
                    factory.dossierTramitationTypes = JSOG.decode(data.data);
                }).catch(function (error) {
                });
            };

            factory.isParallelUnguidedTransaction = function (transaction) {
                var isParallel = false;
                var endDateNull = 0;
                if (transaction && transaction.dossierTransactionGroups) {
                    angular.forEach(transaction.dossierTransactionGroups, function (value, key) {
                        if (value.dossierTransactions && endDateNull < 2) {
                            angular.forEach(value.dossierTransactions, function (value2, key2) {
                                if (endDateNull < 2) {
                                    if (!value2.endDate) {
                                        endDateNull++;
                                    }
                                }
                            });
                        }
                    });
                    if (endDateNull > 1) {
                        isParallel = true;
                    }
                }
                return isParallel;
            };

            factory.createTramResposibleModal = function (transaction) {
                var data = {
                    row: true,
                    colClass: ' col-sm-12',
                    labelClass: ''
                };
                var modal = angular.copy(globalModals.createDossierTransactionPopup);
                modal.alerts = [];
                modal.annexaFormly = new AnnexaFormly();
                var fields = [];
                fields.push(modal.annexaFormly.createField('profile', 'annexaLoadUserAndComboButtonRow', 'col-sm-12',
                    new AnnexaFormlyLoadUserFieldTemplateOptions('text', 'global.literals.profile_handler', false, false, [], factory.searchProfiles, function () {
                        },
                        'Profile', 'global.literals.select', {dossier: factory.Dossier}, undefined, 'global.reg.literals.profileOrUserName', false, false, true)
                    , data)
                );
                modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {transactionNoGuiadaList: []};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.transaction = transaction;
                modal.extra = {transaction: transaction};
                AnnexaFormlyFactory.showModalPromise("createTramResponsible", modal, factory.createTramResposible, false, false, 'global.literals.create').then(function (modalAux) {
                    modal.modal = modalAux;
                    modal.close = modalAux.element.scope().close;
                    modalAux.element.modal();
                    angular.element(".modal-title").focus();
                }).catch(function () {
                    var a = 0;
                });
            };

            factory.createTramResposible = function (component, profile, puser, transactionNoGuiadaList, completeTransaction) {
                var deferred = $q.defer();
                var self = this;
                if (self.annexaFormly.model.modal_body.transactionNoGuiadaList && self.annexaFormly.model.modal_body.transactionNoGuiadaList.length > 0) {
                    var currentLanguage = $translate.use();
                    var lang1 = Language.getByColumn('language1');
                    var lang2 = Language.getByColumn('language2');
                    var lang3 = Language.getByColumn('language3');
                    var trans = {};
                    $translate.use(lang1).then(function (translation) {
                        trans.tram1 = $translate.instant('global.literals.tramitation');
                        trans.puser1 = $translate.instant('global.tram.literals.pending_user');
                        $translate.use(lang2).then(function (translation) {
                            trans.tram2 = $translate.instant('global.literals.tramitation');
                            trans.puser2 = $translate.instant('global.tram.literals.pending_user');
                            $translate.use(lang3).then(function (translation) {
                                trans.tram3 = $translate.instant('global.literals.tramitation');
                                trans.puser3 = $translate.instant('global.tram.literals.pending_user');
                                $translate.use(currentLanguage);
                                var send = {};
                                send.dossierTransaction = JSOG.encode(self.extra.transaction);
                                if (self.annexaFormly.model.modal_body.transactionNoGuiadaList && self.annexaFormly.model.modal_body.transactionNoGuiadaList.length > 0) {
                                    _.forEach( self.annexaFormly.model.modal_body.transactionNoGuiadaList, function(value){
                                        if(value.selectedResponsible){
                                            value.selectedResponsible.delegatorUsers = undefined;
                                            value.selectedResponsible.presetSignCircuits = undefined;
                                            value.selectedResponsible.userCertificates = undefined;
                                            value.selectedResponsible.userDefaultEntityProfiles = undefined;
                                            value.selectedResponsible.userModules = undefined;
                                            value.selectedResponsible.userPositions = undefined;
                                            value.selectedResponsible.userProfiles = undefined;
                                            value.selectedResponsible.userRoles = undefined;
                                            value.selectedResponsible.userSections = undefined;
                                            value.selectedResponsible = angular.copy(value.selectedResponsible);
                                        }
                                    });
                                    send.transactionNoGuiadaList = JSOG.encode(self.annexaFormly.model.modal_body.transactionNoGuiadaList);
                                }
                                send.translations = trans;
                                $http({
                                    url: './api/tram/dossierTransaction',
                                    method: 'POST',
                                    data: send
                                }).then(function (data) {
                                    deferred.resolve(data.data);
                                    self.close();
                                    $state.reload();
                                    //$state.transitionTo('annexa.tram.pending');
                                }).catch(function (error) {
                                    deferred.reject(error);
                                    self.alerts.push({msg: ErrorFactory.getErrorMessage('dossier', 'complete', error.data)});
                                })
                            }).catch(function (error) {
                                $translate.use(currentLanguage);
                                deferred.reject(error);
                            })
                        }).catch(function (error) {
                            $translate.use(currentLanguage);
                            deferred.reject(error);
                        })
                    }).catch(function (error) {
                        $translate.use(currentLanguage);
                        deferred.reject(error);
                    })
                } else {
                    self.alerts.push({msg: 'global.tram.errors.noTransactions'});
                    deferred.reject('global.tram.errors.noTransactions');
                }
                return deferred.promise;
            };

            factory.sendProposalDossierTransaction = function (self) {
                var deferred = $q.defer();
                if (self.annexaFormly.model.modal_body.transactionNoGuiadaList && self.annexaFormly.model.modal_body.transactionNoGuiadaList.length > 0) {
                    var currentLanguage = $translate.use();
                    var lang1 = Language.getByColumn('language1');
                    var lang2 = Language.getByColumn('language2');
                    var lang3 = Language.getByColumn('language3');
                    var trans = {};
                    $translate.use(lang1).then(function (translation) {
                        trans.tram1 = $translate.instant('global.literals.tramitation');
                        trans.puser1 = $translate.instant('global.tram.literals.pending_user');
                        $translate.use(lang2).then(function (translation) {
                            trans.tram2 = $translate.instant('global.literals.tramitation');
                            trans.puser2 = $translate.instant('global.tram.literals.pending_user');
                            $translate.use(lang3).then(function (translation) {
                                trans.tram3 = $translate.instant('global.literals.tramitation');
                                trans.puser3 = $translate.instant('global.tram.literals.pending_user');
                                $translate.use(currentLanguage);
                                var send = {};
                                send.dossierTransaction = JSOG.encode(self.extra.transaction);
                                if (self.annexaFormly.model.modal_body.transactionNoGuiadaList && self.annexaFormly.model.modal_body.transactionNoGuiadaList.length > 0) {
                                    _.forEach( self.annexaFormly.model.modal_body.transactionNoGuiadaList, function(value){
                                        if(value.selectedResponsible){
                                            value.selectedResponsible.delegatorUsers = undefined;
                                            value.selectedResponsible.presetSignCircuits = undefined;
                                            value.selectedResponsible.userCertificates = undefined;
                                            value.selectedResponsible.userDefaultEntityProfiles = undefined;
                                            value.selectedResponsible.userModules = undefined;
                                            value.selectedResponsible.userPositions = undefined;
                                            value.selectedResponsible.userProfiles = undefined;
                                            value.selectedResponsible.userRoles = undefined;
                                            value.selectedResponsible.userSections = undefined;
                                            value.selectedResponsible = angular.copy(value.selectedResponsible);
                                        }
                                    });
                                    send.transactionNoGuiadaList = JSOG.encode(self.annexaFormly.model.modal_body.transactionNoGuiadaList);
                                }
                                send.proposal = {id:self.extra.proposal.id};
                                
                                $http({
                                    url: './api/tram/sendProposalDossierTransaction',
                                    method: 'POST',
                                    data: send
                                }).then(function (data) {
                                    deferred.resolve(data.data);
                                }).catch(function (error) {
                                    deferred.reject(error);
                                    self.alerts.push({msg: ErrorFactory.getErrorMessage('proposal', 'send', error.data)});
                                })
                            }).catch(function (error) {
                                $translate.use(currentLanguage);
                                deferred.reject(error);
                            })
                        }).catch(function (error) {
                            $translate.use(currentLanguage);
                            deferred.reject(error);
                        })
                    }).catch(function (error) {
                        $translate.use(currentLanguage);
                        deferred.reject(error);
                    })
                } else {
                    self.alerts.push({msg: 'global.tram.errors.noTransactions'});
                    deferred.reject('global.tram.errors.noTransactions');
                }
                return deferred.promise;
            };

            factory.sendGuidedProposalDossierTransaction = function (transaction, proposal, nextDossierTransactionInfo) {
                var deferred = $q.defer();
                var send = {};
                send.transaction = transaction;
                send.proposal = {id:proposal.id};
                send.nextDossierTransactionInfo = nextDossierTransactionInfo;

                $http({
                    url: './api/tram/sendGuidedProposalDossierTransaction',
                    method: 'POST',
                    data: JSOG.encode(send)
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

            factory.searchProfiles = function (value) {
                if (!value || !value.val) {
                    return [];
                } else if (value.val != '*' && value.val.length < 3) {
                    return [];
                } else {
                    if (value.val == '*') {
                        value.val = '';
                    }
                    return RestService.filterData('common', 'Profile', {}, { search: value.val }, 0, 100, '', 3)
                        .then(function(data) {
                            var items = [];

                            if(data.data && data.data.content) {
                                _.forEach(JSOG.decode(data.data.content), function(item) {
                                    items.push({ id: item.id, value: item[Language.getActiveColumn()], profile: item});
                                })
                            }

                            return items;
                        }).catch(function () {
                            return [];
                        });
                }
            };

            factory.isResponsible = function () {
                var responsible = false;
                if(factory.Dossier && $rootScope.LoggedUser){
                    if(factory.Dossier.responsibleUser && factory.Dossier.responsibleUser.id == $rootScope.LoggedUser.id){
                        responsible = true;
                    }else if(factory.Dossier.dossierResponsibleProfiles && factory.Dossier.dossierResponsibleProfiles.length > 0 && $rootScope.LoggedUser.userProfiles){
                        angular.forEach(factory.Dossier.dossierResponsibleProfiles, function(value){
                            var pr = $linq($rootScope.LoggedUser.userProfiles).singleOrDefault(undefined, "x => x.profile.id == " + value.profile.id);
                            if(pr){
                                responsible = true;
                            }
                        });
                    }
                }
                return responsible;
            };

            factory.getProfiles = function(){
                return RestService.findAll('Profile', 'allProfiles').then(function(objects){
                    factory.allProfiles = new SortedArray(JSOG.decode(objects.data), Language.getActiveColumn()).sort();
                });
            }

            factory.reopenTramModal = function () {
                var data = {
                    row: true,
                    colClass: ' col-sm-12',
                    labelClass: ''
                };
                var modal = angular.copy(globalModals.createDossierTransactionPopup);
                modal.title = $filter('translate')('global.literals.reopenTram');
                modal.alerts = [];
                modal.annexaFormly = new AnnexaFormly();
                var fields = [];
                fields.push(modal.annexaFormly.createField('profile', 'annexaLoadUserAndComboButtonRow', 'col-sm-12',
                    new AnnexaFormlyLoadUserFieldTemplateOptions('text', 'global.literals.profile_handler', false, false, [], factory.searchProfiles, function () {
                        },
                        'Profile', 'global.literals.select', {dossier: factory.Dossier}, undefined, 'global.reg.literals.profileOrUserName', false, false, true)
                    , data)
                );
                modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {transactionNoGuiadaList: []};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                //modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.transaction = transaction;
                //modal.extra = {transaction: transaction};
                AnnexaFormlyFactory.showModalPromise("reopenTram", modal, factory.reopenTram, false, false, 'global.literals.reopen').then(function (modalAux) {
                    modal.modal = modalAux;
                    modal.close = modalAux.element.scope().close;
                    modalAux.element.modal();
                    angular.element(".modal-title").focus();
                }).catch(function () {
                    var a = 0;
                });
            };

            factory.reopenTram = function (component, profile, puser, transactionNoGuiadaList, completeTransaction) {
                var deferred = $q.defer();
                var self = this;
                if (self.annexaFormly.model.modal_body.transactionNoGuiadaList && self.annexaFormly.model.modal_body.transactionNoGuiadaList.length > 0) {
                    var currentLanguage = $translate.use();
                    var lang1 = Language.getByColumn('language1');
                    var lang2 = Language.getByColumn('language2');
                    var lang3 = Language.getByColumn('language3');
                    var trans = {};
                    $translate.use(lang1).then(function (translation) {
                        trans.tram1 = $translate.instant('global.literals.tramitation');
                        trans.puser1 = $translate.instant('global.tram.literals.pending_user');
                        $translate.use(lang2).then(function (translation) {
                            trans.tram2 = $translate.instant('global.literals.tramitation');
                            trans.puser2 = $translate.instant('global.tram.literals.pending_user');
                            $translate.use(lang3).then(function (translation) {
                                trans.tram3 = $translate.instant('global.literals.tramitation');
                                trans.puser3 = $translate.instant('global.tram.literals.pending_user');
                                $translate.use(currentLanguage);
                                var send = {};
                                send.dossier = {id:factory.Dossier.id};
                                if (self.annexaFormly.model.modal_body.transactionNoGuiadaList && self.annexaFormly.model.modal_body.transactionNoGuiadaList.length > 0) {
                                    send.transactionNoGuiadaList = self.annexaFormly.model.modal_body.transactionNoGuiadaList;
                                }
                                send.translations = trans;
                                $http({
                                    url: './api/tram/dossier/reopen/'+factory.Dossier.id,
                                    method: 'POST',
                                    data: send
                                }).then(function (data) {
                                    deferred.resolve(data.data);
                                    self.close();
                                    $state.transitionTo('annexa.tram.pending');
                                }).catch(function (error) {
                                    deferred.reject(error);
                                    self.alerts.push({msg: ErrorFactory.getErrorMessage('dossier', 'reopen', error.data)});
                                })
                            }).catch(function (error) {
                                $translate.use(currentLanguage);
                                deferred.reject(error);
                            })
                        }).catch(function (error) {
                            $translate.use(currentLanguage);
                            deferred.reject(error);
                        })
                    }).catch(function (error) {
                        $translate.use(currentLanguage);
                        deferred.reject(error);
                    })
                } else {
                    self.alerts.push({msg: 'global.tram.errors.noTransactions'});
                    deferred.reject('global.tram.errors.noTransactions');
                }
                return deferred.promise;
            };
            var calculateDates = function(dates, transaction, newDate){
                var newDates = [];
                var startDate = new Date(transaction.startDate);
                var endDate = transaction.endDate;
                if(endDate == null){
                    endDate = newDate;
                }else{
                    endDate = new Date(endDate);
                }
                _.forEach(dates, function(date, i) {
                    if(startDate < date.startDate && endDate < date.startDate) {
                        newDates.push({startDate:startDate, endDate:endDate});
                        newDates.push(date);
                    }else if(startDate > date.endDate && endDate > date.endDate){
                        newDates.push(date);
                        if(i == (dates.length -1)) {
                            newDates.push({startDate: startDate, endDate: endDate});
                        }
                    }else if (startDate < date.startDate && endDate > date.endDate) {
                        newDates.push({startDate:startDate, endDate:endDate});
                    }else if(startDate >= date.startDate && endDate > date.endDate){
                        newDates.push({startDate:date.startDate, endDate:endDate});
                    }else if(startDate < date.startDate && endDate <= date.endDate){
                        newDates.push({startDate:startDate, endDate:date.endDate});
                    }else{
                        newDates.push(date);
                    }
                });
                return newDates;
            }
            factory.getDaysPendingUser = function(dossierTransactions, loggedUser){
                var daysPendingUser = 0;
                if(dossierTransactions && dossierTransactions.length > 0){
                    var pendigUserTransactions = $linq(dossierTransactions).where("x => x.pendingUser").orderBy("x => new Date(x.startDate)").toArray();
                    if(pendigUserTransactions && pendigUserTransactions.length > 0) {
                        var dates = [];
                        var newdate = new Date();
                        _.forEach(pendigUserTransactions, function(transaction){
                            if(dates.length == 0){
                                if(transaction.endDate == null) {
                                    dates.push({startDate: new Date(transaction.startDate), endDate: newdate})
                                }else{
                                    dates.push({startDate: new Date(transaction.startDate), endDate: new Date(transaction.endDate)})
                                }
                            }else{
                                dates = calculateDates(dates, transaction, newdate);
                            }
                        });
                        if(dates && dates.length > 0) {
                            _.forEach(dates, function(date){
                                daysPendingUser += HelperService.getWorkingDays(date.startDate, date.endDate, loggedUser.entity.holidays);
                            });
                        }
                    }
                }
                return daysPendingUser;
            }

            factory.updateDossierThird = function(id, dossierThird) {
                var deferred = $q.defer();

                $http({
                    url: './api/dossier_third/',
                    method: 'PUT',
                    data: JSOG.encode(dossierThird)
                }).then(function (data) {
                    deferred.resolve(JSOG.decode(data.data))
                }).catch(function(error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            }
            
            factory.reopenDossierTransaction = function (idTransaction) {
                var deferred = $q.defer();
                $http({
                    url: './api/tram/dossier_transaction/reopen',
                    method: 'POST',
                    params: {idDossierTransaction: idTransaction}
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };
            
            factory.updateDossierTransactionProcessProfile = function (idDossierTransactionProcessProfile, idProfile) {
                var deferred = $q.defer();
                $http({
                    url: './api/tram/dossierTransactionProcessProfile',
                    method: 'PUT',
                    params: {idDossierTransactionProcessProfile: idDossierTransactionProcessProfile, idProfile: idProfile}
                }).then(function (data) {
                	deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };            
            
            return factory;
    }]);
