/**
 * Created by osirvent on 30/06/2016.
 */
angular
    .module('annexaApp')
    .controller('TramController', ['$scope', '$rootScope', 'HeaderService', '$state', 'Language', 'RestService', 'AnnexaFormlyFactory', 'AnnexaModalFactory', 'TableFilter', 'BoxFactory', 'DccumentsFactory', '$filter', 'TramFactory', 'GlobalDataFactory', function ($scope, $rootScope, HeaderService, $state, Language, RestService, AnnexaFormlyFactory, AnnexaModalFactory, TableFilter, BoxFactory, DccumentsFactory, $filter, TramFactory, GlobalDataFactory) {
        HeaderService.onChangeState($scope, function(message) {
            if(message.state.name == 'annexa.tram.start' || message.state.name == 'annexa.tram.pending' || message.state.name == 'annexa.tram.view') {
                $rootScope.subHeadButtons = [];

                $rootScope.subHeadButtons.push(new HeadButtonSearch('#tableFilter'));

                if(message.state.name == 'annexa.tram.pending' && $rootScope.esetMode) {
                    $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#dataTableInput','fa-plus fa-fw','global.literals.initExp',undefined,'initExp'))
                }


                $rootScope.subHeadTabs = [
                    {
                        state: 'annexa.tram.pending',
                        type: 'breadDivButton ' + (message.state.name == 'annexa.tram.pending' ? 'active' : ''),
                        name: 'global.tram.tabs.pending.title',
                        permissions: ['process_dossier']
                    },
                    {
                        state: 'annexa.tram.view',
                        type: 'breadDivButton ' + (message.state.name == 'annexa.tram.view' ? 'active' : ''),
                        name: 'global.tram.tabs.view.title',
                        permissions: ['process_dossier', 'view_dossier']
                    }
                ];

                if(!$rootScope.esetMode) {
                    $rootScope.subHeadTabs.push({
                        state: 'annexa.tram.start',
                        type: 'breadDivButton ' + (message.state.name == 'annexa.tram.start' ? 'active' : ''),
                        name: 'global.tram.tabs.start.title',
                        permissions: ['start_dossier']
                    });
                }
            }
        });

        HeaderService.changeState($state.current);
        
        $scope.languageColumn = Language.getActiveColumn();
        $scope.informationLanguageColumn = "";
        if($scope.languageColumn) {
            $scope.informationLanguageColumn = "informationLanguage" + $scope.languageColumn.substr($scope.languageColumn.length - 1);
        }

        //region Filtre pestanya documents

        $scope.filterTabDocuments = [
            { id: 'code', type: 'text', order: 0, label: 'global.literals.code' },
            { id: 'name', type: 'text', order: 1, label: 'global.literals.title' },
            { id: 'type', type: 'select', order: 2, label: 'global.literals.docType', dataType: 'LOCAL', data: $linq(GlobalDataFactory.documentTypes).where("x => x.isPublic").toArray(), addAll: true, nameProperty: $scope.languageColumn },
            { id: 'transactionType', type: 'select', order: 3, label: 'global.tram.literals.transaction_type', dataType: 'LOCAL', data: TramFactory.transactionsTypes, addAll: true, nameProperty: $scope.languageColumn }
        ]

        //endregion

        //region Taula pestanya documents

        var docStatusTitle = function() {
            var content = '';

            content += '<div>';
            content += '    <div class="btn-group dropdown">';
            content += '        <button type="button" class="btn btn-sm white dropdown-toggle p-l-0" data-toggle="dropdown" title="{{\'global.literals.showOptions\'}}">';
            content += '            <span class="fa-stack" aria-hidden="true">';
            content += '                <i class="fa fa-{{headerIconDocStatus(true)}} text-{{headerIconDocStatus(false)}} text-lg fa-stack-1x"></i>';
            content += '            </span>';
            content += '            <span class="sr-only">{{\'global.literals.showOptions\'}}</span>';
            content += '        </button>';
            content += '        <div class="dropdown-menu">';
            content += '            <a class="dropdown-item" ng-repeat="status in statuses | orderBy:\'orderStatus\'" ng-click="filterData.status = (!status.id || status.id == 0 ?  \'\' : status.id); tableDefinition.reloadInternalData(false);">';
            content += '                <span class="fa-stack">';
            content += '                    <i class="fa  fa-{{status.icon}} text-{{status.style}} text-lg fa-stack-1x"></i>';
            content += '                </span>';
            content += '                &nbsp;&nbsp;<span translate="global.literals.all" ng-if="status.id == \'\'"></span>';
            content += '                &nbsp;&nbsp;<span translate="global.documentState.{{status.name}}" ng-if="status.id != \'\'"></span>';
            content += '            </a>';
            content += '        </div>';
            content += '    </div>';
            content += '</div>';

            return content;
        }

        var docStatusRender = function(data, type, full, meta) {
            var content = '';

            if(DccumentsFactory.statuses && DccumentsFactory.statuses.length > 0) {
                var status = $linq(DccumentsFactory.statuses).singleOrDefault(undefined, "x => x.id == '" + data + "'");

                if(status) {
                    content += '<div class="text-center">';
                    content += '    <span class="fa-stack" title="' + $filter('translate')('global.documentState.' + status.name) + '">';
                    content += '        <i class="fa fa-' + status.icon + ' text-' + status.style + ' text-lg fa-stack-1x"></i>';
                    content += '    </span>';
                    content += '</div>';
                }
            }

            return content;
        }

        var actionsTitle = function () {
            return '<div class="column-actions">' + $filter('translate')('global.literals.actions') + '</div>';
        }

        actionsRender = function (data, type, full, meta) {
            return '<annexa-document-actions-button dossier-transaction-document="tableObjects[' + full.id + ']" list="true"></annexa-document-actions-button>';
        };

        $scope.columnsTabDocuments = [
            { id: 'document.publicDocument', column: new BooleanColumn($filter, 'global.documentState.public', 'public') },
            { id: 'document.docStatus', width: '53px', title: docStatusTitle(), render: docStatusRender, sortable: false },
            { id: 'document.id', width: '250px', column: new UIRouterStateColumn($filter, 'global.literals.code', 'annexa.doc.documents.view({code: \'[data]\'})', undefined, 'var code = full.document.id; if(full.document.code) { code = full.document.code.substr((full.code.indexOf(full.document.id) - 2), full.document.id.toString().length + 2); } code;') },
            { id: 'document.id', width: '250px', column: new UIRouterStateColumn($filter, 'global.literals.name', 'annexa.doc.documents.view({code: \'[data]\'})', undefined, 'var name = full.document.name; name;') },
            { id: 'dossierTransaction.transactionType.' + $scope.languageColumn, width: '140px', column: new DatabaseTranslatedColumn($filter, 'global.tram.literals.transaction_type', $scope.languageColumn) },
            { id: 'dossierTransaction.' + $scope.languageColumn, title: $filter('translate')('global.literals.transaction') },
            { id: 'actions', columnName: 'id', width: '120px', className: 'text-center', title: actionsTitle(), render: actionsRender }
        ]

        //endregion
    }])
    .controller('TramStartController',['$scope', '$rootScope', '$filter', 'TableFilter', 'DTColumnBuilder', 'Language', 'TramFactory', 'TableFilterFactory', 'RouteStateFactory', '$state', 'GlobalDataFactory', function ($scope, $rootScope, $filter, TableFilter, DTColumnBuilder, Language, TramFactory, TableFilterFactory, RouteStateFactory, $state, GlobalDataFactory) {
        $scope.tfilter = [
            { id: 'family', type: 'select-tree', order: 0, label: 'global.literals.family', dataType: 'LOCAL', data: GlobalDataFactory.familyProcedures, addAll: true, nameProperty: Language.getActiveColumn()},
            { id: 'id', type: 'text', order: 1, label: 'global.literals.code'},
            { id: 'acronym', type: 'text', order: 2, label: 'global.literals.acronym'},
            { id: Language.getActiveColumn(), type: 'text', order: 3, label: 'global.literals.procedure'}
        ];
        var routeState = RouteStateFactory.getRouteState($state.current);
        if(routeState) {
            $scope.tfilter = routeState.state;
        }
        $scope.filterData = { loggedUser: $rootScope.LoggedUser.id, langColumn: $scope.languageColumn };

        var getFilterCall = function() {
            return TableFilterFactory.getFilterCall($scope.tfilter);
        }

        var getFilterCallAux = function () {
            var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);
            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            filterCallAux.langColumn = $scope.languageColumn;
            filterCallAux.applyPermissions = true;
            return filterCallAux;
        }
        var ProcedureActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),new ActionButton('global.tram.tabs.start.title','annexa.tram.newdossier({ procedure: \'[data]\' })',undefined,true));

        var columnsAux = [
            { id: 'family.' + Language.getActiveColumn(), column: new DatabaseTranslatedColumn($filter, 'global.literals.family', Language.getActiveColumn())},
            { id: 'id',  title: $filter('translate')('global.literals.code')},
            { id: 'acronym', title: $filter('translate')('global.literals.acronym')},
            { id: Language.getActiveColumn(), column: new DatabaseTranslatedColumn($filter, 'global.literals.procedure', Language.getActiveColumn())},
            { id: 'actions', columnName: 'id', width: '150px', className: 'text-center', column: ProcedureActionsColumn,  sortable: false}
        ];

        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_exp_start', columnsAux);
        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_exp_start', columnsAux);
        $scope.tableOrderProperties = {sortName: 'family', sort: [[0, 'asc']]};
        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > -1){
            $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
        }
        $scope.tableDefinition = {
            id: 'tableProcedure',
            origin: 'tram',
            objectType: 'Procedure',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: columns,
            containerScope: $scope
        };

        $scope.submit = function () {
            $scope.tableDefinition.reloadInternalData(true);
        }


    }])
    .controller('TramPendingController',['$scope', '$rootScope', '$filter', 'TableFilter', 'DTColumnBuilder', 'Language', 'HelperService', 'TramFactory', '$stateParams', 'HeaderService', '$state', 'RegFactory', 'RegModals', 'CommonService', 'AnnexaFormlyFactory', 'TableFilterFactory', 'RouteStateFactory', 'GlobalDataFactory', function ($scope, $rootScope, $filter, TableFilter, DTColumnBuilder, Language, HelperService, TramFactory, $stateParams, HeaderService, $state, RegFactory, RegModals, CommonService, AnnexaFormlyFactory, TableFilterFactory, RouteStateFactory, GlobalDataFactory) {
        var expiredStates = [
            { id: 'expired', name: $filter('translate')('global.literals.outOfTime') },
            { id: 'noExpired', name: $filter('translate')('global.literals.tramNoExpired') }
        ];
        $scope.filterData = { loggedUser: $rootScope.LoggedUser.id, favorite: '', langColumn: $scope.languageColumn };
        var routeStateColumnsFilter = RouteStateFactory.getRouteState($state.current, 'ColumnsFilter');
        if(routeStateColumnsFilter) {
            $scope.filterData = routeStateColumnsFilter.state;
        }

        var allProfilesBasic = angular.copy(GlobalDataFactory.allProfiles);
        $linq(allProfilesBasic).foreach(function (x) { 
        	delete x['userProfiles'];
        	delete x['ficticio'];
        });
        
        $scope.tfilter = [];
        $scope.tfilter.push({ id: 'dossierNumber', type: 'text', order: 0, label: 'global.literals.code', callAux: true});
        $scope.tfilter.push({ id: 'family', type: 'select-tree', order: 1, label: 'global.literals.family', dataType: 'LOCAL', data: GlobalDataFactory.familyProcedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true});
        $scope.tfilter.push({ id: 'procedure', type: 'text', order: 2, label: 'global.literals.procedure', callAux: true});
        $scope.tfilter.push({ id: 'procedureClassification', type: 'text', order: 3, label: 'global.literals.classification', callAux: true});
        $scope.tfilter.push({ id: 'subject', type: 'text', order: 4, label: 'global.literals.subject', callAux: true});
        $scope.tfilter.push({ id: 'thirds', type: 'text', order: 5, label: 'global.literals.thirdsInt', callAux: true});
        $scope.tfilter.push({ id: Language.getActiveColumn(), type: 'text', order: 6, label: 'global.literals.transaction'});
        $scope.tfilter.push({ id: 'custom_fields_search', type: 'text', order: 7, label: 'global.literals.customFields', callAux: true});
        $scope.tfilter.push({ id: 'dates', type: 'dateRange', order: 8, label: 'global.tram.literals.startDateTram', callAux: true });
        $scope.tfilter.push({ id: 'creator', type: 'text', order: 9, label: 'global.tram.literals.tramCreator', callAux: true});
        if ($rootScope.esetMode) {
        	$scope.tfilter.push({ id: 'responsibleUser', type: 'text', order: 10, label: 'global.tram.literals.tramResponsible', callAux: true, model:(($rootScope.LoggedUser)?($rootScope.LoggedUser.name+" "+$rootScope.LoggedUser.surename1+(($rootScope.LoggedUser.surename2)?' '+$rootScope.LoggedUser.surename2:'')):undefined)});
        } else {        	
        	$scope.tfilter.push({ id: 'responsibleUser', type: 'text', order: 10, label: 'global.tram.literals.tramResponsible', callAux: true});
        }
        $scope.tfilter.push({ id: 'responsibleTramitProfile', type: 'select-multiple', order: 11, label: 'global.literals.responsibleTramitProfile', dataType: 'LOCAL', data: new SortedArray(allProfilesBasic,Language.getActiveColumn()).sort(), addAll: false, nameProperty: Language.getActiveColumn(), callAux: true});
        $scope.tfilter.push({ id: 'register_entry', type: 'text', order: 12, label: 'global.literals.register_entry_INI', callAux: true});
        $scope.tfilter.push({ id: 'expired', type: 'select', order: 13, label: 'global.literals.expiredSate', dataType: 'LOCAL', data: angular.copy(expiredStates), addAll: true, nameProperty: 'name', callAux: true });

        var routeState = RouteStateFactory.getRouteState($state.current);
        if(routeState) {
            $scope.tfilter = routeState.state;
        }

        var prefilter = undefined;
        if($stateParams.type){
            prefilter = {};
            var filterExpired = $linq($scope.tfilter).where("x => x.id == 'expired'").toArray();
            if($stateParams.type == 'expired' && filterExpired){
                filterExpired[0].preFilter = 'expired';
                prefilter.expired = {value:'expired'};
            }else if($stateParams.type == 'favorit'){
                $scope.filterData.favorite = $stateParams.type;
            }
        }

        $scope.FavoritesColumnRenderFunction = function (data) {
            if(data) {
                var users = $linq(data)
                    .where("x => x.user.id == " + $rootScope.LoggedUser.id)
                    .toArray();
                if (users.length != 0) {
                    return 'favorit';
                } else {
                    return 'nofavorit';
                }
            }
            return '';
        };

        $scope.FavoritesColumnModificar = function(id) {
            TramFactory.modifyFavorite(id, $rootScope.LoggedUser.id).then(function(data){
                $scope.tableDefinition.reloadInternalData(false,true);
            }).catch(function(error){
                $scope.tableDefinition.reloadInternalData(false, true);
            });
        }

        $scope.FavoritesColumn = new IconSearchColumn(
            'dossier.favoriteUsers',
            '<iconsearchcolumn definition="FavoritesColumn" filterModel="filterData" fieldName="favorite"/>',
            '5%',
            new IconSearchRender($scope, 'FavoritesColumn'),
            false,
            true,
            [
                new IconSearchOption('favorit','favorit', 'fa-star text-warn text-lg', 'fa-star text-warn text-lg', 'global.literals.favorite'),
                new IconSearchOption('nofavorit','nofavorit', 'fa-star-o text-lg', 'fa-star-o text-lg', 'global.literals.noFavorite'),
            ],
            'tableDefinition',
            $scope.FavoritesColumnRenderFunction,
            'FavoritesColumnModificar'
        );

        var literalDays = !$rootScope.esetMode ? 'global.literals.days' : 'global.literals.dossierDays';

        var DaysColumn = new RemaingDaysColumn($filter, literalDays, HelperService);
        var TransactionDaysColumn = new RemaingDaysColumn($filter, 'global.literals.transactionDays', HelperService);
        var ProcedureColumn = new DatabaseTranslatedColumn($filter, 'global.literals.procedure', $scope.languageColumn);
        var TransactionColumn = new TransactionNameColumn($filter, 'global.literals.transaction', $scope.languageColumn);
       	var transactionCreatorColumn = new TransactionCreatorColumn($filter, 'global.tram.literals.tramCreator');
        var transactionResponsibleColumn = new TransactionResponsibleColumn($filter, 'global.tram.literals.tramResponsible');
        var transactionResponsibleProfileColumn = new TransactionResponsibleProfileColumn($filter, 'global.literals.responsibleTramitProfile', $scope.languageColumn);
        var TranCounterColumn = new TransactionsCounterColumn();
        var CreatorUserColumn = new UserColumn($filter, 'global.literals.creator');
        var RelatedColumn = new RelatedDossiersColumn();
        var DossierTransactionActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),new ActionButton('global.literals.see','annexa.tram.pending.viewdossier({ dossier: \'[data]\', dossierTransaction : \'[data2]\' })',undefined,true), undefined, 'id');
        var PrincipalColumn = new PrincipalThirdColumn($filter,'global.literals.principalDest');
        var TransactionType = new DatabaseTranslatedColumn($filter, 'global.tram.literals.transaction_type', $scope.languageColumn);

        var DossierNumberColumn = new LinkColumn($filter, 'global.literals.code', 'viewDossier', 'dossier.id' );

        var columnsAux = [];
        if(!$rootScope.esetMode) {
            columnsAux = [
                { id: 'dossier.favoriteUsers', width: '40px', title: $scope.FavoritesColumn.getTitle(), render:$scope.FavoritesColumn.getRender()},
                { id: 'dossier.remainingDays', width: '40px', column: DaysColumn },
                { id: 'dossier.dossierNumber', width: '140px', title:$filter('translate')('global.literals.code')},
                { id: 'dossier.procedure.' + ProcedureColumn.getColumn(), width: '140px', title:  ProcedureColumn.getTitle() },
                { id: TransactionColumn.getColumn(), width: '140px', title: TransactionColumn.getTitle(),  render: TransactionColumn.getRender },
                { id: 'id', width: '60px', render: TranCounterColumn.getRender, sortable: false },
                { id: 'dossier.subject', title: $filter('translate')('global.literals.subject')},
                { id: 'dossier.thirds', width: '200px', column:  PrincipalColumn, sortable: false },
                { id: 'startDate', width: '110px', column:  transactionCreatorColumn },
                { id: 'responsibleUser.completeName', width: '110px', column:  transactionResponsibleColumn },
                { id: 'processProfiles.profile.' + $scope.languageColumn, width: '110px', column:  transactionResponsibleProfileColumn },
                { id: 'dossier.relatedDossiers', width: '40px', render: RelatedColumn.getRender },
                { id: 'actions', columnName: 'dossier.id', width: '100px', className: 'text-center', title: DossierTransactionActionsColumn.getTitle(), render: DossierTransactionActionsColumn.getRender, sortable: false}
            ];
        } else {
            columnsAux = [
                { id: 'dossier.favoriteUsers', width: '40px', title: $scope.FavoritesColumn.getTitle(), render:$scope.FavoritesColumn.getRender()},
                { id: 'dossier.remainingDays', width: '40px', column: DaysColumn },
                { id: 'transactionRemainingDays', width: '40px', column: TransactionDaysColumn },
                { id: 'dossier.dossierNumber', width: '140px', title:$filter('translate')('global.literals.code')},
                { id: 'transactionType.' + TransactionType.getColumn(), width: '140px', title: TransactionType.getTitle()},
                { id: 'dossier.procedure.' + ProcedureColumn.getColumn(), width: '140px', title:  ProcedureColumn.getTitle() },
                { id: TransactionColumn.getColumn(), width: '140px', title: TransactionColumn.getTitle(),  render: TransactionColumn.getRender },
                { id: 'id', width: '60px', render: TranCounterColumn.getRender, sortable: false },
                { id: 'dossier.subject', title: $filter('translate')('global.literals.subject')},
                { id: 'dossier.thirds', width: '200px', column:  PrincipalColumn, sortable: false },
                { id: 'startDate', width: '110px', column:  transactionCreatorColumn },
                { id: 'responsibleUser.completeName', width: '110px', column:  transactionResponsibleColumn },
                { id: 'processProfiles.profile.' + $scope.languageColumn, width: '110px', column:  transactionResponsibleProfileColumn },
                { id: 'dossier.relatedDossiers', width: '40px', render: RelatedColumn.getRender },
                { id: 'actions', columnName: 'dossier.id', width: '100px', className: 'text-center', title: DossierTransactionActionsColumn.getTitle(), render: DossierTransactionActionsColumn.getRender, sortable: false}
            ];
        }

        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_exp_pending', columnsAux);
        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_exp_pending', columnsAux);
        $scope.tableOrderProperties = {sortName: 'startDate', sort: [[1,'desc']]};
        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > -1){
            $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
        }

        var getFilterCall = function(){
            var filterCall = TableFilterFactory.getFilterCall($scope.tfilter);
            filterCall.actual = true;
            filterCall.dossier = {closed:false};
            return filterCall;
        }
        var getFilterCallAux = function(){
            var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);
            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            filterCallAux.langColumn = $scope.languageColumn;
            filterCallAux.favorite = $scope.filterData.favorite;
            if(filterCallAux.expired){
                if(filterCallAux.expired.id == 'expired'){
                    filterCallAux.expired = true;
                }else if(filterCallAux.expired.id == 'noExpired'){
                    filterCallAux.expired = false;
                }else{
                    filterCallAux.expired = undefined;
                }
            }
            filterCallAux.query = false;
            return filterCallAux;
        }

        $scope.tableDefinition = {
            id: 'tableDossierTransactionView',
            origin: 'tram',
            objectType: 'DossierTransaction',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: columns,
            containerScope: $scope
        };

        $scope.viewDossier = function (id) {
            $state.transitionTo('annexa.tram.pending.viewdossier', { "dossier" : id });
        }

        $scope.submit = function () {
            $scope.tableDefinition.reloadInternalData(true);
        }

        $scope.initExp = function() {
            RegFactory.findFamiliesByRegisterClassification(undefined)
                .then(function(data) {
                    var familiesProcedure = JSOG.decode(data.data);
                    familiesProcedure = $linq(familiesProcedure).orderBy("x => x." + $scope.languageColumn, linq.caseInsensitiveComparer).toArray();

                    var modal = RegModals.initExpRegisterSelect;
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = { familyProcedure: {}, isPublic_true: true};
                    modal.annexaFormly.options = {};
                    modal.annexaFormly.options.formState = { readOnly: false};
                    modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.labelProp = Language.getActiveColumn();
                    modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.options = CommonService.getTreeData(familiesProcedure, Language.getActiveColumn());
                    modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.entryClassification = undefined;
                    AnnexaFormlyFactory.showModal('modalGoToTram', modal, $scope.goToTram, false);

                })
        }
        
        $scope.goToTram = function () {
            $state.transitionTo('annexa.tram.newdossier', { "procedure": this.annexaFormly.model.modal_body.procedure });
            this.close();
        }
    }])
    .controller('TramViewController',['$scope', '$rootScope', '$filter', 'TableFilter', 'DTColumnBuilder', 'Language', 'HelperService', 'TramFactory', '$stateParams', 'TableFilterFactory', 'RouteStateFactory', '$state', 'GlobalDataFactory', function ($scope, $rootScope, $filter, TableFilter, DTColumnBuilder, Language, HelperService, TramFactory, $stateParams, TableFilterFactory, RouteStateFactory, $state, GlobalDataFactory) {
        var dossierStatuses = [
            { id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') },
            { id: 'FINISHED', name: $filter('translate')('FINISHED') },
            { id: 'CANCELLED', name: $filter('translate')('CANCELLED') }
        ];

        var tramitationEstates = [
            { id: 'TRAMITATION', name: $filter('translate')('global.literals.tramParticipated') },
            { id: 'SEE', name: $filter('translate')('global.literals.tramPendingSee') }
        ];

        $scope.filterData = { loggedUser: $rootScope.LoggedUser.id, favorite: '', langColumn: $scope.languageColumn, query: true };

        $scope.tfilter = [
            { id: 'dossierNumber', type: 'text', order: 0, label: 'global.literals.code', callAux: true},
            { id: 'family', type: 'select-tree', order: 1, label: 'global.literals.family', dataType: 'LOCAL', data: GlobalDataFactory.familyProcedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true},
            { id: 'procedure', type: 'text', order: 2, label: 'global.literals.procedure', callAux: true},
            { id: 'procedureClassification', type: 'text', order: 0, label: 'global.literals.classification', callAux: true},
            { id: 'subject', type: 'text', order: 3, label: 'global.literals.subject', callAux: true},
            { id: 'thirds', type: 'text', order: 3, label: 'global.literals.thirdsInt', callAux: true},
            { id: 'transaction', type: 'text', order: 5, label: 'global.literals.transaction', callAux: true},
            { id: 'custom_fields_search', type: 'text', order: 6, label: 'global.literals.customFields', callAux: true},
            { id: 'dates', type: 'dateRange', order: 7, label: 'global.delegate.startDate', callAux: true },
            (
                ($rootScope.esetMode)?
                { id: 'responsibleUser', type: 'text', order: 8, label: 'global.literals.responsible', callAux: true}
                    :
                { id: 'creator', type: 'text', order: 8, label: 'global.literals.creator', callAux: true}
            ),
            { id: 'register_entry', type: 'text', order: 9, label: 'global.literals.register_entry_INI', callAux: true},
            { id: 'dossierStatus', type: 'select', order: 10, label: 'global.literals.state', dataType: 'LOCAL', data: angular.copy(dossierStatuses), addAll: true, nameProperty: 'name', callAux: true },
        ];
        var routeState = RouteStateFactory.getRouteState($state.current);
        if(routeState) {
            $scope.tfilter = routeState.state;
        }

        var prefilter = undefined;
        if($stateParams.type){
            prefilter = {};
            var filterDossierStatus = $linq($scope.tfilter).where("x => x.id == 'dossierStatus'").toArray();
            if($stateParams.type == 'tramitation'){
                //$scope.tfilter.tramitation.model = {id:'SEE'};
                //prefilter.tramitation = {value:'SEE'};
            }else if($stateParams.type == 'finally' && filterDossierStatus){
                filterDossierStatus[0].preFilter = 'FINISHED';
                prefilter.dossierStatus = {value:'FINISHED'};
            }else if($stateParams.type == 'participated'){
                //$scope.tfilter.tramitation.model = {id:'TRAMITATION'};
                //prefilter.tramitation = {value:'TRAMITATION'};
            }
        }

        var StatusColumn = new DossierStatusColumn($filter, 'global.literals.dossier');
        var ProcedureColumn = new DatabaseTranslatedColumn($filter, 'global.literals.procedure', $scope.languageColumn);
        var TranCounterColumn = new TransactionsCounterColumn();
        var CreatorUserColumn = new UserColumn($filter, 'global.literals.creator');
        var DossierTransactionActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),new ActionButton('global.literals.see','annexa.tram.view.viewdossier({ dossier: \'[data]\' })',undefined,true),undefined,'id');
        var PrincipalColumn = new PrincipalThirdColumn($filter,'global.literals.principalDest');
        var CreatedDateColumn = new DateColumn($filter, 'global.literals.creation_date');

        var DossierTransactionStatusRender = function(data, type, full, meta) {
            var content = '';

            if(data) {
                content = '<i class="text-success fa fa-circle" title="' + $filter('translate')('global.literals.closedMS') + '" aria-hidden="true"></i> ';
            } else {
                content = '<i class="text-warn fa fa-circle" title="' + $filter('translate')('global.literals.openedMS') + '" aria-hidden="true"></i> ';
            }

            return content;
        }

        var getFilterCall = function(){
            var filterCall = TableFilterFactory.getFilterCall($scope.tfilter);
            return filterCall;
        }
        var getFilterCallAux = function(){
            var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);
            filterCallAux.filterOrigin = 'dossierView';
            filterCallAux.langColumn = $scope.languageColumn;
            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            return filterCallAux;
        }

        var columnsAux = [
            { id: 'dossierStatus', width: '50px', column: StatusColumn },
            { id: 'dossierNumber', width: '140px', title: $filter('translate')('global.literals.code') },
            { id: 'procedure.' + ProcedureColumn.getColumn(), width: '140px', title:ProcedureColumn.getTitle()},
            { id: 'id', width: '60px', render: TranCounterColumn.getRender, sortable: false },
            { id: 'subject', title:  $filter('translate')('global.literals.subject') },
            { id: 'thirds', width: '200px', column: PrincipalColumn, sortable: false },
            { id: 'creatorUser', width: '150px', column: CreatorUserColumn},
            { id: 'createdDate', width: '100px', column:  CreatedDateColumn},
            { id: 'actions', columnName: 'id', width: '40px', className: 'text-center', title: DossierTransactionActionsColumn.getTitle(), render: DossierTransactionActionsColumn.getRender, sortable: false}
        ];

        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_exp_view', columnsAux);
        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_exp_view', columnsAux);
        $scope.tableOrderProperties = {sortName: 'dossier.dossierNumber', sort: [[7, 'desc']]};
        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > -1){
            $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
        }

        $scope.tableDefinition = {
            id: 'tableDossierView',
            origin: 'tram',
            objectType: 'Dossier',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: columns,
            containerScope: $scope
        };

        $scope.submit = function () {
            $scope.tableDefinition.reloadInternalData(true);
        }
    }])
    .controller('TramViewDossierViewController',['$scope', '$rootScope', '$state', '$stateParams', '$filter', 'TramFactory', 'Language', 'HeaderService', 'tramForms', '$timeout', 'CustomFieldFactory', 'HelperService', 'BoxFactory', 'AnnexaEntityFactory', 'globalModals', 'AnnexaFormlyFactory', 'AnnexaPermissionsFactory', 'DTColumnBuilder', 'CommonService', 'TableFilter', 'DccumentsFactory', 'NotificationFactory', 'SignLocalFactory', 'RegFactory', 'GlobalDataFactory', 'apiAdmin', function($scope, $rootScope, $state, $stateParams, $filter, TramFactory, Language, HeaderService, tramForms, $timeout, CustomFieldFactory, HelperService, BoxFactory, AnnexaEntityFactory, globalModals, AnnexaFormlyFactory, AnnexaPermissionsFactory, DTColumnBuilder, CommonService, TableFilter, DccumentsFactory, NotificationFactory, SignLocalFactory, RegFactory, GlobalDataFactory, apiAdmin) {
        $rootScope.showLoadingdivSignin = false;
        $scope.userSections = $linq($rootScope.LoggedUser.userSections).distinctBy("x => x.section.id").select("x => x.section").toArray();
        $scope.idDossierSelectedTab = 'right';
        $scope.thirdModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.thirds'").toArray();
        $scope.dossierModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.tram'").toArray();
        $scope.docModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.doc.documents.all'").toArray()

        $scope.viewdata = {
            tramTemplates: TramFactory.TramTemplates,
            documentTypes: TramFactory.DocumentTypes
        };

        $scope.dossierTramitationTypes = TramFactory.dossierTramitationTypes;
        $scope.allProfiles = TramFactory.allProfiles;

        $scope.dossier = TramFactory.Dossier;
        $scope.modify = false;
        
        $scope.responsibleUserAux =  { user:undefined};
        if($scope.dossier.responsibleUser){
            var user_name = '';
            user_name = $scope.dossier.responsibleUser.name + ' ' + $scope.dossier.responsibleUser.surename1 +  ($scope.dossier.responsibleUser.surename2 ? ' ' +$scope.dossier.responsibleUser.surename2 : '');
            $scope.responsibleUserAux = {user: {id: $scope.dossier.responsibleUser.id, user: $scope.dossier.responsibleUser, value: user_name}};
        }

        $scope.dossierResponsibleProfilesAux = [];
        if($scope.dossier && $scope.dossier.dossierResponsibleProfiles && $scope.dossier.dossierResponsibleProfiles.length > 0){
            angular.forEach($scope.dossier.dossierResponsibleProfiles, function(value){
                $scope.dossierResponsibleProfilesAux.push(value.profile);
            })
        }
        $scope.loadUsers = CommonService.loadUsers;
        $scope.archiveClassifications = TramFactory.archiveClassifications;
        if($scope.dossier) {
            $scope.isUpdateDossier = false;            
            $scope.canEditEsetDossierResponsibleUser = false;

            $scope.receiverBox = AnnexaEntityFactory.getEditTramBox($scope.isUpdateDossier, 'receiver');
            $scope.registerEntryInputBox = {
                boxTitle: 'global.literals.reg_input',
                permissions: {},
                content:  $scope.dossier.relatedEntriesInput ?  $scope.dossier.relatedEntriesInput : [],
                config: {},
                origin: 'tram-view'
            }

            $scope.registerEntryOutputBox = {
                boxTitle: 'global.literals.reg_output',
                permissions: {},
                content:  $scope.dossier.relatedEntriesOutput ?  $scope.dossier.relatedEntriesOutput : [],
                config: {},
                origin: 'tram-view'
            }

            //endregion
            $scope.dossierBox = AnnexaEntityFactory.getEditTramBox($scope.isUpdateDossier, 'dossier');
            $scope.notificationBox = {
                permissions: {},
                search: {},
                content:  $scope.dossier.notifications,
                new: {},
                config: {dossier: $scope.dossier},
                origin: 'tram-view'
            }

            $scope.dossierBox.boxDefinition.content = $scope.dossier.relatedDossiers;
            $scope.notificationBox.content = $scope.dossier.notifications;
            if ($scope.dossier.thirds && $scope.dossier.thirds.length > 0) {
                angular.forEach($scope.dossier.thirds, function (value, key) {
                    var dossierThird = new ThirdAddress(value.third, $scope.languageColumn, $scope.dossier);
                    $scope.receiverBox.boxDefinition.content.push({
                        id: value.id,
                        third: value.third,
                        interested: value.interested,
                        representative: value.representative,
                        principal: value.principal,
                        addresses: dossierThird.getAdressesHtml(),
                        representated: value.representated,
                        roleInterested: value.roleInterested
                    });
                });
            }

            var boxDocuments = $linq($scope.dossier.dossierTransactions).select("x => x.documents").toArray();
            boxDocuments = [].concat.apply([], boxDocuments)
            boxDocuments = $linq(boxDocuments).select("x => x.document").toArray();
            $scope.boxes = [ $scope.receiverBox, $scope.registerInputBox, $scope.registerOutputBox, $scope.notificationBox, { boxDefinition: { title: 'global.literals.documents', content: boxDocuments } }];

            $scope.showview = false;
            $scope.daysexpire = 0;
            $scope.expiredate = '';

            $scope.tabs = {
                principal: {
                    left: './views/layout/viewdossier/general_left.html',
                    center: './views/layout/viewdossier/general_center.html',
                    right: './views/layout/viewdossier/general_right.html'
                }
            }

            var calculateTransaction = function () {
                // TODO: no agafar la primera!
                var actualTransaction = $linq($scope.dossier)
                    .join($scope.dossier.dossierTransactions, "x => x.id", "x => x.dossier.id", "(outer, inner) => inner")
                    .where("x => x.actual == true")
                    .toArray();

                if (actualTransaction.length != 0) {
                    $scope.actualTransaction = actualTransaction[0];
                    $scope.transactions.push($scope.actualTransaction);
                } else {
                    $scope.actualTransaction = {};
                    $scope.actualTransaction[$scope.languageColumn] = '';
                }


                var pastTransactions = $linq($scope.dossier)
                    .join($scope.dossier.dossierTransactions, "x => x.id", "x => x.dossier.id", "(outer, inner) => inner")
                    .where("x => x.endDate != null && x.actual == false")
                    .orderByDescending("x => x.endDate")
                    .toArray();

                if (pastTransactions.length != 0) {
                    angular.forEach(pastTransactions, function (value, key) {
                        $scope.transactions.push(value);
                    });
                }
            };

            $scope.getProfileNames = function(transaction){
                var profiles = '';
                if(transaction && transaction.processProfiles){
                    _.forEach(transaction.processProfiles, function(processProfile){
                        if(processProfile && processProfile.profile){
                            if(profiles == ''){
                                profiles += processProfile.profile[$scope.languageColumn];
                            }else{
                                profiles += ', '+processProfile.profile[$scope.languageColumn];
                            }
                        }
                    });
                }
                return profiles;
            }

            $scope.transactions = $linq($scope.dossier.dossierTransactions).orderByDescending("x => x.createdDate").toArray();
            $scope.activeTransaction = 0;

            if($scope.transactions && $scope.transactions.length > 0) {
                $scope.actualTransaction = undefined;
                _.forEach($scope.transactions, function (transaction, index) {
                    if(AnnexaPermissionsFactory.canViewOrTramitTransaction(transaction, $scope.dossier) && !$scope.actualTransaction) {
                        $scope.actualTransaction = transaction;
                        $scope.dossierTransactionId = transaction.id;
                        $scope.idTransactionSelectedTab = transaction.id;
                        $scope.activeTransaction = index;
                    }
                });

                if(!$scope.actualTransaction) {
                    $scope.actualTransaction = {};
                    $scope.actualTransaction[$scope.languageColumn] = '';
                    $scope.idTransactionSelectedTab = undefined;
                }
            } else {
                $scope.actualTransaction = {};
                $scope.actualTransaction[$scope.languageColumn] = '';
                $scope.idTransactionSelectedTab = undefined;
            }

            var endDate = new Date();
            if($scope.dossier.endDate){
                endDate = new Date($scope.dossier.endDate);
            }

            if(!$scope.actualTransaction.documents){
                $scope.actualTransaction.documents = [];
            }

            $scope.daysexpire = $scope.dossier.remainingDays;
            $scope.expiredate = HelperService.getExpirationDate(endDate, $scope.daysexpire, $rootScope.LoggedUser.entity.holidays);

            HeaderService.changeState($state.current);

            $scope.showview = true;

            $scope.backbutton = ($scope.transactions.length < 2) ? true : false;

            if (!$scope.backbutton) {
                $scope.previous = $scope.transactions[1];
            }
            else {
                $scope.previous = $scope.actualTransaction;
            }
        }else{
            $state.transitionTo('annexa.tram.pending');
        }
        $scope.actionsReg = function() {
            alert('Registre accions');
        };

        $scope.cancelDossierModal = function () {
            var modal = globalModals.cancelDossier;
            modal.annexaFormly.model = {};
            modal.annexaFormly.options = {};
            AnnexaFormlyFactory.showModal('modalSection', modal, $scope.cancelDossier, false);

        }

        $scope.cancelDossier = function (reason) {
            var modal = this;

            if(reason.annexaFormly.model.modal_body && reason.annexaFormly.model.modal_body.reason) {
                TramFactory.cancelDossier(reason.annexaFormly.model.modal_body.reason)
                    .then(function (data) {
                        modal.close();
                        $state.transitionTo('annexa.tram.pending');
                    }).catch(function (error) {
                });
            }
        };

        $scope.updateDossier = function (property, data) {
            var dossierAux = angular.copy($scope.dossier);
            if(property == 'archiveClassification'){
                if(data && data.$selected && data.$selected.id){
                    dossierAux[property] = {id:data.$selected.id};
                }else{
                    dossierAux[property] = {};
                }
            }else{
                dossierAux[property] = data;
            }
            TramFactory.updateDossier(dossierAux)
                .then(function(data) {
                    $scope.dossier.thirds = JSOG.decode(data).thirds;
                }).catch(function (error) {
            });
        };

        $scope.generateFoliate = function(){
            TramFactory.generateFoliate();
        }

        HeaderService.onChangeState($scope, function(message) {
            if(message.state.name == 'annexa.tram.view.viewdossier') {
                $rootScope.subHeadButtons = [];
                if($scope.dossier && $scope.dossier.closed) {
                    //$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm', undefined, '#view-dossier', 'fa-archive', 'global.literals.archive', undefined, 'archive'));
                    if(AnnexaPermissionsFactory.havePermission('reopen_dossier') || (!$rootScope.esetMode && TramFactory.isResponsible(TramFactory.Dossier))){
                        $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm', undefined, '#view-dossier', 'fa-archive', 'global.literals.reopen', undefined, 'reopenTram'));
                    }
                    $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#view-dossier',undefined,'global.literals.foliate',undefined,'generateFoliate'));
                }
                $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm', undefined, '#view-dossier', 'fa fa-eye', 'global.documents.new.audit', undefined, 'auditDossier').setPermissions('admin_audit'));
            }
        });

        $scope.auditDossier = function () {
            if ($scope.dossier && $scope.dossier.id) {
                window.open($state.href('annexa.admin.audit_dossier', {"objectId": $scope.dossier.id }), '_blank');
            }
        };

        $scope.getProfileValue = function(){
            var prof = '';
            if($scope.dossierResponsibleProfilesAux && $scope.dossierResponsibleProfilesAux.length > 0){
                angular.forEach($scope.dossierResponsibleProfilesAux, function(value){
                    if(prof){
                        prof += ", "+value[$scope.languageColumn];
                    }else{
                        prof += value[$scope.languageColumn];
                    }
                })
            }
            return prof;
        };

        HeaderService.changeState($state.current);

        $scope.archive = function(){

        }

        $scope.reopenTram = function(){
            TramFactory.reopenTramModal();
        }

        $scope.getProfileValue = function(){
            var prof = '';
            if($scope.dossierResponsibleProfilesAux && $scope.dossierResponsibleProfilesAux.length > 0){
                angular.forEach($scope.dossierResponsibleProfilesAux, function(value){
                    if(prof){
                        prof += ", "+value[$scope.languageColumn];
                    }else{
                        prof += value[$scope.languageColumn];
                    }
                })
            }
            return prof;
        };

        $scope.printQdC = function(value) {
            var qdc = '';

            var getName = function(item, label) {

                if(label) {
                    label = ' / ' + label;
                }

                label = item[$scope.languageColumn] + label;

                if(item.parent) {
                    label = getName(item.parent, label);
                }

                return label;
            }
            if(value && value.model && value.model.$selected && value.model.$selected.id) {
                var selectedQdc = $linq(TramFactory.archiveClassifications).singleOrDefault(undefined, "x => x.id == " + value.model.$selected.id);

                if(selectedQdc) {
                    qdc = getName(selectedQdc, qdc);
                }
            }

            return qdc;
        }

        $scope.disableTransaction = function (transaction) {
            return false;
        };
        
        $scope.transactionDossierTabSelected = function (trans) {
        	if (trans && trans.transactionType 
        			&& trans.transactionType.transactionSubtype != null && trans.transactionType.transactionSubtype != undefined
            		&& !angular.equals(trans.transactionType.transactionSubtype, apiAdmin.transactionSubtypes[1].id)) {
        		$scope.activeTab = 0;
                $rootScope.$broadcast('transactionTabSelected', {tab: 0});
        	}
        	$scope.idTransactionSelectedTab = ((trans)?trans.id:undefined);
        }
        
        $scope.dossierSelectedTab = function(selected){
        	if(selected && selected == 'center'){
        		$scope.idDossierSelectedTab = selected;
        	}else if(selected && selected == 'left'){
        		$scope.idDossierSelectedTab = selected;
        		$rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounterReloadTable', { reload:true });
        	}else{
        		$scope.idDossierSelectedTab = selected;
        	}
        }
        
    }])
    .controller('TramViewDossierController',['$scope', '$rootScope', '$state', '$stateParams', '$filter', 'TramFactory', 'Language', 'HeaderService', 'tramForms', '$timeout', 'CustomFieldFactory', 'HelperService', 'BoxFactory', 'AnnexaEntityFactory', 'globalModals', 'AnnexaFormlyFactory', 'dialogs', 'ErrorFactory', 'ABMComponentFactory', 'ABMShapesFactory', 'DTColumnBuilder', 'TableFilter', 'DccumentsFactory', 'NotificationFactory', 'CommonService', 'AnnexaPermissionsFactory', 'SignLocalFactory', '$http', 'GlobalDataFactory', 'apiAdmin', 'DialogsFactory', function($scope, $rootScope, $state, $stateParams, $filter, TramFactory, Language, HeaderService, tramForms, $timeout, CustomFieldFactory, HelperService, BoxFactory, AnnexaEntityFactory, globalModals, AnnexaFormlyFactory, dialogs, ErrorFactory, ABMComponentFactory, ABMShapesFactory, DTColumnBuilder, TableFilter, DccumentsFactory, NotificationFactory, CommonService, AnnexaPermissionsFactory, SignLocalFactory, $http, GlobalDataFactory, apiAdmin, DialogsFactory) {
        $scope.esetMode = $rootScope.esetMode;
        $scope.userSections = $linq($rootScope.LoggedUser.userSections).distinctBy("x => x.section.id").select("x => x.section").toArray();
        $scope.thirdModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.thirds'").toArray();
        $scope.dossierModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.tram'").toArray();
        $scope.docModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.doc.documents.all'").toArray()
        $scope.idDossierSelectedTab = 'right';
        $scope.activeTransaction = 0;
        $scope.disableTransaction = function (transaction) {
            return false;
        }
        
        $scope.transactionDossierTabSelected = function (trans) {
        	if (trans && trans.transactionType 
        			&& trans.transactionType.transactionSubtype != null && trans.transactionType.transactionSubtype != undefined
        			&& !(angular.equals(trans.transactionType.transactionSubtype, apiAdmin.transactionSubtypes[1].id)
        					|| angular.equals(trans.transactionType.transactionSubtype, apiAdmin.transactionSubtypes[2].id)
        					|| angular.equals(trans.transactionType.transactionSubtype, apiAdmin.transactionSubtypes[3].id))
        		) {
        		$scope.activeTab = 0;
                $rootScope.$broadcast('transactionTabSelected', {tab: 0});
        	}
            $scope.idTransactionSelectedTab = ((trans)?trans.id:undefined);
        }

        $scope.dossierSelectedTab = function(selected){
        	if(selected && selected == 'center'){
        		$scope.idDossierSelectedTab = selected;
        	}else if(selected && selected == 'left'){
        		$scope.idDossierSelectedTab = selected;
        		$rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounterReloadTable', { reload:true });
        	}else{
        		$scope.idDossierSelectedTab = selected;
        	}
        }


        $scope.dossierAlerts = []
        $rootScope.showLoadingdivSignin = false;

        $scope.viewdata = {
            tramTemplates: TramFactory.TramTemplates,
            documentTypes: TramFactory.DocumentTypes
        };

        $scope.dossierTramitationTypes = TramFactory.dossierTramitationTypes;
        $scope.allProfiles = TramFactory.allProfiles;

        $scope.comboYesNo = [
            { id: true, description: $filter('translate')('global.literals.yes')},
            { id: false, description: $filter('translate')('global.literals.no')}
            ];

        $scope.dossier = TramFactory.Dossier;
        $scope.modify = true;

        $scope.dossierTransactionId = $stateParams.dossierTransaction;
        $scope.archiveClassifications = TramFactory.archiveClassifications;
        
        var canEditEsetDossierResponsibleUser = function(){
        	var res = false;
        	
            if ($rootScope.esetMode && 
            		$scope.isUpdateDossier && 
            		$scope.dossier.procedure && 
            		$scope.dossier.procedure.procedureStartProfiles) {
            	
            	var procedureStartProfilesIds = $linq($scope.dossier.procedure.procedureStartProfiles).select("x => x.profile.id").toArray();
            	var userProfilesIds = $linq($scope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
            	
            	var profilesIntersect = $linq(procedureStartProfilesIds).intersect(userProfilesIds).toArray();
            	if (profilesIntersect.length > 0) {
            		res = true;
            	}
            }
            
            return res;
        };
        
        $scope.comboDossierResponsibleUser = [];                
        
        $scope.loadComboDossierResponsibleUser = function () {
        	$scope.comboDossierResponsibleUser = [];
            angular.forEach($scope.dossier.procedure.procedureStartProfiles, function (profile) {
                var users = $linq(profile.profile.userProfiles).selectMany('x => x.user', '(p, obj) => p').toArray();
                users = $linq(users).where("x => !x.expiryDate && !x.deleted").distinctBy("x => x.id").toArray();

                angular.forEach(users, function (user) { 
                	if (!$scope.dossier.responsibleUser || user.id != $scope.dossier.responsibleUser.id) {
                    	var responsibleUser = "";
                        if (user.name) {
                      	  responsibleUser = responsibleUser + user.name + " ";
                        }
                        if (user.surename1) {
                      	  responsibleUser = responsibleUser + user.surename1 + " ";
                        }
                        if (user.surename2) {
                      	  responsibleUser = responsibleUser + user.surename2;
                        }
                    	
                    	$scope.comboDossierResponsibleUser.push({id: user.id, description: responsibleUser});
                	}
                });
            });
        };   
        
        $scope.loadComboDossierResponsibleUser();
        
        $scope.printDossierResponsibleUser = function(){            	  
            if($scope.dossier && $scope.dossier.responsibleUser){
                var responsibleUser = "";
                if ($scope.dossier.responsibleUser.name) {
              	  responsibleUser = responsibleUser + $scope.dossier.responsibleUser.name + " ";
                }
                if ($scope.dossier.responsibleUser.surename1) {
              	  responsibleUser = responsibleUser + $scope.dossier.responsibleUser.surename1 + " ";
                }
                if ($scope.dossier.responsibleUser.surename2) {
              	  responsibleUser = responsibleUser + $scope.dossier.responsibleUser.surename2;
                }
                
                return responsibleUser;
            }else{
                return "";
            }
        }
        
        if($scope.dossier) {
            $scope.isUpdateDossier = true;
            if($scope.dossier.dossierStatus != 'IN_PROGRESS'){
                $scope.isUpdateDossier = false;
            }
            $scope.canEditEsetDossierResponsibleUser = canEditEsetDossierResponsibleUser();


            $scope.receiverBox = AnnexaEntityFactory.getEditTramBox($scope.isUpdateDossier, 'receiver');
            $scope.dossierBox = AnnexaEntityFactory.getEditTramBox($scope.isUpdateDossier, 'dossier');

            var addNotificationSave = function(notifications) {
                if(notifications && notifications.length > 0) {
                    NotificationFactory.createMultipleNotifications(notifications)
                        .then(function(data) {
                            _.forEach(data, function (item) {
                                $scope.notificationBox.content.push(item);
                                if(item && item.registerEntry && $scope.registerEntryOutputBox && $scope.registerEntryOutputBox.content){
                                    $scope.registerEntryOutputBox.content.push(item.registerEntry);
                                }
                            });
                        }).catch(function (error) {
                        	if(error && error.data && error.data.message == 'No notification type'){
                        		DialogsFactory.error($filter('translate')('global.sec.errors.noNotificationType'));
                        	}else if(error && error.data && error.data.message == 'channelNoValidSendNotifications'){
                        		DialogsFactory.error($filter('translate')('global.errors.channelNoValidSendNotifications'));
                        	}else{
                        		DialogsFactory.error($filter('translate')('global.errors.unknownNew'));
                        	}
                        	console.error(error);
                    });
                }
            }

            $scope.notificationBox = {
                permissions: { new: ['new_output_entry_register'] },
                search: { saveSearch: addNotificationSave },
                content:  $scope.dossier.notifications,
                new: {},
                config: { dossier: $scope.dossier },
                origin: 'tram'
            }

            $scope.$on('created_multiple_notifications_from_document', function (event, args) {
        	    if($scope.notificationBox.search && $scope.notificationBox.search.saveSearch && args && args.parentIsModal=="isNotModal") {
        	    	$scope.notificationBox.search.saveSearch(args.notifications);
                }
            });
            
            $rootScope.$on('new_notification', function(event, args) {
                if(args.notifications && args.notifications.length > 0) {
                    _.forEach(args.notifications, function(notification){
                        if(notification && notification.dossier && notification.dossier.relatedEntriesOutput) {
                            var relatedEntries = $linq(notification.dossier.relatedEntriesOutput).except($scope.dossier.relatedEntriesOutput, "(x,y) => x.id == y.id").toArray();
                            if (relatedEntries && relatedEntries.length > 0) {
                                _.forEach(relatedEntries, function(relatedEntry){
                                    $scope.dossier.relatedEntriesOutput.push(relatedEntry);
                                });
                            }
                        }
                    });
                }
            });

            $scope.dossierBox.boxDefinition.content = $scope.dossier.relatedDossiers;

            if ($scope.dossier.thirds && $scope.dossier.thirds.length > 0) {
                angular.forEach($scope.dossier.thirds, function (value, key) {
                    var dossierThird = new ThirdAddress(value.third, $scope.languageColumn, $scope.dossier);
                    $scope.receiverBox.boxDefinition.content.push({
                        id: value.id,
                        third: value.third,
                        interested: value.interested,
                        representative: value.representative,
                        principal: value.principal,
                        addresses: dossierThird.getAdressesHtml(),
                        representated: value.representated,
                        roleInterested: value.roleInterested
                    });
                });
            }

            $rootScope.$on('AnnexaServerEventUpdateThird', function(event, args) {
                if(args.third) {
                    var dossierThird = new ThirdAddress(args.third, $scope.languageColumn, $scope.dossier);

                    _.forEach($scope.receiverBox.boxDefinition.content, function (value, index) {
                        if(value.third.id == args.third.id) {
                            $scope.receiverBox.boxDefinition.content[index].third = args.third;
                            $scope.receiverBox.boxDefinition.content[index].addresses = dossierThird.getAdressesHtml();
                        }
                    });
                }
            });

            var boxDocuments = $linq($scope.dossier.dossierTransactions).select("x => x.documents").toArray();
            boxDocuments = [].concat.apply([], boxDocuments)
            boxDocuments = $linq(boxDocuments).select("x => x.document").toArray();
            $scope.boxes = [ $scope.receiverBox, $scope.registerInputBox, $scope.registerOutputBox, $scope.notificationBox, { boxDefinition: { title: 'global.literals.documents', content: boxDocuments } }];

            $scope.showview = false;
            $scope.daysexpire = 0;
            $scope.expiredate = '';

            $scope.tabs = {
                principal: {
                    left: './views/layout/viewdossier/general_left.html',
                    center: './views/layout/viewdossier/general_center.html',
                    right: './views/layout/viewdossier/general_right.html'
                }
            }
            HeaderService.onChangeState($scope, function (message) {
                if (message.state.name == 'annexa.tram.pending.viewdossier') {
                    $rootScope.subHeadButtons = [];
                    if($rootScope.isTransactionFinal==true) {
                        $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-x-xs', undefined, '#view-dossier', undefined, 'global.literals.finish', undefined, 'endDossier'));
                    }
                    $rootScope.subHeadButtons.push(new HeadButton('btn text-white breadDivButton m-x-xs grey-500', undefined, '#view-dossier', 'fa fa-minus-circle', 'global.literals.anular', undefined, 'cancelDossierModal').setPermissions('cancel_dossier'));
                    if((TramFactory.isResponsible($scope.dossier) ||  $rootScope.esetMode) && $scope.dossier.procedure.guided == false){
                        $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-x-xs ', undefined, '#view-dossier', undefined, 'global.literals.createTram', undefined, 'createTramResposibleModal'));
                    }
                    $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-x-xs',undefined,'#view-dossier',undefined,'global.literals.foliate',undefined,'generateFoliate'));
                    //$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#view-dossier','fa fa-sitemap','BPM',undefined,'viewBPM'));
                    $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-x-xs', undefined, '#view-dossier', 'fa fa-eye', 'global.documents.new.audit', undefined, 'auditDossier').setPermissions('admin_audit'));
                    if($rootScope.app.configuration.default_eni_values.show_dossier_eni){
                		$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#view-dossier', undefined, 'global.sec.literals.expEni', undefined, 'generateENIDossier').setPermissions('process_dossier'));
                	}
                }
            });

            
            $scope.generateENIDossier = function(){
            	var eniDocumentType = $linq(GlobalDataFactory.allDocumentTypes).firstOrDefault(undefined, "x => x.id == "+$rootScope.app.configuration.default_eni_values.eni_dossier_document_type);
            	if(eniDocumentType){
            		DialogsFactory.confirm('global.documents.new.audit.actionType.ENI', 'global.literals.generate_dossier_ENI')
	                .then(function (data) {
	            		 var saveEniDossier = function(){
	            			 if (this.annexaFormly.form.$valid) {
	            	                var self = this;
	            	                var model = this.annexaFormly.model.modal_body;
	            	                var moduleDoc = 'EXPED';
	            	                var document = {
	            	                        createdDate: new Date(),
	            	                        modifyDate: new Date(),
	            	                        dateLastAccess: new Date(),
	            	                        urgent: false,
	            	                        favorite: false,
	            	                        downloaded: false,
	            	                        sentMail: false,
	            	                        type: {id: model.typeSelect},
	            	                        section: {id: model.section},
	            	                        profiles: [],
	            	                        module: moduleDoc,
	            	                        dateDocumentCreation: model.dateDocumentCreation
	        	                    };
	            	                var documentIndex = model.indexDocumentEni;
	            	                var dtDocumentIndex = model.dossierTransactionEni;
	            	                
	        	                    _.forEach(model.profiles, function(item) {
	        	                        document.profiles.push({ profile: { id: item } });
	        	                    });
	        	                    if(document.profiles.length == 0 && model.profile){
	        	                        document.profiles.push({ profile: { id: model.profile } });
	        	                    }
	        	                    if (model.archiveClassification && model.archiveClassification.$selected && model.archiveClassification.$selected.id) {
	        	                        document.archiveClassification = { id: model.archiveClassification.$selected.id };
	        	                    }
	        	                    document.sentMail = false;

	        	                    var cf = CustomFieldFactory.getModelValues(model);
	        	                    document.customFields = [];
	        	                    var documentType = $linq(GlobalDataFactory.allDocumentTypes).singleOrDefault(undefined, "x => x.id == " + document.type.id);
	        	                    if(documentType && cf && cf.length > 0) {
	        	                        _.forEach(documentType.customFields, function(docTypeField) {
	        	                            var docCustomField = {
	        	                                customField: { id: docTypeField.customField.id },
	        	                                required: docTypeField.required,
	        	                                viewOrder: docTypeField.viewOrder,
	        	                                value: docTypeField.value,
	        	                                noEditable: docTypeField.noEditable
	        	                            };

	        	                            var cfValue = $linq(cf).singleOrDefault(undefined, "x => x.id == 'cf_" + documentType.id  + "_" + docTypeField.customField.id + "'");

	        	                            if(cfValue) {
	        	                                if(docTypeField.customField.frontendType == 'CHECKBOX'){
	        	                                    var custom_field_selected = $linq(cf).where(function(x){
	        	                                        if(x.id.startsWith(cfValue.id+"_")){return true}else{return false}}
	        	                                    ).toArray();
	        	                                    if(custom_field_selected && custom_field_selected.length > 0){
	        	                                        docCustomField.value = '[';
	        	                                        _.forEach(custom_field_selected, function (item, key) {
	        	                                            if(item.value) {
	        	                                                if(docTypeField.customField && docTypeField.customField.listValues && docTypeField.customField.listValues.length > 0) {
	        	                                                    var custom_field_value_selected = $linq(docTypeField.customField.listValues).where(function(x){
	        	                                                        if(item.id.endsWith("_"+x.value)){return true}else{return false}}
	        	                                                    ).toArray();
	        	                                                    if(custom_field_value_selected && custom_field_value_selected.length > 0){
	        	                                                        docCustomField.value += ((docCustomField.value == '[') ?custom_field_value_selected[0].value : ',' + custom_field_value_selected[0].value);
	        	                                                    }
	        	                                                }
	        	                                            }
	        	                                        });
	        	                                        docCustomField.value += ']';
	        	                                    }
	        	                                }else if(Array.isArray(cfValue.value)) {
	        	                                    if(docTypeField.customField.frontendType == 'MULTIPLESELECT') {
	        	                                        docCustomField.value = '[';

	        	                                        _.forEach(cfValue.value, function (item) {
	        	                                            docCustomField.value += ((docCustomField.value == '[') ? item : ',' + item);
	        	                                        });

	        	                                        docCustomField.value += ']';
	        	                                    } else {
	        	                                        docCustomField.value = cfValue.value[0];
	        	                                    }
	        	                                } else {
	        	                                    if(cfValue.value instanceof Date) {
	        	                                        docCustomField.value = $filter('date')(cfValue.value,'yyyy-MM-ddTHH:mm:ss');
	        	                                    } else {
	        	                                        docCustomField.value = cfValue.value;
	        	                                    }
	        	                                }
	        	                            }

	        	                            document.customFields.push(docCustomField);
	        	                        });
	        	                    }
	        	                    $http({
    			                        url: './api/tram/expedienteEni/' +$scope.dossier.id+'/'+documentIndex+'/'+dtDocumentIndex,
    			                        method: 'POST',
    			                        data: JSOG.encode(document)
    			                    }).then(function(data) {
    			                    	$rootScope.loading(false);
    			                    	if(data && data.data){
	    			                    	DialogsFactory.notify($filter('translate')('global.tram.literals.eniOK'));
	    			                    	self.close();
	    			                    	$state.reload();
    			                    	}else{
    			                    		DialogsFactory.error( 'global.errors.unknownNew', $filter('translate')('DIALOGS_ERROR_MSG'));
	    			                    	self.close();
    			                    	}
    			                    }).catch(function(error) {
    			                    	DialogsFactory.error( 'global.errors.unknownNew', $filter('translate')('DIALOGS_ERROR_MSG'));
    	                                console.log({msg: 'global.errors.unknownNew'});
    			                    });
	            	            }
	            	                
	            		 } 
	                	var modal = DccumentsFactory.getOpenNewDocumentModal(undefined, undefined, 'EXPED', undefined, undefined, ((TramFactory.Dossier && TramFactory.Dossier.archiveClassification)?TramFactory.Dossier.archiveClassification:undefined), eniDocumentType, $scope.actualTransaction, undefined, undefined, [eniDocumentType]);
		           		modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.disabled = true;
		           		modal.annexaFormly.fields[0].fieldGroup[1].hideExpression = function(){return true;}
		           		modal.annexaFormly.fields[0].fieldGroup[2].hideExpression = function(){return true;}
		           		modal.annexaFormly.fields[0].fieldGroup.push({
                            key: 'indexDocumentEni',
                            type: 'annexaSelectRow',
                            className: 'col-sm-12',
                            templateOptions: {
                                optionsAttr: 'bs-options',
                                ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                label: 'global.literals.indexDocumentEni',
                                valueProp: 'id',
                                labelProp: 'name',
                                placeholder: '',
                                options: [],
                                required: true,
                                clearHide: true
                            },
                            controller: ['$scope', '$filter', 'Language', 'GlobalDataFactory', 'TramFactory', 'AnnexaDocumentActionsButtonFactory', function($scope, $filter, Language, GlobalDataFactory, TramFactory, AnnexaDocumentActionsButtonFactory) {
                                $scope.to.options = [];
                                $scope.to.options.push({id: -1, name:$filter('translate')('global.literals.foliateTemplate')});
                                if(TramFactory && TramFactory.Dossier && TramFactory.Dossier.dossierTransactions){
                                	_.forEach(TramFactory.Dossier.dossierTransactions, function(dt){
                                		if(dt.documents){
                                			_.forEach(dt.documents, function(doc){
                                				if(doc.relationType == 'DOCUMENT'){
                                					$scope.to.options.push({id: doc.id, name:((doc.document)?doc.document.name:'')});
                                				}
                                			});
                                		}
                                	});
                                }
                            }],
                            data: {
                                row: true,
                                informed: true,
                                colClass: ' col-sm-12',
                                labelClass: 'label-strong'
                            }
                        });
		           		modal.annexaFormly.fields[0].fieldGroup.push({
                            key: 'dossierTransactionEni',
                            type: 'annexaSelectRow',
                            className: 'col-sm-12',
                            templateOptions: {
                                optionsAttr: 'bs-options',
                                ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                label: 'global.literals.dossierTransactionEni',
                                valueProp: 'id',
                                labelProp: 'name',
                                placeholder: '',
                                options: [],
                                required: true,
                                clearHide: true
                            },
                            controller: ['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                $scope.to.options = [];
                                if(TramFactory && TramFactory.Dossier && TramFactory.Dossier.dossierTransactions){
                                	_.forEach(TramFactory.Dossier.dossierTransactions, function(dt){
                                		if(dt.actual){
                              				$scope.to.options.push({id: dt.id, name:dt[Language.getActiveColumn()]});
                                		}
                                	});
                                }
                            }],
                            data: {
                                row: true,
                                informed: true,
                                colClass: ' col-sm-12',
                                labelClass: 'label-strong'
                            }
                        });
		           		AnnexaFormlyFactory.showModal('modalEniDocumentNew', modal, saveEniDossier, false, undefined);
		            }).catch(function(error) {
		            });
            	}else{
            		DialogsFactory.error( 'global.errors.unknownNew', $filter('translate')('DIALOGS_ERROR_MSG'));
            	}
            }
            
            $scope.auditDossier = function () {
                if ($scope.dossier && $scope.dossier.id) {
                    window.open($state.href('annexa.admin.audit_dossier', {"objectId": $scope.dossier.id }), '_blank');
                }
            };

            $scope.bpmDossier = { graphJSON: $scope.dossier.graphJSON };

            $scope.getProfileNames = function(transaction){
                var profiles = '';
                if(transaction && transaction.processProfiles){
                    _.forEach(transaction.processProfiles, function(processProfile){
                        if(processProfile && processProfile.profile){
                            if(profiles == ''){
                                profiles += processProfile.profile[$scope.languageColumn];
                            }else{
                                profiles += ', '+processProfile.profile[$scope.languageColumn];
                            }
                        }
                    });
                }
                return profiles;
            }

            $scope.viewBPM = function() {
                //ABMComponentFactory.openModalSVG();
                ABMComponentFactory.getGraphImg()
                    .then(function (data) {
                        $scope.graphImg = data;
                    }).catch(function (error) {
                });
            };


            $scope.endDossier = function () {
                var dlg = dialogs.confirm(
                    $filter('translate')('global.literals.confirmEndDossierTitle'),
                    $filter('translate')('global.literals.confirmEndDossierBody'),
                    {
                        animation: true,
                        backdrop: 'static',
                        keyboard: true,
                        size: '',
                        backdropClass: 'zMax',
                        windowClass: 'zMax2'
                    }
                );

                dlg.result.then(function (btn) {
                    TramFactory.endDossier($scope.actualTransaction).then(function(data) {
                        $state.transitionTo('annexa.tram.pending');
                    }).catch(function (error) {
                        $scope.dossierAlerts.push({ msg: ErrorFactory.getErrorMessage('dossier', 'complete', error.data )});
                    });
                }, function (btn) {
                    var a = 0;
                });

            }

            var calculateTransaction = function () {
                var actualTransaction = $linq($scope.dossier.dossierTransactions).where("x => x.id == "+$scope.dossierTransactionId).toArray();
                if (actualTransaction.length != 0 && actualTransaction[0] && actualTransaction[0].actual) {
                    $scope.actualTransaction = actualTransaction[0];
                    $scope.idTransactionSelectedTab = $scope.actualTransaction.id;
                } else {
                    actualTransaction = $linq($scope.dossier)
                        .join($scope.dossier.dossierTransactions, "x => x.id", "x => x.dossierId", "(outer, inner) => inner")
                        .where("x => x.actual == true")
                        .toArray();
                    if(actualTransaction.length != 0){
                        $scope.actualTransaction = actualTransaction[0];
                        $scope.idTransactionSelectedTab = $scope.actualTransaction.id;
                    }else{
                        $scope.actualTransaction = {};
                        $scope.actualTransaction[$scope.languageColumn] = '';
                        $scope.idTransactionSelectedTab = undefined;
                    }
                }
                var pastTransactions = $linq($scope.dossier.dossierTransactions).orderByDescending("x => x.createdDate").toArray();
                if (pastTransactions.length != 0) {
                    angular.forEach(pastTransactions, function (value, key) {
                        $scope.transactions.push(value);
                    });
                }
                if($scope.actualTransaction && $scope.actualTransaction.id){
	                var actualTransactionIndex = $linq($scope.transactions).indexOf("x => x.id == "+$scope.actualTransaction.id);
	                if (actualTransactionIndex != -1) {
	                	 $scope.activeTransaction = actualTransactionIndex;
	                }else{
	                	$scope.activeTransaction = 0;
	                }
                }else{
                	$scope.activeTransaction = 0;
                }
                
            };

            $scope.transactions = [];
            var updateDocumentInList = function (document) {
                if(document && document.id) {
                	_.forEach($scope.transactions, function(transaction){
                		if(transaction.documents){
	                        var index = $linq(transaction.documents).indexOf("x => x.document.id == " + document.id);
	                        if (index != -1) {
	                        	transaction.documents[index].document.urgent = document.urgent;
	                        	transaction.documents[index].document.docStatus = document.docStatus;
	                        	transaction.documents[index].document.idPDFDocumentManager = document.idPDFDocumentManager;
	                        	transaction.documents[index].document.filePDFTypeDoc = document.filePDFTypeDoc;
	                        	transaction.documents[index].document.sizePDFDoc = document.sizePDFDoc;
	                        }
                		}

	               	 });
                }
            }

            $scope.$on('SendToSignModalFinishedFromTable', function(event,args){
                if (args.data && args.data.length > 0) {
                    updateDocumentInList(args.data[0]);
                }
            });
            
            $scope.$on('documentActionButtonExecuted', function (event, args) {
            	if (args.button) {
                    switch (args.button) {
	                    case 'publishDocument':	
	                        updateDocumentInList(args.data);
	                        break;
                    }
                }
            });
            
            $scope.$on('documentActionButtonExecutedFromTable', function (event, args) {
                if (args.button) {
                    switch (args.button) {
                        case 'deleteDossierTransactionDocument':
                        	var transactionAux = $linq($scope.transactions).firstOrDefault(undefined, "x => x.id == "+args.data.transactionId);
                            if(transactionAux && transactionAux.documents){
	                        	var indexDeleted = $linq(transactionAux.documents).indexOf("x => x.document.id == " + args.data.documentId);
	                            if (indexDeleted != -1) {
	                            	transactionAux.documents.splice(indexDeleted, 1);
	                            }
                            }
                            break;
                        case 'updateRelationTypeDossierTransactionDocument':
                        	var transactionAux = $linq($scope.transactions).firstOrDefault(undefined, "x => x.id == "+args.data.dossierTransaction.id);
                            if(transactionAux && transactionAux.documents){
	                        	var indexDTD = $linq(transactionAux.documents).indexOf("x => x.id == " + args.data.id);
	                            if (indexDTD != -1) {
	                            	transactionAux.documents[indexDTD].relationType = args.data.relationType;
	                            }  
                            }
                        	break;
                        case 'returnToDraft':
                        case 'complete':
                            updateDocumentInList(args.data);
                            break;
                    }
                }
            });

            calculateTransaction();

            if(!$scope.actualTransaction.documents){
                $scope.actualTransaction.documents = [];
            }

            $scope.daysexpire = $scope.dossier.remainingDays;
            $scope.expiredate = HelperService.getExpirationDate(new Date(), $scope.daysexpire, $rootScope.LoggedUser.entity.holidays);

            // TODO: guiat - no guiat: treure el showEndProcess
            $rootScope.isTransactionFinal = ($scope.actualTransaction.showEndProcess==true || ABMShapesFactory.isTransactionFinal($scope.actualTransaction)==true);
            if($scope.actualTransaction && $scope.actualTransaction.dossier && $scope.actualTransaction.dossier.procedure && $scope.actualTransaction.dossier.procedure.guided == false){
                $rootScope.isTransactionFinal = false;
                var dossierTransactionIsFinal = false;
                if($scope.actualTransaction && $scope.actualTransaction.transactionType && $scope.actualTransaction.transactionType.finallyType){
                    dossierTransactionIsFinal = true;
                }
                var openTransactions = $linq(TramFactory.Dossier.dossierTransactions).where("x => x.actual == true").toArray();
                if(openTransactions && openTransactions.length == 1 && dossierTransactionIsFinal) {
                    if(TramFactory.isResponsible(TramFactory.Dossier)){
                        $rootScope.isTransactionFinal = true;
                    }
                }
            }
            HeaderService.changeState($state.current);

            $scope.showview = true;

            $scope.backbutton = ($scope.transactions.length < 2) ? true : false;

            if (!$scope.backbutton) {
                $scope.previous = $scope.transactions[1];
            }
            else {
                $scope.previous = $scope.actualTransaction;
            }
            $scope.responsibleUserAux =  { user:undefined};
            if($scope.dossier.responsibleUser){
                var user_name = '';
                user_name = $scope.dossier.responsibleUser.name + ' ' + $scope.dossier.responsibleUser.surename1 +  ($scope.dossier.responsibleUser.surename2 ? ' ' +$scope.dossier.responsibleUser.surename2 : '');
                $scope.responsibleUserAux = {user: {id: $scope.dossier.responsibleUser.id, user: $scope.dossier.responsibleUser, value: user_name}};
            }
            $scope.dossierResponsibleProfilesAux = [];
            if($scope.dossier && $scope.dossier.dossierResponsibleProfiles && $scope.dossier.dossierResponsibleProfiles.length > 0){
                angular.forEach($scope.dossier.dossierResponsibleProfiles, function(value){
                    $scope.dossierResponsibleProfilesAux.push(value.profile);
                });
            }


            //region Nou Box registres

            var addRegisterEntrySave = function(registerEntry) {
                $http({
                    url: './api/tram/dossier/' + $scope.dossier.id + '/add_register_entry/' + $linq(registerEntry).select("x => x.id").toArray(),
                    method: 'POST'
                }).then(function (data) {
                    //Empty
                }).catch(function(error) {
                    console.log(error);
                });
            };

            $scope.registerEntryInputBox = {
                boxTitle: 'global.literals.reg_input',
                permissions: { new: 'new_input_entry_register', view: ['view_input_register','view_all_input_register'] },
                search: { placeholder: 'global.literals.placeholser_search_registerEntry', advanced: true, saveSearch: $scope.isUpdateDossier ? addRegisterEntrySave : undefined },
                new: {},
                content:  $scope.dossier.relatedEntriesInput ?  $scope.dossier.relatedEntriesInput : [],
                config: { dossier: $scope.dossier },
                searchByEntryType: true,
                origin: 'tram',
                entryType: 'INPUT'
            };

            $scope.registerEntryOutputBox = {
                boxTitle: 'global.literals.reg_output',
                permissions: { new: 'new_output_entry_register', view: ['view_output_register', 'view_all_output_register'] },
                search: { placeholder: 'global.literals.placeholser_search_registerEntry', advanced: true, saveSearch: $scope.isUpdateDossier ? addRegisterEntrySave : undefined },
                new: {},
                content:  $scope.dossier.relatedEntriesOutput ?  $scope.dossier.relatedEntriesOutput : [],
                config: { dossier: $scope.dossier },
                searchByEntryType: true,
                origin: 'tram',
                entryType: 'OUTPUT'
            };

            $scope.$on('annexaBoxRegisterEntriesDelete', function (event, args) {
                if(args.origin && args.origin == 'tram') {
                   TramFactory.deleteRegister(TramFactory.Dossier.id, args.removedId, args.entryType).catch(function (error) {
                        console.log(error);
                    });
                }
            });
            
            $scope.$on('createdRegisterEntry', function (event, args) {
                if(args.id == 'modalNewRegisterEntry' && args.addNotifications && args.data && args.data.notifications &&  $scope.notificationBox &&  $scope.notificationBox.content) {
                	 _.forEach(args.data.notifications, function(notification){
                		 $scope.notificationBox.content.push(notification);
                	 });
                }
            });
            
            $scope.$on('createdRegisterEntry_from_document', function (event, args) {
                if(args.id == 'modalNewRegisterEntry') {
                	$scope.registerEntryOutputBox.content.push(args.data)

                    if($scope.registerEntryOutputBox.search.saveSearch) {
                    	$scope.registerEntryOutputBox.search.saveSearch(args.data);
                    }
                }
            })
            
            $scope.$on('allowGenerateFromContentDocumentTypeExecutedTram', function(event, args){
            	if(args && args.transactionId && $scope.dossier && $scope.dossier.dossierTransactions && args.data && args.data.id){
            		var transactionToAllow = $linq($scope.dossier.dossierTransactions).firstOrDefault(undefined, "x => x.id == "+args.transactionId);
            		if(transactionToAllow && transactionToAllow.id){
            			var saveDocumentToDossierTransaction = function(transaction, document){
                			var doc = {
	                             dossierTransaction: {id:transaction.id},
	                             document: document,
	                             publicDocument: false
	                        }
	                		if(transaction.documents == null || transaction.documents == undefined){
	                			transaction.documents = [];
	                		}
	                		transaction.documents.push(doc);
	                        TramFactory.updateDossierTransaction(transaction).then(function (data) {
	                            var docsTr = JSOG.decode(data).documents;
	                            if (docsTr) {
	                                transaction.documents.length = 0;
	                                angular.forEach(docsTr, function (value, key) {
	                                    transaction.documents.push(value);
	                                });
	                                $rootScope.$broadcast('proposalDocumentsList', {docs: transaction.documents});
	                                $rootScope.$broadcast('addRemovedObjectEvent', {addRemoveObject: true});
	                                $rootScope.$broadcast('allowGenerateFromContentDocumentTypeAddedToTransaction', {});
	                                $rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: transaction.documents, transactionId: ((transaction)?transaction.id:undefined) });
	                            }
	                        }).catch(function (error) {
	                        });
                    	}
                    	saveDocumentToDossierTransaction(transactionToAllow, args.data);
            		}
            	}
            });
            //endregion
        }else{
            $state.transitionTo('annexa.tram.pending');
        }
        $scope.loadUsers = CommonService.loadUsers;

        $scope.cancelDossierModal = function () {
            var modal = globalModals.cancelDossier;
            modal.annexaFormly.model = {};
            modal.annexaFormly.options = {};
            AnnexaFormlyFactory.showModal('modalSection', modal, $scope.cancelDossier, false);

        }

        $scope.cancelDossier = function (reason) {
            var modal = this;

            if(reason.annexaFormly.model.modal_body && reason.annexaFormly.model.modal_body.reason) {
                TramFactory.cancelDossier(reason.annexaFormly.model.modal_body.reason)
                    .then(function (data) {
                        modal.close();
                        $state.transitionTo('annexa.tram.pending');
                    }).catch(function (error) {
                });
            }
        };

        $scope.updateDossier = function (property, data) {
            var dossierAux = angular.copy($scope.dossier);
            if(property == 'archiveClassification') {
                if (data && data.$selected && data.$selected.id) {
                    dossierAux[property] = {id: data.$selected.id};
                } else {
                    dossierAux[property] = {};
                }
            }else if(property == 'dossierResponsibleProfiles'){
                if(data && data.length > 0){
                    dossierAux[property] = [];
                    angular.forEach(data, function(value){
                        dossierAux[property].push({profile:{id:value.id}});
                    });
                }
            }else if(property == 'responsibleUser'){
                if(data.user && data.user.id) {
                    dossierAux[property] = {id: data.user.id};
                } else if (data) {
                	dossierAux[property] = {id: data};
                }
            }else{
                dossierAux[property] = data;
            }
            TramFactory.updateDossier(dossierAux)
                .then(function(returnData) {
                    var returnDataDecoded = JSOG.decode(returnData);
                    $scope.dossier.thirds = returnDataDecoded.thirds;
                    if(property == 'dossierTramitationType' && (!returnDataDecoded.dossierTramitationType || !returnDataDecoded.dossierTramitationType.allowUrgentProcess)){
                        dossierAux['dossierUrgent'] = false;
                        $scope.dossier['dossierUrgent'] = false;
                    }else if(property == 'dossierResponsibleProfiles') {
                        $scope.dossierResponsibleProfilesAux = data;
                        $scope.dossier['dossierResponsibleProfiles'] = returnDataDecoded.dossierResponsibleProfiles;
                        HeaderService.changeState($state.current);
                    }else if(property == 'responsibleUser'){
                        if(returnDataDecoded.responsibleUser && returnDataDecoded.responsibleUser.id) {
                            $scope.dossier[property] = returnDataDecoded.responsibleUser;
                        }
                        if ($rootScope.esetMode) {
                        	$state.reload(); //se recarga la pagina para que carge el campo usuario responsable del tramite
                        }
                        HeaderService.changeState($state.current);                        
                    }
                }).catch(function (error) {
            });
        };

        $scope.printDossierUrgent = function(){
            if($scope.dossier && $scope.dossier.dossierUrgent){
                return $filter('translate')('global.literals.yes');
            }else{
                return $filter('translate')('global.literals.no');
            }
        }

        $scope.generateFoliate = function(){
            TramFactory.generateFoliate();
        };

        $scope.createTramResposibleModal = function(){
            TramFactory.createTramResposibleModal($scope.actualTransaction);
        };

        $scope.transactionActiveTab = 0;

        $scope.$on('transactionTabSelected', function(event, args) {
            $scope.transactionActiveTab = args.tab;
        });

        $scope.getProfileValue = function(){
            var prof = '';
            if($scope.dossierResponsibleProfilesAux && $scope.dossierResponsibleProfilesAux.length > 0){
                angular.forEach($scope.dossierResponsibleProfilesAux, function(value){
                    if(prof){
                        prof += ", "+value[$scope.languageColumn];
                    }else{
                        prof += value[$scope.languageColumn];
                    }
                })
            }
            return prof;
        };

        $scope.printQdC = function(value) {
            var qdc = '';

            var getName = function(item, label) {

                if(label) {
                    label = ' / ' + label;
                }

                label = item[$scope.languageColumn] + label;

                if(item.parent) {
                    label = getName(item.parent, label);
                }

                return label;
            }
            if(value && value.model && value.model.$selected && value.model.$selected.id) {
                var selectedQdc = $linq(TramFactory.archiveClassifications).singleOrDefault(undefined, "x => x.id == " + value.model.$selected.id);

                if(selectedQdc) {
                    qdc = getName(selectedQdc, qdc);
                }
            }

            return qdc;
        }
    }])
    .controller('TramNewDossierController',['$scope', '$rootScope', '$state', '$stateParams', 'TramFactory', 'Language', 'HeaderService', 'AnnexaEntityFactory', function($scope, $rootScope, $state, $stateParams, TramFactory, Language, HeaderService, AnnexaEntityFactory) {
        HeaderService.onChangeState($scope, function(message) {
            if(message.state.name == 'annexa.tram.newdossier') {
                $rootScope.subHeadButtons = [
                    new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#new-dossier','fa-floppy-o','global.literals.save',undefined,'saveEntity'),
                    new HeadButton('btn grey-500 text-white breadDivButton m-r-sm', undefined, '#new-dossier',undefined,'global.literals.cancel',undefined,'cancelEntity')
                ];
            }
        });

        HeaderService.changeState($state.current);

        $scope.translations = { procedure: TramFactory.Procedure[Language.getActiveColumn()] };
        $scope.daysexpire = TramFactory.Procedure.maxDays; //TODO: Mirar si al calcul de dies val només amb el del procediment, o s'han d'incloure els dels tramits
        $scope.expiredate = TramFactory.expireDate;

        $scope.entity = AnnexaEntityFactory.getNewTramForm($scope);

    }]);